import React, { useState } from "react";
import styled from "styled-components";

import Button from "../components/Button";
import Input from "../components/Input";

import { useHistory } from "react-router-dom";

import DefaultLayout from "../layouts/DefaultLayout";

import { signUp } from "../firebase/firebase.utils";
import { Helmet } from 'react-helmet';
const SignupPage = ({ currentUser, themeToggler, theme }) => {
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const login = async (e) => {
    e.preventDefault();
    await signUp(email, password);
    history.push("/subscribe");
  };

  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      <Helmet>
        <title>TubeDiary</title>
        <meta name="description" content="Build a second brain that leverages your consumption of online information so that you take more action. Also, access key, practical book summaries." />
      </Helmet>
      <PageBody>
        <FormContainer>
          <FormTitle>Sign Up to TubeDiary</FormTitle>
          <LoginForm>
            <Input value={email} onChange={handleEmailChange} label="Email" />
            <Input
              value={password}
              onChange={handlePasswordChange}
              label="Password"
            />
            <Button label="Sign Up" onClick={login} />
          </LoginForm>
        </FormContainer>
      </PageBody>
    </DefaultLayout>
  );
};

export default SignupPage;

const FormTitle = styled.span`
  font-size: 2rem;
  font-weight: 700;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LoginForm = styled.form`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;
