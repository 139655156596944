import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useMediaQuery } from "react-responsive";
import { signOut } from "../firebase/firebase.utils";

//import logo from "../growth-notes-logo3x.png";
import logo from "../growth-notes-logo_black.png";
import logoDark from "../growth-notes-logo3x.png";

import { Link } from "react-router-dom";

import icon_signup from "../assets/img/icons/icon-signup-key3x.png";
import icon_menu from "../assets/img/icons/icon-menu3x.png";
import icon_menu_white from "../assets/img/icons/icon-menu3x_white.png";

import SignUpKeyIcon from "./SignUpKeyIcon";

import Button, { BaseButton } from "../components/Button";

import { breakpoints } from "../styles/globalStyles";

import { useAuth } from "../App";

import { useHistory } from "react-router-dom";

import { useModal } from "../context/Modal";
import SignupCard from "../components/SignupCard";

import Toggle from "../components/Toggler";
import { useDarkMode } from "../components/useDarkMode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import Bell from "../context/Bell";
import { usePageInfoProvider } from "../context/PageInfoContext";

const Header = ({ themeToggler, theme }) => {
  const history = useHistory();
  const { openModal, closeModal } = useModal();
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });
  const { currentUser } = useAuth();
  const [trayActive, setTrayActive] = useState(false);

  const newLogo = theme === "light" ? logo : logoDark;
  const handleSignUpClickDesktop = () => {
    history.push("/sign-up");
  };

  const handleSignUpClickMobile = () => {
    // history.push("/growth-notes-sign-up")
    setTrayActive(false);
    openModal(<SignupCard isModal="true" />);
  };

  let myInsightBtnDeskTop = null;
  let myInsightBtnMobile = null;
  myInsightBtnDeskTop = (
    <Link to={`/insights`} style={{ textDecoration: "none" }}>
      <NoBoldButton
        tertiary
        label="TubeDiary"
        style={{ padding: "0.75rem 0.6rem" }}
        className="h-100"
      />
    </Link>
  );
  myInsightBtnMobile = (
    <Link
      to={"/insights"}
      style={{
        textDecoration: "none",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button black label="TubeDiary" />
    </Link>
  );
  return (
    <StyledHeader>
      <div id="back-to-top-anchor">
        <a href="https://tubediary.com/insight">
          <LogoImage src={newLogo} />
        </a>
        {isMobile ? (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => setTrayActive((s) => !s)}
              style={{ padding: "0.3rem 0.4rem" }}
            >
              {trayActive ? (
                <FontAwesomeIcon className="float-end" icon={faTimes} />
              ) : (
                <FontAwesomeIcon className="float-end" icon={faBars} />
              )}
            </button>
            <ToggleContainerMobile>
              <Toggle theme={theme} toggleTheme={themeToggler} />
            </ToggleContainerMobile>
          </div>
        ) : (
          <div>
            <div style={{ margin: "auto", marginLeft: "1rem" }}>
              <Toggle theme={theme} toggleTheme={themeToggler} />
            </div>

            {currentUser.loggedIn ? (
              <Link to={'/profile'} style={{ textDecoration: 'none' }}>
                <HeaderButton
                  primary
                  label="Profile"
                  style={{ padding: "0.75rem 2rem" }}
                  className="h-100"
                // onClick={() => history.push("/profile")}
                />
              </Link>
            ) : (
              <HeaderButton
                secondary
                label="Sign Up"
                style={{ padding: "0.75rem 2rem" }}
                onClick={handleSignUpClickDesktop}
              />
            )}
            {currentUser.loggedIn ? (
              <>
                <InvertButton
                  primary
                  label="Logout"
                  style={{ padding: "0.75rem 2rem" }}
                  onClick={signOut}
                />
                <Bell theme={theme} />
              </>
            ) : (
              <HeaderButton
                primary
                label="Login"
                style={{ padding: "0.75rem 2rem" }}
                onClick={() => history.push("/login")}
              />
            )}
            {myInsightBtnDeskTop}

            {currentUser.loggedIn && (
              <Link to={`/tag-cloud`} style={{ textDecoration: "none" }}>
                <NoBoldButton
                  tertiary
                  label="Tag Cloud"
                  style={{ padding: "0.75rem 0.6rem" }}
                  className="h-100"
                />
              </Link>
            )}

            <Link to={`/how-it-works`} style={{ textDecoration: "none" }}>
              <NoBoldButton
                tertiary
                label="How To"
                style={{ padding: "0.75rem 0.6rem" }}
                className="h-100"
              />
              </Link>
              {
                !currentUser.loggedIn && (
                  <Link to={`/home-content`} style={{ textDecoration: "none" }}>
                    <NoBoldButton
                      tertiary
                      label="Home"
                      style={{ padding: "0.75rem 0.6rem" }}
                      className="h-100"
                    />
                  </Link>
                )
              }
            {/* {currentUser.loggedIn ? (
              <a href={'https://drive.google.com/drive/folders/12nuKJQc9MY5O79m-wi0g_ZFzN0W3aNDC?usp=share_link'} style={{ textDecoration: "none" }} target="_blank">
              <NoBoldButton
                tertiary
                label="Toolkit"
                style={{ padding: "0.75rem 0.6rem" }}
                className="h-100"
                // onClick={() => {
                //   window.open(
                //     "https://drive.google.com/drive/folders/12nuKJQc9MY5O79m-wi0g_ZFzN0W3aNDC?usp=share_link"
                //   );
                // }}
              />
              </a>
            ) : (
              <></>
            )} */}
            {/* {currentUser.loggedIn ? (
              <a href={"https://join.slack.com/t/xhabits/shared_invite/zt-25fcaau92-1VfFwquaXiKZ5y1yOkK8bw"} style={{ textDecoration: 'none' }} target="_blank">
              <NoBoldButton
                tertiary
                label="Accountability"
                style={{ padding: "0.75rem 0.6rem" }}
                className="h-100"
              />
              </a>
            ) : (
              <></>
            )} */}
            {/*<NoBoldButton
              tertiary
              label="Community"
              style={{ padding: "0.75rem 0.6rem" }}
              onClick={() => {
                history.push("/community")
              }}
            /> */}
          </div>
        )}
      </div>
      {isMobile && (
        <Tray className={`${trayActive ? "active" : ""}`}>
          <div>
            <MenuItemLast>
              {currentUser.loggedIn ? (
                <Button
                  black
                  label="Profile"
                  style={{ color: "black" }}
                  onClick={() => history.push("/profile")}
                />
              ) : (
                <Button
                  black
                  label="Sign Up"
                  icon={icon_signup}
                  onClick={handleSignUpClickMobile}
                />
              )}
            </MenuItemLast>
            <MenuItem>
              {currentUser.loggedIn ? (
                <>
                  <Button black label="Logout" onClick={signOut} />
                  {/* <Button black label="Bill"/> */}
                </>
              ) : (
                <Button
                  black
                  label="Login"
                  onClick={() => history.push("/login")}
                />
              )}
            </MenuItem>
            {/* <MenuItem>
              <Link
                to={"/content"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button black label="Snips" />
              </Link>
            </MenuItem> */}
            <MenuItem>{myInsightBtnMobile}</MenuItem>
            <MenuItem>
              <Link
                to={"/how-it-works"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button black label="How To" />
              </Link>
            </MenuItem>

            {!currentUser.loggedIn && (
              <MenuItem>
                <Link
                  to={"/home-content"}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button black label="Home" />
                </Link>
              </MenuItem>
            )}


            {/* {currentUser.loggedIn ? (
              <MenuItem>
                <a
                  href="https://drive.google.com/drive/folders/12nuKJQc9MY5O79m-wi0g_ZFzN0W3aNDC?usp=share_link"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button black label="Toolkit" />
                </a>
              </MenuItem>
            ) : (
              <></>
            )} */}

            {/* {currentUser.loggedIn ? (
              <MenuItem>
                <a
                  href="https://join.slack.com/t/xhabits/shared_invite/zt-25fcaau92-1VfFwquaXiKZ5y1yOkK8bw"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button black label="Accountability" />
                </a>
              </MenuItem>
            ) : (
              <></>
            )} */}
            {/*<MenuItem>
              <Link
                to={"/community"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button black label="Community" />
              </Link>
            </MenuItem> */}
          </div>
        </Tray>
      )}
    </StyledHeader>
  );
};

export default Header;

const ToggleContainerMobile = styled.div`
  display: flex;
  align-items: flex-end;
  > div {
    display: flex;
  }
`;
const InvertButton = styled(Button)`
  > span {
    font-weight: unset;
    letter-spacing: 1px;
  }
  &:hover {
    background-color: var(--color-primary);
    > span {
      color: white;
    }
  }
`;

const HeaderButton = styled(Button)`
  > span {
    font-weight: unset;
    letter-spacing: 1px;
  }
`;

const NoBoldButton = styled(Button)`
  > span {
    font-weight: unset;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.text87};
  }
  &:hover {
    background-color: white;
    > span {
      color: var(--color-primary);
    }
  }
`;

const Tray = styled.div`
  background-color: var(--background-panel);
  border-left: 1px solid var(--header-border);
  border-right: 1px solid var(--header-border);
  border-bottom: 2px solid var(--header-border);
  position: relative;
  top: 0;
  padding: 0;
  transform-origin: top;
  transform: scaleY(0);
  max-height: 0;
  transition: max-height 0.3s, transform 0.3s, -webkit-transform 0.3s;
  margin: 0px -20px;
  > div {
    display: flex;
    flex-direction: column-reverse;
    > * {
      margin: 0.2rem 0;
    }
  }
  &.active {
    transform: scaleY(1);
    max-height: 100vh;
  }
`;

const MenuItem = styled.div`
  border-color: #000000;
  border-bottom: 1px solid #0000000f;
  display: grid;
`;
const MenuItemLast = styled.div`
  display: grid;
`;
const StyledHeader = styled.header`
  height: 100px;
  padding: 15px 20px;
  border-bottom: 1px solid var(--header-border);
  z-index: 999;
  > div:first-child {
    margin: 0 auto;
    height: 100%;
    display: flex;
    max-width: 1440px;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      flex-direction: row-reverse;
      > * {
        margin-left: 1rem;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.s}) {
  }
`;

const LogoImage = styled.img`
  height: 44px;
  margin-top: 10px;
  @media screen and (max-width: ${breakpoints.s}) {
    width: 50px;
    height: auto;
  }
`;
