export const lightTheme = {
    name: 'light',
    body: '#FFF',
    toggleBorder: '#FFF',
    textDefault:'#4a4a4a',

    text87: 'rgba(0,0,0,0.87)',
    text60: 'rgba(0,0,0,0.60)',
    text38: 'rgba(0,0,0,0.38)',

    background: '#ffffff',
    panel: '#ffffff',
    border: '#e8e8e8',

    primary: '#265396',
    primary20: 'rgba(38, 115, 221, 0,2)',
    primary05: 'rgba(38, 115, 221, 0,05)',

    warning: '#ffbb00',
    warning20: '#ffbb0020',
    error: '#ee2c4a',
    success: '#44cc77',
    jeans: '#648ba7',

    /** special  */
    bookShadow: 'linear-gradient( to bottom, rgba(255, 255, 255, 0) 0%, white 100% )',
    darkOpacity: 'rgba(0,0,0,0.1)',
}
export const darkTheme = {
    name: 'dark',
    body: '#363537',
    toggleBorder: '#6B8096',
    textDefault:'#e9e6e6',
    
    text87: 'rgba(255, 255, 255,0.87)',
    text60: 'rgba(255, 255, 255,0.60)',
    text38: 'rgba(255, 255, 255,0.38)',

    background: '#222222',
    panel: '#121212',
    border: 'rgba(255 , 255 , 255 ,0.09)',

    primary: '#2673dd',
    primary20: 'rgba(38, 115, 221, 0,3)',
    primary05: 'rgba(38, 115, 221, 0,14)',

    warning: '#b2b400',
    warning20: '#b2b40020',
    error: '#a82b30',
    success: '#309053',
    jeans: '#55768d',

    /** special  */
    bookShadow: 'linear-gradient( to bottom, rgba(12, 12, 12, 0) 0%, #12121200 100% )',
    darkOpacity: 'rgba(0,0,0,0.1)',
}