import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Loading = ({ size }) => {
  return <Spinner icon={faSpinner} size={size ? size : "1x"} />;
};

export default Loading;

const Spinner = styled(FontAwesomeIcon)`
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  animation: spinner 1s linear infinite;
`;
