import { default as ReactSelect } from "react-select";

// Custom Styles - see list here: https://react-select.com/styles

// Pass custom styles into the styles prop of the <ReactSelect>
/*
const customStyles  = {
    option: (provided, state) 
}
*/

const Select = ({
  options,
  setOptions,
  onChange,
  isClearable = true,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  isSearchable = true,
  theme,
  value,
  ...rest
}) => {
  var optionColor = '';
  var optionBgColor = '';
  var optionBgColorHover = '';
  if(theme === 'light') {
    optionColor = '#000000de';
    optionBgColor = '#dbdbdb';
    optionBgColorHover = '#ababab';
  }else{
    optionColor = '#ffffffde';
    optionBgColor = '#333333';
    optionBgColorHover = '#424242';
  }

  var customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2673dd' : optionBgColor,
      "&:hover": {
        backgroundColor: optionBgColorHover,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: optionBgColor,
      color: optionColor,
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      backgroundColor: '#ffffff0d',
      border: state.isFocused ? '1px solid #86b7fe': '1px solid #ced4da',
      boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : '',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = optionColor;
      return { ...provided, opacity, transition, color };
    }
  }

  return (
    <ReactSelect
      styles={customStyles}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isMulti={isMulti}
      isSearchable={isSearchable}
      options={options}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};

export default Select;
