import { useState, useContext, createContext, useMemo } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalExternalContext = createContext();
const ModalInternalContext = createContext();
const ExternalProvider = ModalExternalContext.Provider;
const InternalProvider = ModalInternalContext.Provider;

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalSubtitle, setModalSubtitle] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content, title, subtitle) => {
    setModalContent(() => content);
    setModalTitle(() => title);
    if (subtitle) {
      setModalSubtitle(() => subtitle);
    }
    setIsOpen(() => true);
  };

  const closeModal = () => {
    setIsOpen(() => false);
    setModalContent(() => null);
    setModalTitle(() => null);
    setModalSubtitle(() => null);
  };

  const externalValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    []
  );

  const internalValue = {
    isOpen,
    modalTitle,
    modalSubtitle,
    modalContent,
  };

  return (
    <ExternalProvider value={externalValue}>
      <InternalProvider value={internalValue}>{children}</InternalProvider>
    </ExternalProvider>
  );
};

export const useModal = () => {
  const store = useContext(ModalExternalContext);
  return store;
};

const Modal = () => {
  const { isOpen, modalContent } = useContext(ModalInternalContext);

  const { closeModal } = useContext(ModalExternalContext);

  const overlayClick = (e) => {
    e.stopPropagation();
    closeModal();
  };
  const containerClick = (e) => {
    e.stopPropagation();
  };

  return ReactDOM.createPortal(
    isOpen && <ModalContainer>{modalContent}</ModalContainer>,
    document.querySelector("#modal-root")
  );
};

export default Modal;

const ModalContainer = styled.div`
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 134vh;
  z-index: 100;
  background-color: #302e2e5e;
`;

const ModalOverlay = styled.div`
  position: fixed;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: var(--z-index-modal);
`;
