import React, { useState, useEffect } from "react";

import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { auth } from "../firebase/firebase.utils";
import { BaseButton } from "../components/Button";

import { breakpoints } from "../styles/globalStyles";

import HomePage from "../pages/HomePage";
import BooksPage from "../pages/BooksPage";
import GrowthNotePage from "../pages/GrowthNotePage";
import ContactPage from "../pages/ContactPage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import ProfilePage from "../pages/ProfilePage";
import MyInsightPage from "../pages/MyInsightPage";
import PublicInsightPage from "../pages/PublicInsightPage";
import CommunityPage from "../pages/CommunityPage";
import SubscribePage from "../pages/SubscribePage";
import {
  firestore
} from "../firebase/firebase.utils";
import HowWorkPage from "../pages/HowWorkPage";
import TagCloudPage from "../pages/TagCloudPage";
import HomeContentPage from "../pages/HomeContentPage";
const Routes = ({ currentUser, themeToggler, theme }) => {
  const [currentSub, setCurrentSub] = useState(null)
  useEffect(() => {
    (async () => {
      const currentSubSnapshot = await firestore
        .doc(`users/${auth.currentUser.uid}/subscription/data`)
        .get();
      const sub =
        currentSubSnapshot.data().subscriptionData.currentSubscription;
      setCurrentSub(sub);
      console.log('==========================', currentSubSnapshot.data().subscriptionData)
    })()
  }, [])

  return (
    <Router>
      {currentUser.loggedIn ? (
        <>
          {!currentUser.subscribed && currentSub && currentSub.cancel_at_period_end ? (
            <>
              <InfoBanner style={{ zIndex: 10, position: "relative" }}>
                <span>
                  You currently do not have an active subscription. Update your
                  billing details.
                </span>
                <BannerButton tertiary>Update Payment Details</BannerButton>
              </InfoBanner>
              <InfoBanner>
                <span>
                  You currently do not have an active subscription. Update your
                  billing details.
                </span>
                <BannerButton
                  tertiary
                  onClick={() => window.location.replace("/profile")}
                >
                  Update Payment Details
                </BannerButton>
              </InfoBanner>
            </>
          ) : (
            <div></div>
          )}
          <Switch>
            {/* <Route
              exact
              path="/"
              render={() => {
                return <HomePage currentUser={currentUser} />;
              }}
            /> */}
            <Route
              exact
              path="/content"
              render={() => {
                return <BooksPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/how-it-works"
              render={() => {
                return <HowWorkPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/home-content"
              render={() => {
                return <HomeContentPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/sign-up"
              render={() => {
                return <HomePage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/book-snips/:slug"
              children={<GrowthNotePage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />}
            />
            <Route
              exact
              path="/contact"
              render={() => {
                return <ContactPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/profile"
              render={() => {
                return <ProfilePage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/insights"
              render={() => {
                return <MyInsightPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/insights/:hash"
              render={() => {
                return <PublicInsightPage themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/community"
              render={() => {
                return <CommunityPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Route
              exact
              path="/tag-cloud"
              render={() => {
                return <TagCloudPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
              }}
            />
            <Redirect to="/insights" />
          </Switch>
        </>
      ) : (
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <HomeContentPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/content"
            render={(props) => {
              return <BooksPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/book-snips/:slug"
            children={<GrowthNotePage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />}
          />
          <Route
            exact
            path="/subscribe"
            render={() => {
              return <SubscribePage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/login"
            render={() => {
              return <LoginPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/sign-up"
            render={() => {
              return <HomePage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/contact"
            render={() => {
              return <ContactPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/insights"
            render={() => {
              return <MyInsightPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/insights/:hash"
            render={() => {
              return <PublicInsightPage themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/community"
            render={() => {
              return <CommunityPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Route
            exact
            path="/how-it-works"
            render={() => {
              return <HowWorkPage currentUser={currentUser} themeToggler={themeToggler} theme={theme} />;
            }}
          />
          <Redirect to="/" />
        </Switch>
      )}
    </Router>
  );
};

export default Routes;

const InfoBanner = styled.div`
  display: flex;
  z-index: 9999;
  position: fixed;
  width: 100%;
  padding: 1rem;
  left: 0;
  top: 0;
  background-color: var(--color-primary);
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${breakpoints.s}) {
    flex-direction: column;
    > span {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  > span {
    color: white;
    font-weight: 700;
  }
`;

const BannerButton = styled(BaseButton)`
  background-color: white;
  color: var(--color-primary);
  font-weight: 700;
`;
