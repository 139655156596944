import styled from "styled-components";
import { useEffect } from "react";
import { useModal } from "../context/Modal";

import { breakpoints } from "../styles/globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

const SubscribePermissionWarnning = ({ currentUser, theme, subscribe}) => {

  const { closeModal } = useModal();

  useEffect(() => {

  }, []);


  const handleSubmit = () => {
    // subscribe();
    closeModal();
  };


  const overlayClick = (e) => {
    e.stopPropagation();
    closeModal();
  };

  return (
    <Container>
      <div className="mb-3 pb-3">
        <span></span>
        <FontAwesomeIcon
          className="float-end"
          icon={faTimes}
          size="lg"
          style={{ cursor: "pointer" }}
          onClick={overlayClick}
        />
      </div>
      <InsightFormContainer>
          <DetailsRow>
            You will get access to three book summaries of your choice on the first of next month.
          </DetailsRow>
          
      </InsightFormContainer>
      <BookButtonRow>
        {/* <div style={{ marginRight: "1rem" }}>
          <button
            className="btn btn-outline-primary"
            onClick={closeModal}
          >Cancel</button>
        </div> */}
        <div >
          <button
            className="btn btn-primary"
            onClick={handleSubmit}>
              Ok
              </button>
        </div>
      </BookButtonRow>
    </Container>
  );
};

export default SubscribePermissionWarnning;

const InsightFormContainer = styled.div`
  max-height: 50vh;
  overflow: auto;
  @media screen and (max-width: ${breakpoints.s}) {
    max-height: 80vh;
  }
`;
const BookButtonRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding: 0.5rem;
`;

const Container = styled.div`
    position: relative;
    width: 50%;
    margin: auto;
    margin-top: 50vh;
    transform: translateY(-50%);
    background: var(--background-panel);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    @media screen and (max-width: ${breakpoints.s}) {
      width: 100%;
      height: 100vh;
    }
`;

const DetailsRow = styled.div`
  width: 100%;
`;
