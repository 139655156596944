import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import styled from "styled-components";

const GrowthNoteIconContainer = ({ children, message, color }) => {
  if(message != 'Completed') {
    return (
      <div></div>
    )
  }
  return (
    <TooltipPrimitive.Root delayDuration={0}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <Content side={"top"} avoidCollisions>
        <span
          style={{
            fontWeight: 700,
            marginBottom: "0.5rem",
            color: color ? color : undefined,
          }}
        >
          Growth Cycle:
        </span>
        <span style={{ color: color ? color : undefined }}>{message}</span>
        <Arrow />
      </Content>
    </TooltipPrimitive.Root>
  );
};

export default GrowthNoteIconContainer;

const Content = styled(TooltipPrimitive.Content)`
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1;
  color: var(--color-primary);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled(TooltipPrimitive.Arrow)`
  fill: none;
`;
