import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

import styled from "styled-components";
import { signOut } from "../firebase/firebase.utils";
import ScrollToTop from "../components/ScrollToTop";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const DefaultLayout = (props) => {
  const elementRef = useRef();

  const { children, themeToggler, theme } = props;
  const [trigger, setTrigger] = useState(false);
  let timer;

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 1000 * 60 * 30); // Logout automatically after 30 min
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  // useEffect(() => {
  //   Object.values(events).forEach((item) => {
  //     window.addEventListener(item, () => {
  //       resetTimer();
  //       handleLogoutTimer();
  //     });
  //   });
  // }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    signOut();
  };

  let sTimer = null;
  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current && elementRef.current.getBoundingClientRect().top < -300) {
        // User has scrolled down
        setTrigger(true);
      }else{
        setTrigger(false);
      }
    };
    sTimer = setInterval(() => {
      handleScroll();
    }, 500);
    return () => {
      if(sTimer) {
        clearInterval(sTimer);
      }
    };
  }, []);

  return (
    <div ref={elementRef}>
      <Container>
        <Header themeToggler={themeToggler} theme={theme} />
        {children}
        <ScrollToTop trigger ={trigger} />
        <Footer />
      </Container>
    </div>
  );
};

export default DefaultLayout;

const Container = styled.div`
  min-height: 130vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;
