import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const SearchBox = ({searchQuery, setSearchQuery, submit, placeHolder}) => {
  return (
  <div className="d-flex flex-fill">
    <input placeholder={placeHolder} className="form-control" value={searchQuery} onInput={e => setSearchQuery(e.target.value)}/>
  </div>
  );
};

const SearchIcon = styled.button`
  color: var(--color-text-second);
`;
export default SearchBox;
