import React, { useEffect, useState , Fragment } from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { getUsers,getUserHash, getAllInsights } from "../firebase/firebase.utils";
import { breakpoints } from "../styles/globalStyles";
import { Helmet } from "react-helmet";
import external_link from "../assets/img/icons/external-link.png";
import external_link_white from "../assets/img/icons/external-link-white.png";
import { useHistory } from "react-router-dom";
import insightMachine from "../machines/insightMachine";
import { useMachine } from "@xstate/react";
import * as moment from "moment";
import defaultAvatar from "../assets/img/_defaultUser.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CommunityPage = ({ currentUser, themeToggler, theme }) => {
  const [books, setBooks] = useState([]);
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });

  useEffect(() => {
    async function fetchData() {
      const origin = window.location.origin;
      let userData = await getUsers();

      let hashData = await getUserHash();
      let insightData = await getAllInsights();
      // origin = origin + '/insights/' + hash;
      userData.forEach(element => {
        const hashItem = hashData.find(item => {
          return item.userId === element.id;
        })
        const hash = hashItem ? hashItem.hash : null;
        const link = hash !== null ? (origin + '/insights/' + hash) : null;
        element.link = link;

        const noteList = insightData.filter(item => {
          return (item.userId === element.id && item.private !== true);
        })
        const sz_note = noteList.length;
        element.sz_notes = sz_note;

        const favoriteList = noteList.filter(item => {
          return (item.userId === element.id && item.fav === true);
        })
        const sz_favorite = favoriteList.length;
        element.sz_favorite = sz_favorite;

        const expressList = noteList.filter(item => {
          return (item.userId === element.id && item.express === true);
        })
        const sz_expressed = expressList.length;
        element.sz_expressed = sz_expressed;
      });

      userData = userData.sort(function(a, b) {
        var keyA = a.sz_notes,
          keyB = b.sz_notes;
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });

      setBooks(userData);
    }
    fetchData()
  }, [currentUser]);

  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      <Helmet>
        <title>Community - TubeDiary</title>
        <meta name="description" content="Build a sophisticated second brain by logging your notes and progress in relationship to the online material that you consume." />
      </Helmet>
      <PageBody>
        <ExploreContainer>
          <h1>Community</h1>
          <p style={{ textAlign: "center" }}>
            Explore everyone’s insights in the TubeDiary network, discover new potential content, and see the scoreboard.
          </p>
        </ExploreContainer>
        <div className="w-100" style={{ paddingLeft: '2rem', paddingRight: '2rem', textAlign: 'center' }}>
          <TableBooks className="table">
            { !isMobile && (<thead>
              <tr>
                <th colSpan={2} style={{ textAlign: 'center' }}>Username</th>
                <th>Number of Notes</th>
                <th>Number of Favourites</th>
                <th>Number of notes 'Expressed'</th>
              </tr>
            </thead>)}
            <tbody style={{ border: 'none' }}>
              {books.length > 0 ? (
                <>
                  {books
                    .map((book) => (
                      <BookRow key={book.id} book={book} currentUser={currentUser} theme={theme} />
                    ))}
                </>
              ): (
                <tr>
                  <td colSpan={'9'}>
                    <div className="text-center p-5">There are no data to display</div>
                  </td>
                </tr>
              )}
            </tbody>
          </TableBooks>
        </div>
      </PageBody>
    </DefaultLayout>
  );
};

export default CommunityPage;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 70px;

  > h1 {
    font-weight: 700;
    font-size: var(--font-size-1);
    color: var(--color-text-default);
  }
  > p {
    font-size: var(--font-size-4);
    max-width: 1030px;
    text-align: center;
    margin-top: 0;
    color: var(--color-text-default);
  }

  @media screen and (max-width: ${breakpoints.s}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${breakpoints.s}) {
  }
`;

const TableBooks = styled.table`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const BookRow = ({ book, currentUser, theme }) => {
  const avatarWidth = 50;
  const avatarHeight = 50;
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });
  const bookId = book.id;
  const [state] = useMachine(
    insightMachine.withContext({
      insightId: bookId,
      insight: book,
      currentUser,
    })
  );

  useEffect(() => {
  }, [book])

  let row = '';

    row = <Fragment>
    <InsightRow status={state}>
      <td style={{ cursor: book.link ? "pointer" : '', maxWidth: '150px',overflowWrap: 'anywhere', textAlign: 'right'}}>
        {
          book.avatar? 
          (
            <a href={book.link} target="_blank" rel="noreferrer">
              <LazyLoadImage 
                width={ avatarWidth }
                height ={ avatarHeight }
                src={book.avatar} 
                alt={ book.email }
                className="custom_avatar"
                visibleByDefault={true}
              />
            </a>
          )
          : 
          (
            <a href={book.link} target="_blank" rel="noreferrer">
              <LazyLoadImage 
                width={ avatarWidth }
                height ={ avatarHeight }
                src={ defaultAvatar } 
                alt={ book.email }
                className="custom_avatar"
                visibleByDefault={true}
              />
            </a>
          )
        }
      </td>
      <td style={{ cursor: book.link ? "pointer" : '', maxWidth: '150px',overflowWrap: 'anywhere', textAlign: 'left' }}>
      <a href={book.link} target="_blank" rel="noreferrer">{book.name}</a>
      </td>
      <td style={{  }}>
        {book.sz_notes}
      </td>
      <td style={{  }}>
        {book.sz_favorite}
      </td>
      <td style={{ }}>
        {book.sz_expressed}
      </td>
    </InsightRow>

    </Fragment>;

    if(isMobile) {
      row = <Fragment>
      <InsightRow status={state} >
        <td style={{ overflowWrap: 'anywhere'}}>
          <div className="p-1" style={{ border: "1px solid #000", borderRadius: '4px' }}>
            <div className="text-center">
              {
                book.avatar? 
                (
                  <a href={book.link} target="_blank" rel="noreferrer">
                    <LazyLoadImage 
                      width={ avatarWidth }
                      height ={ avatarHeight }
                      src={book.avatar} 
                      alt={ book.email }
                      className="custom_avatar"
                      visibleByDefault={true}
                    />
                  </a>
                )
                : 
                (
                  <a href={book.link} target="_blank" rel="noreferrer">
                    <LazyLoadImage 
                      width={ avatarWidth }
                      height ={ avatarHeight }
                      src={ defaultAvatar } 
                      alt={ book.email }
                      className="custom_avatar"
                      visibleByDefault={true}
                    />
                  </a>
                )
              }
            </div>
            <div   style={{ cursor: "pointer" }}><strong>Name: </strong><a href={book.link} target="_blank" rel="noreferrer">{book.name}</a></div>
            <div   style={{ cursor: "pointer" }}><strong>Number of Notes: </strong>{book.sz_notes}</div>
            <div   style={{ cursor: "pointer" }}><strong>Number of Favorite: </strong>{book.sz_favorite}</div>
            <div   style={{ cursor: "pointer" }}><strong>Number of Expressed: </strong>{book.sz_expressed}</div>
          </div>
        </td>
      </InsightRow>
    </Fragment>;
    }
  return row;
};

const InsightRow = styled.tr`
  vertical-align: middle;
  ${({ status }) => (status && (status.matches("readyForSecondTick") || status.matches("readyForThirdTick"))) && "background: var(--color-warning-20)"}
`;
const ExpandRow = styled.div`
  background: #85858530;
`;
const ToggleOpen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 100%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  &:hover {
    background-color: #eee;
  }
  @media screen and (max-width: ${breakpoints.s}) {
    color: var(--color-text-default);
    &:hover {
      background-color: transparent;
      color: var(--color-text-default);
    }
  }

`;

const CButton = styled.button`
  color: #2B7BD9;
  border: 1px solid #2B7BD9;
  &:hover {
    color: #ffffff;
    border-color: #2B7BD9;
    background: #2B7BD9;
  }
`;

const PButton = styled.button`
color: #2B7BD9;
border: 2px solid #2B7BD9;
&:hover {
  color: #ffffff;
  border-color: #2B7BD9;
  background: #2B7BD9;
}
`;