import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useParams, useHistory } from "react-router-dom";

import { useNotifications } from "../context/Notifications";

import { useModal } from "../context/Modal";

import { breakpoints } from "../styles/globalStyles";

import Button from "../components/Button";

import SignupCard from "../components/SignupCard";

import { PremiumTag } from "../components/Tag";

import Loading from "../components/Loading";

import HighlightsModal from "../components/HighlightsModal";

import pdf_icon from "../assets/img/icons/icon-pdf.svg";

import DefaultLayout from "../layouts/DefaultLayout";

import GrowthCycle from "../components/GrowthCycle_v3";
import {
  getBook,
  getBooks,
  getUserBook,
  createUserBook,
  updateUserBook,
} from "../firebase/firebase.utils";
import { binarify } from "../functions/misc";

import { useMediaQuery } from "react-responsive";

import mapCycle from "../functions/mapCycle";
import { Helmet } from "react-helmet";
const mapTimestampsToDates = (bookData) => {
  if (bookData.readDate) {
    bookData.readDate = bookData.readDate.toDate();
  }
  if (bookData.reviewSchedule) {
    bookData.reviewSchedule = bookData.reviewSchedule.toDate();
  }
  if (bookData.reviewDate) {
    bookData.reviewDate = bookData.reviewDate.toDate();
  }
  if (bookData.finalReviewSchedule) {
    bookData.finalReviewSchedule = bookData.finalReviewSchedule.toDate();
  }
  if (bookData.finalReviewDate) {
    bookData.finalReviewDate = bookData.finalReviewDate.toDate();
  }
  return bookData;
};

const GrowthNotePage = ({ currentUser, themeToggler, theme }) => {
  const { slug } = useParams();
  const history = useHistory();
  const { closeModal, openModal } = useModal();
  const [book, setBook] = useState(null);
  const [userBook, setUserBook] = useState(null);
  const [relatedBooks, setRelatedBooks] = useState([]);
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });

  const [isHighlights, setIsHighlights] = useState(false);

  // scale height = 0.5625
  const [screenDimensionsForVimeoEmbed, setScreenDimensionsForVimeoEmbed] =
    useState(null);

    useEffect(()=> {
      console.log('hhh')
      scrollToTop();
    }, []);

    const scrollToTop = () => {
      const anchor = (document).querySelector(
        "#back-to-top-anchor"
      );
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
  
  useEffect(() => {
    (async () => {
      const data = await getBook(slug);
      setBook(data);
      const allBooks = await getBooks();
      const categorisedBooks = allBooks.filter(
        (b) => b.category === data.category && b.id !== data.id && b.hide != true
      );
      setRelatedBooks(categorisedBooks.slice(0, 3));
      if (currentUser.loggedIn && currentUser.subscribed) {
        let bookData = await getUserBook(currentUser.uid, data.id);
        if (bookData) {
          console.log(bookData);
          bookData = mapTimestampsToDates(bookData);
          setUserBook(bookData);
        } else {
          setUserBook(null);
        }
      }
    })();
  }, [currentUser, slug]);

  const { subscribed } = currentUser;

  useEffect(() => {
    if (window.innerWidth > parseInt(breakpoints.s)) {
      setScreenDimensionsForVimeoEmbed((v) => ({
        width: 0.6 * window.innerWidth,
        height: 0.6 * (window.innerWidth * 0.5625),
      }));
    } else {
      setScreenDimensionsForVimeoEmbed((v) => ({
        width: 0.8 * window.innerWidth,
        height: 0.8 * (window.innerWidth * 0.5625),
      }));
    }
  }, []);

  const handleSignUpClickDesktop = () => {
    history.push("/sign-up");
  };

  const handleSignUpClickMobile = () => {
    openModal(<SignupCard isModal="true" />);
  };

  return (
    <DefaultLayout themeToggler={themeToggler} key={slug} theme={theme}>
      <Helmet>
        <title>TubeDiary { book ? '- ' + book.title : '' }</title>
      </Helmet>
      {book && (
        <>
          {/* MOBILE VIEW */}
          {isMobile ? (
            <PageBody>
              <div>
                <LeftColumn>
                  <a
                    href={
                      currentUser.subscribed || book.free ? book.link : null
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <CoverHolder>
                      <img src={book.image} alt={book.title + " cover"} />
                    </CoverHolder>
                  </a>
                </LeftColumn>
                <RightColumn>
                  <BookInfo>
                    <h1>{book.title}</h1>
                    <h2>{book.author}</h2>
                    <p>{book.summary}</p>
                    {book.videoLink && screenDimensionsForVimeoEmbed ? (
                      <>
                        {currentUser.subscribed || book.free ? (
                          <iframe
                            title="vimeo-player"
                            src={book.videoLink}
                            width={screenDimensionsForVimeoEmbed.width}
                            height={screenDimensionsForVimeoEmbed.height}
                            frameborder="0"
                            allowfullscreen
                          ></iframe>
                        ) : (
                          <PremiumBlock style={{ height: "unset" }}>
                            <PremiumTag />
                            <span>Subscribe to view videos</span>
                            {/* <Button
                              secondary
                              label={"Sign up"}
                              onClick={
                                isMobile
                                  ? handleSignUpClickMobile
                                  : handleSignUpClickDesktop
                              }
                            /> */}
                          </PremiumBlock>
                        )}
                      </>
                    ) : null}
                  </BookInfo>
                </RightColumn>
              </div>
              <div>
                <LeftColumn>
                  <a
                    href={
                      currentUser.subscribed || book.free ? book.link : null
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <AccessButton
                      label="Access Content"
                      icon={pdf_icon}
                      style={
                        !currentUser.subscribed && !book.free
                          ? { cursor: "no-drop", margin: "0.5rem 0" }
                          : { cursor: "pointer", margin: "0.5rem 0" }
                      }
                    />
                  </a>
                  <WideButton
                    label="Highlights"
                    onClick={() =>
                      currentUser.subscribed || book.free
                        ? setIsHighlights(true)
                        : null
                    }
                    style={
                      !currentUser.subscribed && !book.free
                        ? { cursor: "no-drop", margin: "0.5rem 0 1rem 0" }
                        : { cursor: "pointer", margin: "0.5rem 0 1rem 0" }
                    }
                  />
                  {subscribed ? (
                    <>
                      {book ? (
                        <GrowthCycle
                          bookId={book.id}
                          currentUser={currentUser}
                        />
                      ) : (
                        <span>Loading...</span>
                      )}
                    </>
                  ) : (
                    <PremiumBlock>
                      <PremiumTag />
                      <span>Subscribe to access the spatial repetition feature</span>
                      {/* <Button
                        secondary
                        label={"Sign up"}
                        onClick={
                          isMobile
                            ? handleSignUpClickMobile
                            : handleSignUpClickDesktop
                        }
                      /> */}
                    </PremiumBlock>
                  )}
                </LeftColumn>
                <RightColumn>
                  {subscribed ? (
                    <PersonalInsights
                      userBook={userBook}
                      bookId={book.id}
                      currentUser={currentUser}
                    />
                  ) : (
                    <PremiumBlock>
                      <PremiumTag />
                      <span>
                        Subscribe to take notes and keep track of your learning journey
                      </span>
                      <Button
                        secondary
                        label={"Sign up"}
                        onClick={
                          isMobile
                            ? handleSignUpClickMobile
                            : handleSignUpClickDesktop
                        }
                      />
                    </PremiumBlock>
                  )}
                </RightColumn>
              </div>
              <RelatedSection style={{ flexDirection: 'column' }}>
                <h3>Related Content</h3>
                <Related>
                  {relatedBooks.map((rbook, index) => (
                    <RelatedBook
                    key={index}
                      onClick={() =>{
                        scrollToTop();
                        setTimeout(() => {
                          history.push(`/book-snips/${rbook.slug}`)
                        }, 500);
                      }
                      }
                    >
                      <img src={rbook.image} alt={rbook.title + "cover"} />
                      <span>{rbook.title}</span>
                      <span>{rbook.author}</span>
                    </RelatedBook>
                  ))}
                </Related>
              </RelatedSection>
            </PageBody>
          ) : (
            <PageBody>
              {/* DESKTOP VIEW */}
              <div style={{ paddingBottom: "4rem" }}>
                <LeftColumn style={{ height: "fit-content" }}>
                  <a
                    href={
                      currentUser.subscribed || book.free ? book.link : null
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <CoverHolder>
                      <img src={book.image} alt={book.title + " cover"} />
                    </CoverHolder>
                  </a>
                  <a
                    href={
                      currentUser.subscribed || book.free ? book.link : null
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <AccessButton
                      label="Access Content"
                      icon={pdf_icon}
                      style={
                        !currentUser.subscribed && !book.free
                          ? { cursor: "no-drop", margin: "0.5rem 0" }
                          : { cursor: "pointer", margin: "0.5rem 0" }
                      }
                    />
                  </a>
                  <WideButton
                    label="Habits"
                    onClick={() =>
                      currentUser.subscribed || book.free
                        ? setIsHighlights(true)
                        : null
                    }
                    style={
                      !currentUser.subscribed && !book.free
                        ? { cursor: "no-drop", margin: "0.5rem 0 1rem 0" }
                        : { cursor: "pointer", margin: "0.5rem 0 1rem 0" }
                    }
                  />
                  {subscribed ? (
                    <>
                      {book ? (
                        <GrowthCycle
                          bookId={book.id}
                          currentUser={currentUser}
                        />
                      ) : (
                        <span>Loading...</span>
                      )}
                    </>
                  ) : (
                    <PremiumBlock>
                      <PremiumTag />
                      <span>Subscribe to access the spatial repetition feature</span>
                      {/* <Button
                        secondary
                        label={"Sign up"}
                        onClick={
                          isMobile
                            ? handleSignUpClickMobile
                            : handleSignUpClickDesktop
                        }
                      /> */}
                    </PremiumBlock>
                  )}
                </LeftColumn>
                <RightColumn>
                  <BookInfo>
                    <h1>{book.title}</h1>
                    <h2>{book.author}</h2>
                    <p>{book.summary}</p>
                    {book.videoLink && screenDimensionsForVimeoEmbed ? (
                      <>
                        {currentUser.subscribed || book.free ? (
                          <iframe
                            title="vimeo-player"
                            src={book.videoLink}
                            // width={screenDimensionsForVimeoEmbed.width}
                            // height={screenDimensionsForVimeoEmbed.height}
                            height={100}
                            width={'100%'}
                            frameborder="0"
                            allowfullscreen
                          ></iframe>
                        ) : (
                          <PremiumBlock style={{ height: "unset" }}>
                            <PremiumTag />
                            <span>Subscribe to view videos</span>
                            {/* <Button
                              secondary
                              label={"Sign up"}
                              onClick={
                                isMobile
                                  ? handleSignUpClickMobile
                                  : handleSignUpClickDesktop
                              }
                            /> */}
                          </PremiumBlock>
                        )}
                      </>
                    ) : null}
                  </BookInfo>
                  {subscribed ? (
                    <PersonalInsights
                      userBook={userBook}
                      bookId={book.id}
                      currentUser={currentUser}
                    />
                  ) : (
                    <div style={{ padding: "0 2rem" }}>
                      <PremiumBlock>
                        <PremiumTag />
                        <span>
                          Subscribe to take notes and keep track of your learning journey
                        </span>
                        <Button
                          secondary
                          label={"Sign up"}
                          onClick={
                            isMobile
                              ? handleSignUpClickMobile
                              : handleSignUpClickDesktop
                          }
                        />
                      </PremiumBlock>
                    </div>
                  )}
                </RightColumn>
              </div>

              <RelatedSection style={{ paddingTop: "0", flexDirection: 'column' }}>
                <div
                  style={{
                    margin: "0 0rem 2rem 0rem",
                    height: "1px",
                    borderTop: "1px solid #dedede",
                  }}
                />
                <h3>Related Content</h3>
                <Related>
                  {relatedBooks.map((rbook, index) => (
                    <RelatedBook
                    key={index}
                      onClick={() =>{
                        scrollToTop();
                        setTimeout(() => {
                          history.push(`/book-snips/${rbook.slug}`)
                        }, 5);
                      }
                      }
                    >
                      <img src={rbook.image} alt={rbook.title + "cover"} />
                      <span>{rbook.title}</span>
                      <span>{rbook.author}</span>
                    </RelatedBook>
                  ))}
                </Related>
              </RelatedSection>
            </PageBody>
          )}
        </>
      )}
      {book && (
        <HighlightsModal
          isOpen={isHighlights}
          setIsOpen={setIsHighlights}
          highlights={book.highlights}
        />
      )}
    </DefaultLayout>
  );
};

export default GrowthNotePage;

const PremiumBlock = styled.div`
  width: 100%;
  min-height: 150px;
  height: 100%;
  padding: 2rem;
  border: 1px #dedede solid;
  display: grid;
  place-items: center;
  border-radius: 12px;
  > span {
    font-weight: 700;
    font-size: 20px;
    color: #4a4a4a;
    text-align: center;
    padding: 2rem 0rem;
  }

  > * {
    margin: 1rem;
  }
`;

const RelatedSection = styled.div`
  padding: 2rem;
`;

const WideButton = styled(Button)`
  width: 100%;
  border-radius: 6px;
  margin: 1rem 0;
  padding: 1rem 0.5rem;
  > span {
    font-size: 1rem;
  }
`;

const AccessButton = styled(WideButton)`
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  cursor: pointer;
  > span {
    color: black;
  }

  &:hover {
    background-color: white;
    border-color: var(--color-secondary);
    > span {
      color: var(--color-secondary);
    }
  }
  > i,
  img {
    max-height: 16px;
  }
`;

const LeftColumn = styled.div`
  @media screen and (max-width: ${breakpoints.s}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem 2rem;
  }

  @media screen and (min-width: ${breakpoints.s}) {
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem 2rem 0 2rem;
  }
`;

const RightColumn = styled.div`
  @media screen and (max-width: ${breakpoints.s}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }

  @media screen and (min-width: ${breakpoints.s}) {
    width: 68%;
    // display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 0 2rem;
  }
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.s}) {
    > div {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
    > div:nth-child(2) {
      padding-bottom: 1rem;
      border-bottom: 1px solid rgb(238, 238, 238);
    }
    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      > h3 {
        margin: 2rem 1rem;
        margin-left: 0;
        font-size: var(--font-size-2);
        font-weight: 700;
        color: var(--color-primary);
      }
    }
  }

  @media screen and (min-width: ${breakpoints.s}) {
    > div {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      > h3 {
        margin: 2rem 1rem;
        margin-left: 0;
        font-size: var(--font-size-2);
        font-weight: 700;
        color: var(--color-primary);
      }
    }
  }
`;

/*

@media screen and (max-width: ${breakpoints.s}) {
  }

  @media screen and (min-width: ${breakpoints.s}) {
  }



*/

const VideoPlaceholder = styled.div`
  border-radius: 12px;
  padding: 2rem;
  border: 1px #dedede solid;
  display: grid;
  place-items: center;
`;

const CoverHolder = styled.div`
  display: grid;
  place-items: center;
  padding: 2rem;
  > img {
    width: 194px;
    height: 300px;
    object-fit: contain;
  }
`;

const BookInfo = styled.div`
  @media screen and (max-width: ${breakpoints.s}) {
    padding: 1rem 0 1rem 0;
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.s}) {
    padding: 3rem 2rem;
    // height: 100%;
    width: 100%;
  }

  > h1 {
    margin-top: 0;
    font-size: var(--font-size-1);
    font-weight: 700;
    text-align: left;
    color: var(--color-text-default);
    margin-bottom: 0;
  }
  > h2 {
    margin-top: 0;
    font-size: var(--font-size-5);
    font-weight: bold;
    line-height: 1.56;
    text-align: left;
    color: #979797;
  }
  > p {
    font-size: var(--font-size-4);
    letter-spacing: 0.3px;
    line-height: 1.52;
    margin-bottom: 25px;
    color: var(--color-text-default);
  }
`;

const Related = styled.div`
  @media screen and (max-width: ${breakpoints.s}) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.s}) {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const RelatedBook = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    padding: 2rem;
    width: 250px;
    height: 340px;
    object-fit: contain;
  }
  > span {
    text-align: left;
    width: 100%;
    font-size: var(--font-size-5);
    font-weight: bold;
    color: #979797;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  > span:nth-child(2) {
    font-size: var(--font-size-3);
    letter-spacing: 0.2px;
    line-height: 1.33;
    color: var(--color-text-default);
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--highlight-opacity);
  }
`;

const insightElements = [
  // {
  //   header: "",
  //   description:
  //     "How do you feel about the book in general? What adjustments will you implement as a result of reading this Growth Note?",
  //   field: "creativeInsights",
  // },
  {
    header: "The Key 1 Habit You Can Eliminate",
    description:
      "",
    field: "singleHabit",
  },
  // {
  //   header: "Test Your Memory",
  //   description:
  //     "Without immediately looking at the Growth Note, try to list out all the key action steps you can remember. Compare how many you managed to remember with the Growth Note.",
  //   field: "memoryTest",
  // },
];

const PersonalInsights = ({ userBook, bookId, currentUser }) => {
  const N = useNotifications();
  const [currentElement, setCurrentElement] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const [creativeInsights, setCreativeInsights] = useState(
    userBook ? userBook.creativeInsights || "" : ""
  );
  const [memoryTest, setMemoryTest] = useState(
    userBook ? userBook.memoryTest || "" : ""
  );
  const [singleHabit, setSingleHabit] = useState(
    userBook ? userBook.singleHabit || "" : ""
  );

  const saveInsight = async (field) => {
    setIsSaving(true);
    switch (field) {
      case "creativeInsights":
        await updateUserBook(currentUser.uid, bookId, {
          creativeInsights,
        });
        break;
      case "singleHabit":
        await updateUserBook(currentUser.uid, bookId, {
          singleHabit,
        });
        break;
      case "memoryTest":
        await updateUserBook(currentUser.uid, bookId, {
          memoryTest,
        });
        break;
      default:
        throw new Error("Something went wrong");
    }
    setIsSaving(false);
    N.notify("Insight saved!");
  };

  const updateInsightState = (e, field) => {
    switch (field) {
      case "creativeInsights":
        setCreativeInsights(e.target.value);
        break;
      case "singleHabit":
        setSingleHabit(e.target.value);
        break;
      case "memoryTest":
        setMemoryTest(e.target.value);
        break;
      default:
        throw new Error("Something went wrong");
    }
  };

  const { subscribed } = currentUser;

  useEffect(() => {
    if (userBook) {
      setCreativeInsights(userBook.creativeInsights);
      setMemoryTest(userBook.memoryTest);
      setSingleHabit(userBook.singleHabit);
    }
  }, [userBook]);

  return (
    <PersonalContainer>
      <div>
        <h3>Personal Insights</h3>
        {!subscribed && <PremiumTag />}
      </div>
      <InsightElement>
        <h4>{insightElements[currentElement].header}</h4>
        <p>{insightElements[currentElement].description}</p>
        {/* {currentElement === 0 && (
          <textarea
            onChange={(e) => {
              updateInsightState(e, insightElements[currentElement].field);
            }}
            value={creativeInsights}
          />
        )} */}
        {currentElement === 0 && (
          <textarea
            onChange={(e) => {
              updateInsightState(e, insightElements[0].field);
            }}
            value={singleHabit}
          />
        )}
        {/* {currentElement === 2 && (
          <textarea
            onChange={(e) => {
              updateInsightState(e, insightElements[currentElement].field);
            }}
            value={memoryTest}
          />
        )} */}

        {!isSaving ? (
          <Button
            label="Save"
            onClick={() => saveInsight(insightElements[currentElement].field)}
          />
        ) : (
          <div className="text-center">
              <Loading />
          </div>
        )}
      </InsightElement>
      {/* <InsightSelector>
        {insightElements.map((_, i) => (
          <SelectElement
            key={i}
            active={i === currentElement}
            onClick={() => setCurrentElement(i)}
          >
            <span>{i + 1}</span>
          </SelectElement>
        ))}
      </InsightSelector> */}
    </PersonalContainer>
  );
};

const PersonalContainer = styled.div`
  margin: 1rem 0;

  > div:first-child {
    display: flex;
    align-items: center;
    > h3 {
      margin: 0 1rem;
      margin-left: 0;
      font-size: 30px;
      font-weight: 700;
      color: var(--color-primary);
    }
  }

  @media screen and (min-width: ${breakpoints.s}) {
    padding: 0 2rem;
  }
`;

const InsightElement = styled.div`
  > h4 {
    font-size: 18px;
    font-weight: 700;
    color: var(--color-text-default);
  }
  > p {
    font-size: 18px;
    color: var(--color-text-default);
  }
  > textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border-color: var(--color-primary);
    resize: none;
    margin-bottom: 1rem;
  }
`;

const InsightSelector = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SelectElement = styled.div`
  border-radius: 100%;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  margin: 1rem;
  justify-content: center;
  align-items: center;
  font-weight: ${(p) => (p.active ? "700" : "300")};
  background-color: ${(p) =>
    p.active ? "var(--color-primary)" : "var(--color-primary-hover)"};
  > span {
    color: white;
  }
`;
