import { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import {
  stripeApi_CreateSetupIntent,
  stripeApi_CreateSubscriptionAndUser,
  stripeApi_CreateSubscriptionWithTrial,
  stripeApi_CreateSubscriptionWithoutTrial,
  stripeApi_ReturnInvoiceToRetry,
  stripeApi_ValidatePromoCode,
  stripeApi_CancelSubscription,
  stripeApi_UpdatePaymentDetails,
  stripeApi_CancelSignup,
} from "./manageStripeApi";

/**
 * Collection of tools for Stripe
 *
 * @component
 */
const useStripeTools = () => {
  const [validCard, setValidCard] = useState(false); // Use onChange on stripe CardElement to set this to true when a valid card is entered.
  const [validPromoCode, setValidPromoCode] = useState(null);
  const [processing, setProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  /**
   * Validates card details and creates a stripe payment method.
   *
   * @param {Object} props.billingDetails Object containing the billing details for the current user
   * @returns {PaymentMethodData} A stripe payment_method
   */

  const getValidPaymentMethod = async ({ billingDetails }) => {
    // check to make sure stripe and elements are loaded.
    if (!stripe || !elements) {
      throw new Error("stripe_or_elements_hook_not_loaded");
    }

    // Only proceed if there is a valid card.
    if (!validCard) {
      throw new Error("invalid_card");
    }

    const cardElement = elements.getElement(CardElement);
    const paymentMethodResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails,
    });

    // error on response object instead
    if (paymentMethodResponse.error) {
      throw new Error(handleStripeError(paymentMethodResponse.error));
    }
    return paymentMethodResponse.paymentMethod;
  };

  /**
   * Helper function to map Stripe errors
   *
   * @param {Object} error Error object
   */

  const handleStripeError = (error) => {
    if (error.type === "card_error") {
      // TODO: For card errors, error.message can be shown directly to end user.
      console.log(error.message);
      return error;
    }
    // handle errors based on the code, full list here: https://stripe.com/docs/error-codes
    if (error.code) {
      switch (error.code) {
        default:
          console.log("TODO");
          console.error(error.code);
          return error;
      }
    }
  };

  /**
   * Checks if the entered code is usable, then saves it details to validPromoCode state
   *
   * @param {string} props.promoCode Customer-facing promotional code
   * @param {string} props.userId Id of user in backend
   *
   * @returns {boolean} Returns true if the promo code was successfully applied, false if not
   */

  const applyPromoCode = async ({ promoCode, userId }) => {
    if (!processing) {
      try {
        setProcessing(() => true);
        const result = await stripeApi_ValidatePromoCode({ promoCode, userId });
        if (result.valid) {
          // set promoCode state to the id of the promotion, then return
          setValidPromoCode({ id: result.id, ...result.coupon });
          return true;
        } else {
          // inform user that the promotion code is not valid
          return false;
        }
      } catch (err) {
        return false;
        // inform user that an error has occurred
      } finally {
        setProcessing(() => false);
      }
    }
  };

  /**
   * Resets validPromoCode state
   */

  const clearPromoCode = () => {
    if (!processing) {
      setValidPromoCode(null);
    }
  };

  /**
   * Update User's payment details
   *
   * @param {string} props.userId User ID
   * @param {string} props.subscriptionId ID of subscription
   * @param {Object} props.billingDetails Object containing the billing details for the current user
   * @returns {string} String saying 'Success!'
   */

  const updatePaymentDetails = async ({
    userId,
    subscriptionId,
    billingDetails,
  }) => {
    if (!processing) {
      try {
        setProcessing(() => true);
        const paymentMethod = await getValidPaymentMethod({ billingDetails });

        const setupIntent = await stripeApi_CreateSetupIntent({
          userId,
        });

        const confirmCardSetupResult = await stripe.confirmCardSetup(
          setupIntent.client_secret,
          {
            payment_method: paymentMethod.id,
          }
        );

        // error on response object instead
        if (confirmCardSetupResult.error) {
          throw new Error(handleStripeError(confirmCardSetupResult.error));
        }
        const result = await stripeApi_UpdatePaymentDetails({
          userId,
          subscriptionId,
          paymentMethodId: confirmCardSetupResult.setupIntent.payment_method,
        });
        return "Success!";
      } finally {
        setProcessing(() => false);
      }
    }
  };

  const signUpWithPayment = async ({
    userId,
    priceId,
    email,
    password,
    name,
    billingDetails,
  }) => {
    if (!processing) {
      try {
        setProcessing(() => true);
        const paymentMethod = await getValidPaymentMethod({ billingDetails });

        const subscriptionandpayment = await stripeApi_CreateSubscriptionAndUser({
          userId,
          email,
          password,
          name,
          priceId: priceId,
          payment_method: paymentMethod.id,
          promoCode: validPromoCode ? validPromoCode.id : null,
        });

        const confirmCardPaymentResult = await stripe.confirmCardPayment(
          subscriptionandpayment.clientSecret,
          {
            payment_method: paymentMethod.id,
          }
        );

        if (confirmCardPaymentResult.error) {

          const cancelsubscription = await stripeApi_CancelSignup({ userId, subscriptionId: subscriptionandpayment.subscriptionId });

          throw new Error(handleStripeError(confirmCardPaymentResult.error));
        }

        return "Success";

      } finally {
        setProcessing(() => false);
      }
    }
  };
  /**
   * Create's a subscription for the current user if one no longer exists
   *
   * @param {string} props.userId User ID
   * @param {string} props.priceId Stripe Price ID
   * @param {boolean} props.isTrial Set to true if there is to be a trial period
   * @param {Object} props.billingDetails Object containing the billing details for the current user
   * @returns {string} String saying 'Success!'
   */

  const createSubscription = async ({
    userId,
    priceId,
    isTrial,
    billingDetails,
  }) => {
    if (!processing) {
      try {
        setProcessing(() => true);
        const paymentMethod = await getValidPaymentMethod({ billingDetails });

        if (!isTrial) {
          // ----------------------------------
          // NO TRIAL PERIOD
          // ----------------------------------

          // Server-side function automatically adds the payment_method as default_payment_method on relevant customer
          const subscription = await stripeApi_CreateSubscriptionWithoutTrial({
            userId,
            priceId: priceId,
            payment_method: paymentMethod.id,
            promoCode: validPromoCode ? validPromoCode.id : null,
          });

          const confirmCardPaymentResult = await stripe.confirmCardPayment(
            subscription.clientSecret,
            {
              payment_method: paymentMethod.id,
            }
          );
          if (confirmCardPaymentResult.error) {
            throw new Error(handleStripeError(confirmCardPaymentResult.error));
          }
          return "Success";
        } else {
          // ----------------------------------
          // WITH TRIAL PERIOD
          // ----------------------------------
          const setupIntent = await stripeApi_CreateSetupIntent({
            userId,
          });

          const confirmCardSetupResult = await stripe.confirmCardSetup(
            setupIntent.client_secret,
            {
              payment_method: paymentMethod.id,
            }
          );

          // error on response object instead
          if (confirmCardSetupResult.error) {
            throw new Error(handleStripeError(confirmCardSetupResult.error));
          }

          // Server-side function automatically adds the payment_method as default_payment_method on relevant customer
          const subscription = await stripeApi_CreateSubscriptionWithTrial({
            userId,
            priceId: priceId,
            payment_method: confirmCardSetupResult.setupIntent.payment_method,
            promoCode: validPromoCode ? validPromoCode.id : null,
          });

          return "Success!";
        }
      } finally {
        setProcessing(() => false);
      }
    }
  };

  /**
   * Retrieves failed invoice by id, and attempts payment using the provided payment_details
   *
   * @param {string} props.invoiceId ID of the invoice to be retrieved
   * @param {string} props.userId User ID
   * @param {Object} props.billingDetails Object containing the billing details for the current user
   * @returns {string} String saying 'Success!'
   */

  const retryInvoicePayment = async ({ invoiceId, userId, billingDetails }) => {
    if (!processing) {
      try {
        setProcessing(() => true);
        // check to make sure stripe and elements are loaded.
        const paymentMethod = await getValidPaymentMethod({ billingDetails });
        const invoice = await stripeApi_ReturnInvoiceToRetry({
          invoiceId,
          userId,
          paymentMethodId: paymentMethod.id,
        });

        const confirmCardPaymentResult = await stripe.confirmCardPayment(
          invoice.payment_intent.client_secret,
          {
            payment_method: paymentMethod.id,
          }
        );
        if (confirmCardPaymentResult.error) {
          throw new Error(handleStripeError(confirmCardPaymentResult.error));
        }
        return "Success!";
      } finally {
        setProcessing(() => false);
      }
    }
  };

  /**
   * Retrieves failed invoice by id, and attempts payment using the provided payment_details
   *
   * @param {string} props.userId User ID
   * @param {string} props.subscriptionId Subscription ID
   * @param {boolean} props.immediately Whether or not to cancel immediately or at the end of the current period
   * @returns {string} String saying 'Success!'
   */

  const cancelSubscription = async ({
    userId,
    subscriptionId,
    immediately,
  }) => {
    if (!processing) {
      try {
        setProcessing(() => true);
        const result = await stripeApi_CancelSubscription({
          userId,
          subscriptionId,
          immediately,
        });
        return "Success!";
      } finally {
        setProcessing(() => false);
      }
    }
  };

  return {
    createSubscription,
    cancelSubscription,
    retryInvoicePayment,
    updatePaymentDetails,
    applyPromoCode,
    clearPromoCode,
    setValidCard,
    validCard,
    validPromoCode,
    processing,
    signUpWithPayment
  };
};

export default useStripeTools;
