import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/globalStyles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ReactHtmlParser from 'react-html-parser'; 

const HighlightsModal = ({ highlights, title, isOpen, setIsOpen }) => {
  const overlayClick = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };
  const containerClick = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      {isOpen && (
        <Overlay onClick={overlayClick}>
          <Container onClick={containerClick}>
            <div>
              <span>{title || "Habits"}</span>
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                style={{ cursor: "pointer" }}
                onClick={overlayClick}
              />
            </div>
            <ContentDiv>
              { ReactHtmlParser (highlights) } 
            </ContentDiv>
          </Container>
        </Overlay>
      )}
    </>
  );
};

export default HighlightsModal;

const ContentDiv = styled.div`
  display: block;
  p {
    margin-bottom: 0.4rem;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 134vh;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  display: grid;
  place-items: center;
`;

const Container = styled.div`
  background-color: var(--background-panel);
  z-index: 10001;
  display: grid;
  grid-template-rows: auto 1fr;
  cursor: default;

  > div:first-child {
    > span {
      font-weight: 700;
    }
    display: flex;
    padding: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  > div:nth-child(2) {
    padding: 2rem;
    overflow-y: auto;
    margin-bottom: 1rem;
    white-space: pre-line;
    p{
      white-space: pre-line;
    }
  }
  @media screen and (max-width: ${breakpoints.s}) {
    width: 90%;
    height: 90vh;
  }

  @media screen and (min-width: ${breakpoints.s}) {
    width: 50%;

    > div:first-child {
      padding: 2rem;
      padding-left: 3rem;
      > span {
        font-size: 24px;
        font-weight: 700;
      }
    }
    > div:nth-child(2) {
      padding: 3rem;
      padding-top: 0px;
    }
  }
`;
