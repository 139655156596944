import { useCallback } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faTrash } from '@fortawesome/free-solid-svg-icons'

const Dropzone = ({ image, newImageName,newImageSize, handleFileUpload, handleDelete }) => {
  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const humanFileSize = (size) => {
    if(size <= 0) {
      return '0B';
    }
      var i = Math.floor( Math.log(size) / Math.log(1024) );
      return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  };
  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      {newImageName ? (
        <div>
          <span>{newImageName}</span>
          <span className="size m-2">{humanFileSize(newImageSize)}</span>
          <span className="text-danger position-absolute" onClick={handleDelete}><FontAwesomeIcon icon={faTrash} /></span>
        </div>
      ) : (
        <>
          {image ? (
            <>
              <div >
                <span  style={{ fontSize: '2rem' }} ><FontAwesomeIcon icon={faFile} /></span>
                <span className="position-absolute text-danger" onClick={handleDelete}><FontAwesomeIcon icon={faTrash} /></span>
              </div>
              <span style={{ textDecoration: "underline" }} onClick={handleDelete}>
                Click to replace attachment
              </span>
            </>
          ) : (
            <>
              {isDragActive ? (
                <span>Drop the file here ...</span>
              ) : (
                <span>Drag and drop or click to select file</span>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Dropzone;

const Container = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #ffffff09;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: var(--color-text-default);
  > div {
    text-align: center;
    color: var(--color-text-default);
    > .size{
      color: var(--color-danger);
    }
  }
  > img {
    width: 100%;
  }
  .position-absolute {
    top: 6px;
    right: 11px;
  }
  border: 5px dashed var(--color-primary);
`;
