import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <span>TubeDiary © 2024</span>
      </div>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.header`
  height: 100px;
  padding: 15px 20px;
  > div {
    margin: 0 auto;
    height: 100%;
    display: flex;
    max-width: 1440px;
    justify-content: center;
    align-items: center;
  }
`;
