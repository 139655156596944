import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

console.log("initialized");
export default firebase;

// AUTH
export const auth = firebase.auth();

export const emailAuthProvider = firebase.auth.EmailAuthProvider;

export const signUp = async (email, password) => {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .catch((err) => console.log(err));
};

export const signIn = async (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const signOut = async (email, password) => {
  return auth
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};
export const sendPasswordResetEmail = async (email) => {
  try {
    return await auth.sendPasswordResetEmail(email);
  } catch (err) {
    console.error(err);
  }
};

// FIRESTORE
export const firestore = firebase.firestore();

export const getBook = async (slug) => {
  return firestore
    .collection("books")
    .where("slug", "==", slug)
    .limit(1)
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data[0];
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getBooks = async () => {
  return firestore
    .collection("books")
    // .where("hide", "!=", true)
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    });
};
export const getPermission = async (email) =>{
  try {
    const snapshot = await firestore
      .collection("users")
      .where("email", "==", email)
      .get();

    if (snapshot.empty) return null;

    let permission = snapshot.docs[0].data();
    return permission;
  } catch (err) {
    console.error(err);
  }
    
};
export const updatePermission = async (email, userPermission) => {
  const snapshot = await firestore
    .collection("users")
    .where("email", "==", email)    
    .get();

  if (!snapshot.empty) {
    await snapshot.docs[0].ref.update(userPermission);
    return snapshot.docs[0].ref.id;
  } else {
    let ref = await initializeBookForUser(email, userPermission);
    return ref.id;
  }
};

export const getUserBook = async (userId, bookId) => {
  try {
    const snapshot = await firestore
      .collection("userBooks")
      .where("userId", "==", userId)
      .where("bookId", "==", bookId)
      .get();

    if (snapshot.empty) return null;

    let book = snapshot.docs[0].data();
    return book;
  } catch (err) {
    console.error(err);
  }
};

export const getUserBooks = async (userId) => {
  const userBooksSnapshot = await firestore
    .collection("userBooks")
    .where("userId", "==", userId)
    .get();
  if (userBooksSnapshot.empty) return [];
  return userBooksSnapshot.docs.map((v) => ({ ...v.data(), id: v.id }));
};

export const getUsers = async () => {
  const userBooksSnapshot = await firestore
    .collection("users")
    .get();
  if (userBooksSnapshot.empty) return [];
  return userBooksSnapshot.docs.map((v) => ({ ...v.data(), id: v.id }));
};

export const updateUserBookList = async (userId, bookId) => {
  return firestore.doc(`users/${userId}`).update({
    books: firebase.firestore.FieldValue.arrayUnion(bookId),
  });
};

export const updateUserAvatar = async (userId, avatar) => {
  return firestore.doc(`users/${userId}`).update({
    avatar,
  });
};

export const updateBookForUser = async (userId, bookId) => {
  //return firestore.doc(`users/${userId}`).update({books: })
};

const initializeBookForUser = async (userId, bookId, extraFields = {}) => {
  const userBookObject = {
    ...extraFields,
    bookId,
    userId,
    createdAt: new Date(),
  };
  const newDocRef = await firestore.collection("userBooks").add(userBookObject);
  return newDocRef;
};

export const updateUserBook = async (userId, bookId, userBook) => {
  const snapshot = await firestore
    .collection("userBooks")
    .where("userId", "==", userId)
    .where("bookId", "==", bookId)
    .get();

  if (!snapshot.empty) {
    await snapshot.docs[0].ref.update(userBook);
    return snapshot.docs[0].ref.id;
  } else {
    let ref = await initializeBookForUser(userId, bookId, userBook);
    return ref.id;
  }
};

export const createUserBook = async (userId, bookId, userBook) => {
  try {
    // create a new User Book
    let doc = await firestore.collection("userBooks").doc();
    await doc.set(userBook);

    await firestore.doc(`users/${userId}`).update({
      books: firebase.firestore.FieldValue.arrayUnion(bookId),
    });

    return doc.id;
  } catch (err) {
    console.error(err);
  }
};

export const addInsight = async (book) => {
  return firestore
    .collection("insight")
    .add(book)
    .then(() => {
      console.log("yay");
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updateInsight = async (insightId, insight) => {
  return firestore.doc(`insight/${insightId}`).update(insight);
};

export const getUserHash = async () => {
  const userBooksSnapshot = await firestore
    .collection("userid_hash")
    .get();
  if (userBooksSnapshot.empty) return [];
  return userBooksSnapshot.docs.map((v) => ({ ...v.data(), id: v.id }));
};

export const getInsightPublic = async (uid) => {
  const snapshot = await firestore
    .collection(`userid_hash`)
    .where("userId", "==", uid)
    .get();
  if (!snapshot.empty) {
    if (snapshot.docs.length > 0) {
      return true;
    }
    return false;
  } else {
    return false;
  }
};

export const updateInsightPublic = async (uid, hash) => {
  // if (isPublic === true) {
    const snapshot = await firestore
    .collection(`userid_hash`)
    .where("userId", "==", uid)
    .get();
    if(!snapshot.empty) {
      // hash exist
      await snapshot.docs[0].ref.update({'userId': uid, 'hash': hash});
      return snapshot.docs[0].ref.id;
    }else{
      let doc = await firestore.collection("userid_hash").doc();
      await doc.set({ userId: uid, hash });
      return true;
    }
  // } else {
  //   const snapshot = await firestore
  //     .collection(`userid_hash`)
  //     .where("userId", "==", uid)
  //     .get();
  //   if (!snapshot.empty) {
  //     for (var i = 0; i < snapshot.docs.length; i++) {
  //       const doc = snapshot.docs[i];
  //       await doc.ref.delete();
  //     }
  //     return true;
  //   }
  //   return false;
  // }
};
export const getUserIdWithHash = async (hash) => {
  return firestore
    .collection("userid_hash")
    .where("hash", "==", hash)
    .get()
    .then((snapshot) => {
      let userId = null;
      if (snapshot.docs.length > 0) {
        userId = snapshot.docs[0].data().userId;
      }
      return userId;
    });
};
export const getHashWithUserIdWith = async (uid) => {
  return firestore
    .collection("userid_hash")
    .where("userId", "==", uid)
    .get()
    .then((snapshot) => {
      let hash = null;
      if (snapshot.docs.length > 0) {
        hash = snapshot.docs[0].data().hash;
      }
      return hash;
    });
};
export const checkUserHash = async (hash, uid) => {
  return (
    firestore
      .collection("userid_hash")
      .where("hash", "==", hash)
      // .where("userId", "!=", uid)
      .get()
      .then((snapshot) => {
        let userId = null;
        if (snapshot.docs.length > 0) {
          userId = snapshot.docs[0].data().userId;
        }
        return userId;
      })
  );
};

export const getAllInsights = async () => {
  return firestore
    .collection("insight")
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    });
};

export const getInsights = async (userId) => {
  return firestore
    .collection("insight")
    .where("userId", "==", userId)
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    });
};

export const getPublicInsights = async (uid) => {
  return firestore
    .collection("insight")
    .where("userId", "==", uid)
    .where("private", "!=", true)
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    });
};

export const deleteInsight = async (id) => {
  return firestore
    .doc(`insight/${id}`)
    .delete()
    .then(() => console.log("yay"))
    .catch((err) => {
      console.error(err);
    });
};

export const getPageContent = async (page) => {
  return firestore
    .collection("pageTitles")
    .where("page", "==", page)
    .limit(1)
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data[0];
    })
    .catch((err) => {
      console.error(err);
    });
};

export const functions = firebase.app().functions("europe-west1");

export const createUser = functions.httpsCallable("createUser");
