/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment } from "react";

import DefaultLayout from "../layouts/DefaultLayout";
import styled from "styled-components";

import Select from "../components/Select";

import { useMediaQuery } from "react-responsive";

import {
  getInsights,
  updateInsight,
  updateInsightPublic,
  getInsightPublic,
  getHashWithUserIdWith,
  getPageContent,
  deleteInsight,
} from "../firebase/firebase.utils";

import { breakpoints } from "../styles/globalStyles";

import Button from "../components/Button";

import { useModal } from "../context/Modal";
import MyInsightModal from "../components/MyInsightModal";
import MyInsightUrlModal from "../components/MyInsightUrlModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleUp,
  faAngleDown,
  faPen,
  faFile,
  faStar,
  faHeart,
  faCheckSquare,
  faSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SearchBox from "../components/SearchBox";
import {
  formatDate,
  formatStripeDate,
  formatDateShort,
} from "../functions/misc";
import { Rating } from "react-simple-star-rating";
import Switch from "react-switch";
import HighlightsModal from "../components/HighlightsModal";

import { Helmet } from "react-helmet";
import external_link from "../assets/img/icons/external-link.png";
import external_link_white from "../assets/img/icons/external-link-white.png";
import star from "../assets/img/icons/star.svg";

import { useHistory } from "react-router-dom";
import insightMachine from "../machines/insightMachine";
import { useMachine } from "@xstate/react";
import * as moment from "moment";
import dayjs from "dayjs";

const filterOptions = [
  {
    label: "All",
    value: "All",
  },
  // {
  //   label: "Video",
  //   value: "Video",
  // },
  // {
  //   label: "Podcast",
  //   value: "Podcast",
  // },
  // {
  //   label: "Article",
  //   value: "Article",
  // },
  // {
  //   label: "Misc",
  //   value: "Misc",
  // },
];

const MyInsight = ({ currentUser, themeToggler, theme }) => {
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const paramUrl = queryParams.get("u");
  const paramType = queryParams.get("type");
  const paramTitle = queryParams.get("title");
  const paramChannel = queryParams.get("channel");
  const paramDuration = queryParams.get("duration");
  // const paramCategory = queryParams.get("category");

  console.log("paramUrl: ", paramUrl);
  console.log("paramTitle: ", paramTitle);
  console.log("paramChannel: ", paramChannel);
  const { openModal } = useModal();

  const [books, setBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [favFilter, setFavFilter] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });
  const [publicUrl, setPublicUrl] = useState("");
  const [hash, setHash] = useState("");
  const [isHelpModal, setIsHelpModal] = useState(false);
  const [isCycleModal, setIsCycleModal] = useState(false);
  const [helpContent, setSubText] = useState(null);
  const [cycleContent, setCycleText] = useState(null);

  const onChangeFav = () => {
    let _favFilter = !favFilter;
    setFavFilter(_favFilter);
  };

  useEffect(() => {
    async function fetchInsightPublic() {
      const hash = await getHashWithUserIdWith(currentUser.uid);
      setHash(hash ? hash : "");
    }

    async function getHelp() {
      const pageData = await getPageContent("insights");
      if (pageData) {
        var content = JSON.parse(pageData.content);
        setSubText(content["subtext"] ? content["subtext"] : null);
      }

      const pageDataCycle = await getPageContent("cycle");
      if (pageData) {
        var contentCycle = JSON.parse(pageDataCycle.content);
        setCycleText(contentCycle["subtext"] ? contentCycle["subtext"] : null);
      }
    }
    getHelp();
    if (!currentUser.empty && currentUser.loggedIn) {
      fetchInsightPublic();
      var origin = window.location.origin;
      origin = origin + "/insights/" + hash;
      setPublicUrl(origin);

      getInsights(currentUser.uid).then((data) => {
        data = data.sort(function (a, b) {
          var keyA = a.createdAt.seconds,
            keyB = b.createdAt.seconds;
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        setBooks(data);
      });
      if (paramUrl !== null) {
        openLink(paramUrl);
      }
    } else {
      var data = localStorage.getItem("insightList");
      if (!data) {
        data = [];
      } else {
        data = JSON.parse(data);
      }
      if (paramUrl !== null && data.length < 1) {
        history.push("/login");
      }
      data = data.sort(function (a, b) {
        var keyA = new Date(a.createdAt),
          keyB = new Date(b.createdAt);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      setBooks(data);
    }
  }, [currentUser, hash]);

  const refreshHash = async () => {
    const hash = await getHashWithUserIdWith(currentUser.uid);
    setHash(hash ? hash : currentUser.name);
    var origin = window.location.origin;
    origin = origin + "/insights/" + hash;
    setPublicUrl(origin);
  };
  
  const calFilterBook = () => {
    let temp = [];
    if (books.length > 0) {
      temp = books.filter((b) => {
        const title = b.title.toLowerCase();
        const query = searchQuery.toLowerCase();
        return title.includes(query);
        return categoryFilter === "All"
          ? title.includes(query)
          : b.category === categoryFilter && title.includes(query);
      });

      temp = temp.filter((b) => {
        return favFilter == false ? true : b.fav == true;
      })
    }

    var temp2 = [];
    if (temp.length > 0) {
      const cYear = moment().format("YY");

      var year =
        !currentUser.empty && currentUser.loggedIn
          ? moment(formatStripeDate(temp[0].createdAt.seconds)).format("YY")
          : moment(formatDate(temp[0].createdAt)).format("YY");

      if (year == cYear) {
        var month =
          !currentUser.empty && currentUser.loggedIn
            ? moment(formatStripeDate(temp[0].createdAt.seconds)).format("MMMM")
            : moment(formatDate(temp[0].createdAt)).format("MMMM");
      } else {
        var month =
          !currentUser.empty && currentUser.loggedIn
            ? moment(formatStripeDate(temp[0].createdAt.seconds)).format(
                "MMMM YY"
              )
            : moment(formatDate(temp[0].createdAt)).format("MMMM YY");
        const cMonth = moment().format("MMMM YY");
      }

      var createdAt =
        !currentUser.empty && currentUser.loggedIn
          ? moment(formatStripeDate(temp[0].createdAt.seconds)).format(
              "MM-DD-YYYY"
            )
          : moment(formatDate(temp[0].createdAt)).format("MM-DD-YYYY");

      var weeknumber = moment(createdAt, "MM-DD-YYYY").isoWeek();

      const cur_weeknumber = moment(
        moment().format("MM-DD-YYYY"),
        "MM-DD-YYYY"
      ).isoWeek();

      // if(cMonth !== month) {
      temp2.push({
        month: month,
      });
      // }

      temp2.push({
        weeknum: weeknumber,
      });

      var _hour = 0;
      var _min = 0;
      var _tmp_month = "";

      var szSnipMonth = 0;
      var szSnipApplied = 0;

      for (var i = 0; i < temp.length; i++) {
        var tmp_year =
          !currentUser.empty && currentUser.loggedIn
            ? moment(formatStripeDate(temp[i].createdAt.seconds)).format("YY")
            : moment(formatDate(temp[i].createdAt)).format("YY");

        if (tmp_year == cYear) {
          var temp_month =
            !currentUser.empty && currentUser.loggedIn
              ? moment(formatStripeDate(temp[i].createdAt.seconds)).format(
                  "MMMM"
                )
              : moment(formatDate(temp[i].createdAt)).format("MMMM");
        } else {
          var temp_month =
            !currentUser.empty && currentUser.loggedIn
              ? moment(formatStripeDate(temp[i].createdAt.seconds)).format(
                  "MMMM YY"
                )
              : moment(formatDate(temp[i].createdAt)).format("MMMM YY");
        }

        _tmp_month = temp_month;
        var tmp_createdAt =
          !currentUser.empty && currentUser.loggedIn
            ? moment(formatStripeDate(temp[i].createdAt.seconds)).format(
                "MM-DD-YYYY"
              )
            : moment(formatDate(temp[i].createdAt)).format("MM-DD-YYYY");

        var tmp_weeknumber = moment(tmp_createdAt, "MM-DD-YYYY").isoWeek();
        // calcualte for month
        if (
          temp[i].time != undefined &&
          temp[i].time != "" &&
          temp_month == month
        ) {
          var __hour = moment(temp[i].time).format("H");
          var __min = moment(temp[i].time).format("m");
          _hour += parseInt(__hour);
          _min += parseInt(__min);
        }

        // calculate szSnipApplied
        szSnipMonth++;
        let express = temp[i].express;
        if (express == true && temp_month == month) {
          szSnipApplied++;
        }

        if (temp_month !== month) {
          let _h = _hour;
          let _m = _min;
          _h += parseInt(_m / 60);
          _m = _m % 60;
          var _time = `${_h}h ${_m}m`;
          if (_h == 0 && _m == 0) {
            _time = "";
          }
          _hour = 0;
          _min = 0;

          if (temp[i].time != undefined && temp[i].time != "") {
            var __hour = moment(temp[i].time).format("H");
            var __min = moment(temp[i].time).format("m");
            _hour += parseInt(__hour);
            _min += parseInt(__min);
          }

          var idx = temp2.findIndex((item) => item.month == month);
          var item = temp2[idx];
          var newItem = {
            ...item,
            time: _time,
            szSnipMonth: szSnipMonth,
            szSnipApplied: szSnipApplied,
          };

          szSnipMonth = 0;
          szSnipApplied = 0;
          if (express == true) {
            szSnipApplied++;
          }
          temp2[idx] = newItem;

          month = temp_month;
          temp2.push({ month: month });

          weeknumber = tmp_weeknumber;
          temp2.push({ weeknum: weeknumber });
        }

        if (tmp_weeknumber !== weeknumber) {
          weeknumber = tmp_weeknumber;
          temp2.push({ weeknum: weeknumber });
        }

        temp2.push(temp[i]);
      }

      if (_tmp_month != "") {
        var idx = temp2.findIndex((item) => item.month == _tmp_month);
        var item = temp2[idx];

        let _h = _hour;
        let _m = _min;
        _h += parseInt(_m / 60);
        _m = _m % 60;
        let _time = `${_h}h ${_m}m`;
        if (_h == 0 && _m == 0) {
          _time = "";
        }

        var newItem = {
          ...item,
          time: _time,
          szSnipMonth: szSnipMonth,
          szSnipApplied: szSnipApplied,
        };
        temp2[idx] = newItem;
      }
    }
    calculateWeek(temp2);
    return temp2;
  };

  const calculateWeek = (temp) => {
    var temp2 = temp;
    if (temp.length > 0) {
      var _hourw = 0;
      var _minw = 0;
      var szSnipMonthw = 0;
      var szSnipAppliedw = 0;
      var weekIndex = -1;
      for (var i = 0; i < temp.length; i++) {
        let item = temp[i];
        let month = item.month;
        let week = item.weeknum;
        if (month != undefined) {
          if (weekIndex > 0) {
            let _hw = _hourw;
            let _mw = _minw;
            _hw += parseInt(_mw / 60);
            _mw = _mw % 60;
            var _timew = `${_hw}h ${_mw}m`;
            if (_hw == 0 && _mw == 0) {
              _timew = "";
            }
            _hourw = 0;
            _minw = 0;
            var itemw = temp2[weekIndex];

            var newItemw = {
              ...itemw,
              time: _timew,
              szSnipMonth: szSnipMonthw,
              szSnipApplied: szSnipAppliedw,
            };
            temp2[weekIndex] = newItemw;
            weekIndex = -1;
          }
          _hourw = 0;
          _minw = 0;
          szSnipMonthw = 0;
          szSnipAppliedw = 0;
          continue;
        }
        if (week != undefined) {
          if (weekIndex > 0) {
            let _hw = _hourw;
            let _mw = _minw;
            _hw += parseInt(_mw / 60);
            _mw = _mw % 60;
            var _timew = `${_hw}h ${_mw}m`;
            if (_hw == 0 && _mw == 0) {
              _timew = "";
            }
            _hourw = 0;
            _minw = 0;
            var itemw = temp2[weekIndex];

            var newItemw = {
              ...itemw,
              time: _timew,
              szSnipMonth: szSnipMonthw,
              szSnipApplied: szSnipAppliedw,
            };
            temp2[weekIndex] = newItemw;
          }
          weekIndex = i;

          _hourw = 0;
          _minw = 0;
          szSnipMonthw = 0;
          szSnipAppliedw = 0;
        } else {
          if (item.time != undefined && item.time != "") {
            var __hourw = moment(item.time).format("H");
            var __minw = moment(item.time).format("m");
            _hourw += parseInt(__hourw);
            _minw += parseInt(__minw);
          }
          szSnipMonthw++;
          let expressw = temp[i].express;
          if (expressw == true) {
            szSnipAppliedw++;
          }
        }
      }

      let _hw = _hourw;
      let _mw = _minw;
      _hw += parseInt(_mw / 60);
      _mw = _mw % 60;
      var _timew = `${_hw}h ${_mw}m`;
      if (_hw == 0 && _mw == 0) {
        _timew = "";
      }
      _hourw = 0;
      _minw = 0;
      var itemw = temp2[weekIndex];

      var newItemw = {
        ...itemw,
        time: _timew,
        szSnipMonth: szSnipMonthw,
        szSnipApplied: szSnipAppliedw,
      };
      temp2[weekIndex] = newItemw;
    }
    return temp2;
  };

  const refreshList = async () => {
    if (!currentUser.empty && currentUser.loggedIn) {
      getInsights(currentUser.uid).then((data) => {
        data = data.sort(function (a, b) {
          var keyA = a.createdAt.seconds,
            keyB = b.createdAt.seconds;
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        setBooks(data);
      });
    } else {
      var data = localStorage.getItem("insightList");
      if (!data) {
        data = [];
      } else {
        data = JSON.parse(data);
      }
      setBooks(data);
    }
  };

  const handlePublicAll = async () => {
    if (!currentUser.empty && currentUser.loggedIn) {
      openModal(
        <MyInsightUrlModal
          currentUser={currentUser}
          refreshHash={refreshHash}
          theme={theme}
          hash={hash}
        />,
        "Set Profile",
        "books"
      );
    }
  };

  const openLink = (url) => {
    openModal(
      <MyInsightModal
        currentUser={currentUser}
        refreshList={refreshList}
        theme={theme}
        hash={hash}
        _link={url}
        paramType={paramType}
        paramTitle={paramTitle}
        paramChannel={paramChannel}
        paramDuration={paramDuration}
        // paramCategory={paramCategory}
      />,
      "New Insight",
      "books"
    );
  };
  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      <Helmet>
        <title>My TubeDiary - TubeDiary</title>
        <meta
          name="description"
          content="Build a sophisticated second brain by logging your notes and progress in relationship to the online material that you consume."
        />
      </Helmet>
      <PageBody>
        <ExploreContainer>
          <h1>My TubeDiary</h1>
          <p style={{ textAlign: "center" }}>
            Log the content you consume and write down the key insights that you
            want to act upon. <br />
            Bring the knowledge into action.{" "}
          </p>
          {isMobile ? (
            <div style={{ width: "100%" }}>
              <SearchBox
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {/* <Select
                className="mt-3"
                options={filterOptions}
                onChange={(val) => setCategoryFilter(val.value)}
                value={filterOptions.find((fo) => fo.value === categoryFilter)}
                isClearable={false}
                theme={theme}
              /> */}
              <PButton
                className="btn mt-3 w-100"
                onClick={() =>
                  openModal(
                    <MyInsightModal
                      currentUser={currentUser}
                      refreshList={refreshList}
                      theme={theme}
                      hash={hash}
                    />,
                    "New Insight",
                    "books"
                  )
                }
              >
                <FontAwesomeIcon icon={faPlus} /> New Insight
              </PButton>
            </div>
          ) : (
            <div style={{ width: '80%', marginTop: 10}}>
              <div style={{ margin: "auto", display: "flex", width: '50%' }}>
                <SearchBox
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  placeHolder={"Search"}
                />
                {/* <PButton
                    className="btn "
                    onClick={() =>
                      openModal(
                        <MyInsightModal
                          currentUser={currentUser}
                          refreshList={refreshList}
                          theme={theme}
                          hash={hash}
                        />,
                        "New Insight",
                        "books"
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </PButton> */}

                  {favFilter ? (
                    <PButtonActive
                      className="btn"
                      onClick={() => onChangeFav()}
                    >
                      <FontAwesomeIcon icon={faHeart} />
                    </PButtonActive>
                  ) : (
                    <PButton
                      className="btn"
                      onClick={() => onChangeFav()}
                    >
                      <FontAwesomeIcon icon={faHeart} />
                    </PButton>
                  )}
              </div>
              {/* <div style={{ display: "flex" }}>
                <FilterRow>
                  {filterOptions.map((f, index) => (
                    <div key={index} style={{ padding: "1rem" }}>
                      <Button
                        primary={f.value === categoryFilter}
                        secondary={f.value !== categoryFilter}
                        onClick={() => setCategoryFilter(f.value)}
                        label={f.label}
                      />
                    </div>
                  ))}
                </FilterRow>
                <FilterRow style={{ marginLeft: "-4rem" }}>
                  <PButton
                    className="btn mt-3 mb-3"
                    onClick={() =>
                      openModal(
                        <MyInsightModal
                          currentUser={currentUser}
                          refreshList={refreshList}
                          theme={theme}
                          hash={hash}
                        />,
                        "New Insight",
                        "books"
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </PButton>
                </FilterRow>
                <FilterRow style={{ marginLeft: "-3rem" }}>
                  {favFilter ? (
                    <PButtonActive
                      className="btn mt-3 mb-3 ml-3"
                      onClick={() => onChangeFav()}
                    >
                      <FontAwesomeIcon icon={faHeart} />
                    </PButtonActive>
                  ) : (
                    <PButton
                      className="btn mt-3 mb-3 ml-3"
                      onClick={() => onChangeFav()}
                    >
                      <FontAwesomeIcon icon={faHeart} />
                    </PButton>
                  )}
                </FilterRow>
              </div> */}
            </div>
          )}
        </ExploreContainer>
        <div
          className="w-100"
          style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
        >
          <TableBooks className="table">
            {!isMobile && (
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Channel</th>
                  {/* <th>Category</th> */}
                  <th>Date Added</th>
                  <th>Time Spent</th>
                  {/* <th>Tag</th> */}
                  <th>Favorite</th>
                  <th>Link</th>
                  {/* <th>Attachment</th> */}
                  {/* <th>Applied</th> */}
                  <th>Applied</th>
                  {/* <th>Public</th> */}
                  <th>Edit</th>
                  <th>Delete</th>
                  <th>
                    <HelpButton onClick={() => setIsHelpModal(true)}>
                      ?
                    </HelpButton>
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              {calFilterBook().length > 0 ? (
                <>
                  {calFilterBook().map((book, index) => (
                    <BookRow
                      key={index}
                      book={book}
                      currentUser={currentUser}
                      refreshList={refreshList}
                      insightList={books}
                      theme={theme}
                      hash={hash}
                      setIsCycleModal={setIsCycleModal}
                    />
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={"12"}>
                    <div className="text-center p-5">
                      <div>
                      Signed up users: Install the <a href="https://chromewebstore.google.com/detail/tubediary/amjlcpmoajaifbamcjligphblfaocmil" target="_blank">Chrome extension</a> to automatically begin tracking your YouTube consumption.
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </TableBooks>
        </div>
        {!currentUser.empty &&
          currentUser.loggedIn &&
          categoryFilter === "All" && (
            <PublicUrlBox className="row">
              <div className="col-md-4 mt-3">
                <a onClick={handlePublicAll} style={{ cursor: "pointer" }}>
                Set my TubeDiary Favourites URL
                </a>
              </div>
              <div className="col-md-8 d-flex mt-3">
                <input
                  style={{ marginLeft: "1rem" }}
                  value={publicUrl}
                  className="form-control"
                  readOnly
                />
              </div>
            </PublicUrlBox>
          )}
      </PageBody>
      {isHelpModal && (
        <HighlightsModal
          highlights={helpContent}
          title={"My TubeDiary"}
          isOpen={isHelpModal}
          setIsOpen={setIsHelpModal}
        />
      )}
      {isCycleModal && (
        <HighlightsModal
          highlights={cycleContent}
          title={"Growth Cycle"}
          isOpen={isCycleModal}
          setIsOpen={setIsCycleModal}
        />
      )}
    </DefaultLayout>
  );
};

export default MyInsight;

const HelpButton = styled.div`
  width: 20px;
  height: 20px;
  color: white;
  font-weight: 700;
  border-radius: 100%;
  cursor: pointer;
  background-color: var(--color-primary);
  display: grid;
  place-items: center;
  margin-left: 5px;
`;

const PublicUrlBox = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  width: 70%;
  float: right;
  > div {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  @media screen and (max-width: ${breakpoints.s}) {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 1rem;
    > div {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;
const FilterRow = styled.div`
  display: flex;
  padding: 2rem;
  @media screen and (max-width: ${breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 70px;

  > h1 {
    font-weight: 700;
    font-size: var(--font-size-1);
    color: var(--color-text-default);
  }
  > p {
    font-size: var(--font-size-4);
    max-width: 1030px;
    text-align: center;
    margin-top: 0;
    color: var(--color-text-default);
  }

  @media screen and (max-width: ${breakpoints.s}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${breakpoints.s}) {
  }
`;

const TableBooks = styled.table`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const BookRow = ({
  book,
  refreshList,
  currentUser,
  insightList,
  theme,
  hash,
  setIsCycleModal,
}) => {
  const { openModal } = useModal();
  const [open, setOpen] = useState(false);
  const [apply, setApply] = useState(book.apply ? book.apply : false);
  const [express, setExpress] = useState(book.express ? book.express : false);
  const [newFav, setNewFav] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });
  const fillColor = "#CCDAF5"; // #2B7BD9
  const bookId = book.id;
  const [state] = useMachine(
    insightMachine.withContext({
      insightId: bookId,
      insight: book,
      currentUser,
    })
  );

  useEffect(() => {
    const ff = book.fav !== undefined ? book.fav : false;
    setNewFav(ff);
  }, [book]);
  const handleRating = (rate) => {
    var temp = book;
    temp.rate = rate;
    if (!currentUser.empty && currentUser.loggedIn) {
      updateInsight(book.id, temp);
    } else {
      var selected = insightList.findIndex((item) => {
        return item.id === book.id;
      });
      insightList[selected] = temp;
      localStorage.setItem("insightList", JSON.stringify(insightList));
    }
    refreshList();
  };

  const handleFav = async () => {
    var _fav = !newFav;
    var temp = book;
    temp.fav = _fav;
    setNewFav(_fav);
    if (!currentUser.empty && currentUser.loggedIn) {
      await updateInsight(book.id, temp);
    } else {
      var selected = insightList.findIndex((item) => {
        return item.id === book.id;
      });
      insightList[selected] = temp;
      localStorage.setItem("insightList", JSON.stringify(insightList));
    }
    await refreshList();
  };

  /** set public to each insight -- 2022-07-08  */

  // const handlePublic = (checked) => {
  //   var temp = book;
  //   temp.public = checked;
  //   if (!currentUser.empty && currentUser.loggedIn){
  //     updateInsight(book.id, temp);
  //   }else{
  //     var selected = insightList.findIndex((item)=> {
  //       return item.id === book.id;
  //     })
  //     insightList[selected] = temp;
  //     localStorage.setItem('insightList', JSON.stringify(insightList));
  //   }
  //   refreshList();
  // };

  const handleApply = async (checked) => {
    var temp = book;
    temp.apply = checked;
    setApply(checked);
    if (!currentUser.empty && currentUser.loggedIn) {
      await updateInsight(book.id, temp);
    } else {
      var selected = insightList.findIndex((item) => {
        return item.id === book.id;
      });
      insightList[selected] = temp;
      localStorage.setItem("insightList", JSON.stringify(insightList));
    }
    // await refreshList();
  };

  const handleDelete = async (book) => {
    if (!currentUser.empty && currentUser.loggedIn) {
      await deleteInsight(book?.id);
    } else {
      var selected = insightList.findIndex((item) => {
        return item.id == book?.id;
      });
      insightList.splice(selected, 1);
      localStorage.setItem("insightList", JSON.stringify(insightList));
    }
    await refreshList();
  };

  const handleExpress = async () => {
    let _express = !express;
    var temp = book;
    temp.express = _express;
    setExpress(_express);
    if (!currentUser.empty && currentUser.loggedIn) {
      await updateInsight(book.id, temp);
    } else {
      var selected = insightList.findIndex((item) => {
        return item.id === book.id;
      });
      insightList[selected] = temp;
      localStorage.setItem("insightList", JSON.stringify(insightList));
    }
    await refreshList();
  };

  var createdAt = "";
  if (!currentUser.empty && currentUser.loggedIn) {
    createdAt = book.createdAt
      ? formatStripeDate(book.createdAt.seconds, true)
      : "";
  } else {
    createdAt = formatDateShort(book.createdAt);
  }
  var month = book.month;
  var time = book.time;

  let row = "";
  if (month !== undefined) {
    let percent =
      Math.floor((book.szSnipApplied / book.szSnipMonth) * 10000) / 100;
    row = (
      <InsightRow>
        <td colSpan={"4"}>
          <h3>{month}</h3>
        </td>
        <td colSpan={"7"}>
          <p
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0px",
            }}
          >
            <b>Applied: &nbsp; </b> {book.szSnipApplied}/{book.szSnipMonth} -{" "}
            {percent} %
          </p>
          {time != "" && (
            <p style={{ display: "flex", justifyContent: "end" }}>
              {" "}
              <b>Total Time: &nbsp; </b> {time}
            </p>
          )}
        </td>
      </InsightRow>
    );
  } else if (book.weeknum !== undefined) {
    let percentw =
      Math.floor((book.szSnipApplied / book.szSnipMonth) * 10000) / 100;

    row = (
      <InsightRow>
        <td colSpan={"4"}>
          <h4>Week {book.weeknum}</h4>
        </td>
        <td colSpan={"8"}>
          <p
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0px",
            }}
          >
            <b>Applied: &nbsp; </b> {book.szSnipApplied}/{book.szSnipMonth} -{" "}
            {percentw} %
          </p>
          {time != "" && (
            <p style={{ display: "flex", justifyContent: "end" }}>
              {" "}
              <b>Total Time: &nbsp; </b> {time}
            </p>
          )}
        </td>
      </InsightRow>
    );
  } else {
    row = (
      <Fragment>
        <InsightRow status={state}>
          <td
            onClick={() => setOpen((o) => !o)}
            style={{
              cursor: "pointer",
              maxWidth: "300px",
              minWidth: "300px",
              overflowWrap: "anywhere",
            }}
          >
            {book.title}
          </td>
          <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}>
            {book.author}
          </td>
          {/* <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}>
            {book.category}
          </td> */}
          <td
            onClick={() => setOpen((o) => !o)}
            style={{ cursor: "pointer", width: "200px" }}
          >
            {createdAt}
          </td>
          <td
            onClick={() => setOpen((o) => !o)}
            style={{ cursor: "pointer", width: "200px" }}
          >
            {book.time
              ? dayjs(book.time).format("H") +
                "h " +
                dayjs(book.time).format("mm") +
                "m"
              : ""}
          </td>
          {/* <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}>
            {book.tag}
          </td> */}
          <td>
            <div className="d-flex">
              {newFav ? (
                <FontAwesomeIcon
                  onClick={handleFav}
                  style={{ cursor: "pointer", color: "#f10000" }}
                  icon={faHeart}
                  size={"lg"}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={handleFav}
                  style={{ cursor: "pointer", color: "#cccccc" }}
                  icon={faHeart}
                  size={"lg"}
                />
              )}

              <div
                onClick={() => setOpen((o) => !o)}
                style={{ width: "100%", cursor: "pointer" }}
              ></div>
            </div>
          </td>
          <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}>
            <a href={book.link} target="_blank" rel="noreferrer">
              {book.link ? (
                <img
                  style={{ width: "31px" }}
                  src={theme === "light" ? external_link : external_link_white}
                  alt="View"
                />
              ) : (
                ""
              )}
            </a>
          </td>
          {/* <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}>
          {
            book.fileUrl && (
              <a href={book.fileUrl} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFile}/></a>
            )
          }
      </td>     */}
          {/* 
      set public to each insight -- 2022-07-08 

      <td>
        <div className="d-flex">
          <Switch uncheckedIcon={false} checkedIcon = {false} onChange={handlePublic} checked={book.public ? book.public : false} />
          <div onClick={() => setOpen((o) => !o)} style={{ width: '100%', cursor: "pointer"}}></div>
        </div>
      </td> */}
          {/* <td>
        <div className="d-flex">
          <Switch height={24} width={48} handleDiameter={22} onColor={fillColor} uncheckedIcon={false} checkedIcon = {false} onChange={handleApply} checked={apply ? apply : false} />
          <div onClick={() => setOpen((o) => !o)} style={{ width: '100%', cursor: "pointer"}}></div>
        </div>
      </td> */}
          <td>
            <div className="d-flex">
              {express ? (
                <FontAwesomeIcon
                  onClick={handleExpress}
                  style={{ cursor: "pointer", color: "#cccccc" }}
                  icon={faCheckSquare}
                  size={"lg"}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={handleExpress}
                  style={{ cursor: "pointer", color: "#cccccc" }}
                  icon={faSquare}
                  size={"lg"}
                />
              )}
              <div
                onClick={() => setOpen((o) => !o)}
                style={{ width: "100%", cursor: "pointer" }}
              ></div>
            </div>
          </td>
          <td>
            <CButton
              className="btn  btn-sm rounded-pill"
              onClick={(e) => {
                e.stopPropagation();
                openModal(
                  <MyInsightModal
                    book={book}
                    currentUser={currentUser}
                    refreshList={refreshList}
                    theme={theme}
                    hash={hash}
                    setIsCycleModal={setIsCycleModal}
                  />,
                  "Edit Insight",
                  "books"
                );
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </CButton>
          </td>
          <td>
            <CButton
              className="btn btn-sm rounded-pill"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(book);
              }}
            >
              <FontAwesomeIcon icon={faTrash}/>
            </CButton>
          </td>
          <td onClick={() => setOpen((o) => !o)}>
            <ToggleOpen style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={open ? faAngleUp : faAngleDown}
                size={"lg"}
              />
            </ToggleOpen>
          </td>
        </InsightRow>
        {open && (
          <tr className="test-td">
            <td colSpan={"12"} style={{ overflowWrap: "anywhere" }}>
              <ExpandRow className="p-3">
                {!book.note1 && !book.note2 && !book.note3 && !book.note && (
                  <div className="mb-1">
                    <strong>N/A</strong>
                  </div>
                )}
                {(book.note1 || book.note2 || book.note3) && (
                  <div className="mb-1">
                    <strong>Key Practical Takeaways</strong>
                  </div>
                )}
                {book.note1 && <div>{book.note1}</div>}
                {book.note2 && (
                  <div>
                    <span style={{ marginRight: "0.5rem" }}>2.</span>
                    {book.note2}
                  </div>
                )}
                {book.note3 && (
                  <div>
                    <span style={{ marginRight: "0.5rem" }}>3.</span>
                    {book.note3}
                  </div>
                )}
                {book.note && (
                  <div style={{ whiteSpace: "pre-line" }}>
                    <div className="mt-2 mb-1">
                      <strong>Reflection</strong>
                    </div>
                    {book.note}
                  </div>
                )}
              </ExpandRow>
            </td>
          </tr>
        )}
      </Fragment>
    );

    if (isMobile) {
      row = (
        <Fragment>
          <InsightRow status={state}>
            <td style={{ overflowWrap: "anywhere" }}>
              <div
                className="p-1"
                style={{ border: "1px solid #000", borderRadius: "4px" }}
              >
                <div
                  onClick={() => setOpen((o) => !o)}
                  style={{ cursor: "pointer" }}
                >
                  <strong>Title: </strong>
                  {book.title}
                </div>
                <div
                  onClick={() => setOpen((o) => !o)}
                  style={{ cursor: "pointer" }}
                >
                  <strong>Channel: </strong>
                  {book.author}
                </div>
                <div
                  onClick={() => setOpen((o) => !o)}
                  style={{ cursor: "pointer" }}
                >
                  <strong>Date Added: </strong>
                  {createdAt}
                </div>
                <div
                  onClick={() => setOpen((o) => !o)}
                  style={{ cursor: "pointer" }}
                >
                  <strong>Time Spent: </strong>
                  {book.time
                    ? dayjs(book.time).format("H") +
                      "h " +
                      dayjs(book.time).format("mm") +
                      "m"
                    : ""}
                </div>

                {/* <div
                  onClick={() => setOpen((o) => !o)}
                  style={{ cursor: "pointer" }}
                >
                  <strong>Tag: </strong>
                  {book.tag}
                </div> */}
                <div>
                  <strong>Favorite: </strong>
                  {newFav ? (
                    <FontAwesomeIcon
                      onClick={handleFav}
                      style={{ cursor: "pointer", color: "#f10000" }}
                      icon={faHeart}
                      size={"lg"}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={handleFav}
                      style={{ cursor: "pointer", color: "#cccccc" }}
                      icon={faHeart}
                      size={"lg"}
                    />
                  )}
                </div>
                <div>
                  <strong>Link: </strong>
                  <a href={book.link} target="_blank" rel="noreferrer">
                    {book.link ? (
                      <img
                        style={{ width: "23px" }}
                        src={
                          theme === "light"
                            ? external_link
                            : external_link_white
                        }
                        alt="View"
                      />
                    ) : (
                      ""
                    )}
                  </a>
                </div>
                {/* <div onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}><strong>Attachment:</strong> 
            {
              book.fileUrl && (
                <a className="m-3" href={book.fileUrl} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFile}/></a>
              )
            }
            </div> */}
                {/* <div className=""><strong style={{ marginRight: '10px' }}>Applied: </strong>
              <div style={{ float : 'right'}}>
                <Switch height={24} width={48} handleDiameter={22}  onColor={fillColor} uncheckedIcon={false} checkedIcon = {false} onChange={handleApply} checked={apply ? apply : false} />
              </div>
            </div> */}
                <div style={{ display: "flex" }} className="">
                  <strong style={{ marginRight: "10px" }}>Applied: </strong>
                  <div>
                    <input
                      type="checkbox"
                      checked={express ? express : false}
                      onChange={handleExpress}
                    />
                  </div>
                </div>
                <div className="text-center mt-3">
                  <CButton
                    className="btn rounded-pill btn-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal(
                        <MyInsightModal
                          book={book}
                          currentUser={currentUser}
                          refreshList={refreshList}
                          theme={theme}
                          hash={hash}
                          setIsCycleModal={setIsCycleModal}
                        />,
                        "New Insight",
                        "books"
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </CButton>
                </div>
                <div onClick={() => setOpen((o) => !o)} className="text-center">
                  <ToggleOpen className=" w-100" style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                      icon={open ? faAngleUp : faAngleDown}
                      size={"lg"}
                    />
                  </ToggleOpen>
                </div>
              </div>
            </td>
          </InsightRow>
          {open && (
            <tr className="test-td">
              <td style={{ overflowWrap: "anywhere" }}>
                <ExpandRow className="p-3">
                  {!book.note1 && !book.note2 && !book.note3 && !book.note && (
                    <div className="mb-1">
                      <strong>N/A</strong>
                    </div>
                  )}
                  {(book.note1 || book.note2 || book.note3) && (
                    <div className="mb-1">
                      <strong>Key Practical Takeaways</strong>
                    </div>
                  )}
                  {book.note1 && (
                    <div>
                      <span style={{ marginRight: "0.5rem" }}>1.</span>
                      {book.note1}
                    </div>
                  )}
                  {book.note2 && (
                    <div>
                      <span style={{ marginRight: "0.5rem" }}>2.</span>
                      {book.note2}
                    </div>
                  )}
                  {book.note3 && (
                    <div>
                      <span style={{ marginRight: "0.5rem" }}>3.</span>
                      {book.note3}
                    </div>
                  )}
                  {book.note && (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <div className="mt-2 mb-1">
                        <strong>Reflection</strong>
                      </div>
                      {book.note}
                    </div>
                  )}
                </ExpandRow>
              </td>
            </tr>
          )}
        </Fragment>
      );
    }
  }
  return row;
};

const InsightRow = styled.tr`
  vertical-align: middle;
  ${({ status }) =>
    status &&
    (status.matches("readyForSecondTick") ||
      status.matches("readyForThirdTick")) &&
    "background: var(--color-warning-20)"}
`;
const ExpandRow = styled.div`
  background: #85858530;
  white-space: break-spaces;
`;
const ToggleOpen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 100%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  &:hover {
    background-color: #eee;
  }
  @media screen and (max-width: ${breakpoints.s}) {
    color: var(--color-text-default);
    &:hover {
      background-color: transparent;
      color: var(--color-text-default);
    }
  }
`;

const CButton = styled.button`
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  &:hover {
    color: #ffffff;
    border-color: var(--color-primary);
    background: var(--color-primary);
  }
`;

const DButton = styled.button`
  color: var(--color-primary);
  border: 1px solid var(--color-danger-hover);
  &:hover {
    color: #ffffff;
    border-color: var(--color-danger-hover);
    background: var(--color-danger-hover);
  }
`;

const PButton = styled.button`
  margin-left: 1rem;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  &:hover {
    color: #ffffff;
    border-color: var(--color-primary);
  }
`;
const PButtonActive = styled.button`
  margin-left: 1rem;
  border: 2px solid var(--color-primary);
  background: var(--color-primary);
  color: #ffffff;
  &:hover {
    color: #ffffff;
    border-color: var(--color-primary);
  }
`;
