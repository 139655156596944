import styled from "styled-components";
import { binarify } from "../functions/misc";

const Button = ({ primary, secondary, tertiary, black, danger, label, icon, ...rest }) => {
  // converts boolean props to binary for ability to switch below. 4 = primary, 2 = secondary, 1 = tertiary, 0 = default = primary
  const type = binarify(danger, black, primary, secondary, tertiary);
  return (
    <>
      {
        {
          0: <PrimaryButton label={label} icon={icon} {...rest} />,
          1: <TertiaryButton label={label} icon={icon} {...rest} />,
          2: <SecondaryButton label={label} icon={icon} {...rest} />,
          4: <PrimaryButton label={label} icon={icon} {...rest} />,
          8: <BlackButton label={label} icon={icon} {...rest} />,
          16: <DangerButton label={label} icon={icon} {...rest} />,
        }[type]
      }
    </>
  );
};

export default Button;

const PrimaryButton = ({ label, icon, ...rest }) => {
  return (
    <>
      {label && icon && (
        <StyledPrimary {...rest}>
          <i>
            <img src={icon} alt="icon" />
          </i>
          <span>{label}</span>
        </StyledPrimary>
      )}
      {label && !icon && (
        <StyledPrimary {...rest}>
          <span>{label}</span>
        </StyledPrimary>
      )}
      {!label && icon && (
        <StyledPrimary icon {...rest}>
          <img src={icon} alt="icon" />
        </StyledPrimary>
      )}
    </>
  );
};
const DangerButton = ({ label, icon, ...rest }) => {
  return (
    <>
      {label && icon && (
        <StyledDanger {...rest}>
          <i>
            <img src={icon} alt="icon" />
          </i>
          <span>{label}</span>
        </StyledDanger>
      )}
      {label && !icon && (
        <StyledDanger {...rest}>
          <span>{label}</span>
        </StyledDanger>
      )}
      {!label && icon && (
        <StyledDanger icon {...rest}>
          <img src={icon} alt="icon" />
        </StyledDanger>
      )}
    </>
  );
};

const SecondaryButton = ({ label, icon, ...rest }) => {
  return (
    <>
      {label && icon && (
        <StyledSecondary {...rest}>
          <i>
            <img src={icon} alt="icon" />
          </i>
          <span>{label}</span>
        </StyledSecondary>
      )}
      {label && !icon && (
        <StyledSecondary {...rest}>
          <span>{label}</span>
        </StyledSecondary>
      )}
      {!label && icon && (
        <StyledSecondary icon {...rest}>
          <img src={icon} alt="icon" />
        </StyledSecondary>
      )}
    </>
  );
};
const TertiaryButton = ({ label, icon, ...rest }) => {
  return (
    <>
      {label && icon && (
        <StyledTertiary {...rest}>
          <i>
            <img src={icon} alt="icon" />
          </i>
          <span>{label}</span>
        </StyledTertiary>
      )}
      {label && !icon && (
        <StyledTertiary {...rest}>
          <span>{label}</span>
        </StyledTertiary>
      )}
      {!label && icon && (
        <StyledTertiary icon {...rest}>
          <img src={icon} alt="icon" />
        </StyledTertiary>
      )}
    </>
  );
};

const BlackButton = ({ label, icon, ...rest }) => {
  return (
    <>
      {label && icon && (
        <StyledBlack {...rest}>
          <i>
            <img src={icon} alt="icon" />
          </i>
          <span>{label}</span>
        </StyledBlack>
      )}
      {label && !icon && (
        <StyledBlack {...rest}>
          <span>{label}</span>
        </StyledBlack>
      )}
      {!label && icon && (
        <StyledBlack icon {...rest}>
          <img src={icon} alt="icon" />
        </StyledBlack>
      )}
    </>
  );
};
export const BaseButton = styled.button`
  border: 0;
  border-radius: 6px;
  padding: ${(p) => (p.icon ? "1rem" : "var(--padding-button-default)")};
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  transition: background-color 200ms;
  > span {
    color: white;
    font-size: 16px;
    font-weight: 700;
    transition: color 300ms;
  }
  > i {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
  > i,
  img {
    max-height: 12px;
  }
  ${({ disabled }) =>
    disabled &&
    "background-color: #eee !important; border-color: #eee !important;"}
`;

const StyledPrimary = styled(BaseButton)`
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  > span {
    color: var(--color-primary-contrast);
  }
  > i {
    color: var(--color-primary-contrast);
  }
  &:hover {
    background-color: white;
    border: 2px solid var(--color-primary-hover);
    > span {
      color: var(--color-primary);
    }
  }
`;

const StyledDanger = styled(BaseButton)`
  border: 2px solid var(--color-danger);
  background-color: var(--color-danger);
  > span {
    color: var(--color-danger-contrast);
  }
  > i {
    color: var(--color-danger-contrast);
  }
  &:hover {
    background-color: white;
    border: 2px solid var(--color-danger-hover);
    > span {
      color: var(--color-danger);
    }
  }
`;

const StyledSecondary = styled(BaseButton)`
  border: 2px solid var(--color-primary);
  background: none;
  > span {
    color: var(--color-primary);
  }
  > i {
    color: var(--color-primary);
  }
  &:hover {
    background-color: var(--color-primary);
    > span {
      color: white;
    }
  }
`;

const StyledTertiary = styled(BaseButton)`
  background: none;
  > span {
    color: var(--color-primary);
  }
  > i {
    color: var(--color-primary);
  }
  &:hover {
    background-color: var(--color-hover-opacity);
  }
`;

const StyledBlack = styled(BaseButton)`
  background: none;
  > span {
    color: var(--color-text-default);
  }
  > i {
    color: var(--color-text-default);
  }
  &:hover {
    background-color: var(--color-hover-opacity);
  }
`;
