import styled from "styled-components";

const Tag = styled.div`
  width: 42px;
  height: 27px;
  text-align: center;
  line-height: 27px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
`;

export const FreeTag = () => {
  return <StyledFreeTag>Free</StyledFreeTag>;
};

const StyledFreeTag = styled(Tag)`
  width: 42px;
  background-color: #7ed321;
`;

export const PremiumTag = () => {
  return <StyledPremiumTag>Premium</StyledPremiumTag>;
};

export const ComingSoonTag = () => {
  return <StyledComingSoonTag>Coming Soon</StyledComingSoonTag>;
};

export const StyledPremiumTag = styled(Tag)`
  width: 78px;
  background-color: var(--color-primary);
`;

export const StyledComingSoonTag = styled(Tag)`
  width: 108px;
  background-color: var(--bs-orange);
`;
const StyledNewTag = styled(Tag)`
  color: var(--color-primary);
  background-color: white;
  border: 1px solid #dedede;
  width: 50px;
  margin-left:10px;
`;

export const NewTag = () => {
  return <StyledNewTag>New</StyledNewTag>;
};
