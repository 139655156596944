import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";

import TextArea from "./TextArea";

import { useNotifications } from "../context/Notifications";
import { useModal } from "../context/Modal";

import { addInsight, updateInsightPublic, checkUserHash } from "../firebase/firebase.utils";
import { breakpoints } from "../styles/globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button, { BaseButton } from "../components/Button";

const MyInsightUrlModal = ({ refreshHash, currentUser, theme, hash }) => {
  const [_hash, setHash] = useState(hash ? hash : "");
  const [exist, setExist] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const N = useNotifications();

  const { closeModal } = useModal();

  useEffect(() => {
    
  }, []);


  const handleSubmit = async () => {
    await updateInsightPublic(currentUser.uid, _hash);
    await refreshHash();
    closeModal();
  };
  
  var timer = null;
  const handleInputChange = (e) => {

    const newHash = e.target.value;
    if(newHash) {
      setIsNew(true);
    }else{
      setIsNew(false);
    }
    clearTimeout(timer);
    setHash(newHash);
    if(newHash.length === 0) {
      return;
    }
    timer = setTimeout(async() => {
      // check hash is available
      try{
        console.log('checkUserHash');
        const uid = await checkUserHash(newHash, currentUser.uid);
        if(!uid) {
          console.log(uid, 'is null');
          setExist(false);
        }else if(uid !== currentUser.uid) {
          console.log(uid, 'is not null');
          setExist(true);
        }
      }catch(err){
        console.log(err);
        N.error(err);
      }
    }, 2000);
  };

  const overlayClick = (e) => {
    e.stopPropagation();
    closeModal();
  };
  return (
    <Container>
      <div className="mb-3 pb-3">
        <span></span>
        <FontAwesomeIcon
          className="float-end"
          icon={faTimes}
          size="lg"
          style={{ cursor: "pointer" }}
          onClick={overlayClick}
        />
      </div>
      <InsightFormContainer>
        <div className="p-2">
          Choose a profile name for your shareable insights page.
        </div>
          <DetailsRow>
            <BookDetailForm
              title={"Name"}
              value={_hash}
              valueName={"hash"}
              handleInputChange={handleInputChange}
              theme={theme}
            />
            { (exist && isNew) && (
              <UnAvailableMessage>This profile name is already being used</UnAvailableMessage>
            )}
            {(!exist && isNew) && (
              <AvailableMessage>This profile name is available</AvailableMessage>
            )}
            {
              _hash.length === 0 && (
                <UnAvailableMessage>This field is required</UnAvailableMessage>
              )
            }
          </DetailsRow>
      </InsightFormContainer>
      <BookButtonRow>
        <div style={{ marginRight: "1rem" }}>
          <Button
            tertiary
            label="Cancel"
            onClick={closeModal}
          />

        </div>
        <div>
          <Button
            primary
            label="Save"
            onClick={handleSubmit}
          />
        </div>
      </BookButtonRow>
    </Container>
  );
};

export default MyInsightUrlModal;
const AvailableMessage = styled.div`
color: var(--color-success);
padding-left: 2rem;
padding-right: 2rem;
`;

const UnAvailableMessage = styled.div`
color: var(--color-warning);
padding-left: 2rem;
padding-right: 2rem;
`;

const InsightFormContainer = styled.div`
  max-height: 50vh;
  overflow: auto;
  @media screen and (max-width: ${breakpoints.s}) {
    max-height: 80vh;
  }
`;
const BookButtonRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding: 0.5rem;
`;

const Container = styled.div`
    position: relative;
    width: 500px;
    margin: auto;
    margin-top: 50vh;
    transform: translateY(-50%);
    background: var(--background-panel);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    @media screen and (max-width: ${breakpoints.s}) {
      width: 100%;
      height: 100vh;
    }
`;

const BookHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BookBody = styled.div`
  display: grid;
  grid-template-columns: 33% 66%;
  margin-top: 2rem;
`;

const BookCoverHolder = styled.div`
  padding: 0.5rem;
  width: 100%;
  > img {
    width: 72%;
  }
`;

const BookDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1rem;
`;

const DetailsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BookDetailForm = ({
  title,
  value,
  valueName,
  handleInputChange,
  type,
  theme,
}) => {
  return (
    <Detail>
      <DetailTitle>{title}</DetailTitle>
      {type === "textarea" && (
        <TextArea  className="form-control" onChange={handleInputChange} name={valueName} value={value} />
      )}
      {type === "number" && (
        <input className="form-control" onChange={handleInputChange} name={valueName} value={value} />
      )}
      {!type && (
        <input className="form-control" onChange={handleInputChange} name={valueName} value={value} />
      )}
    </Detail>
  );
};

const Detail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0.5rem;
  margin-bottom: 0.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailTitle = styled.span`
  font-size: var(--font-size-7);
  margin-bottom: 0.25rem;
  font-weight: 700;
`;

const TitleSuperscript = styled.span`
  font-size: var(--font-size-5);
`;
