import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";

import Input from "./Input";
import TextArea from "./TextArea";
import Button from "./Button";
import Select from "./Select";

import { useNotifications } from "../context/Notifications";
import { useModal } from "../context/Modal";

import { addInsight, updateInsight, deleteInsight } from "../firebase/firebase.utils";
import { breakpoints } from "../styles/globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes, faStar } from "@fortawesome/free-solid-svg-icons";
import { Rating } from 'react-simple-star-rating'
import useUpload from "../tools/useUpload";
import Dropzone from "./Dropzone";
import Switch from "react-switch";
import InsightCycle from "./InsightCycle";

const categoryOptions = [
  {
    label: "Videos",
    value: "Videos",
  },
  {
    label: "Podcasts",
    value: "Podcasts",
  },
  {
    label: "Articles",
    value: "Articles",
  },
  {
    label: "Misc",
    value: "Books",
  },
];

const SubscribeWarning = ({ currentUser, theme, subscribe}) => {

  const { closeModal } = useModal();

  useEffect(() => {

  }, []);


  const handleSubmit = () => {
    subscribe();
    closeModal();
  };


  const overlayClick = (e) => {
    e.stopPropagation();
    closeModal();
  };

  return (
    <Container>
      <div className="mb-3 pb-3">
        <span></span>
        <FontAwesomeIcon
          className="float-end"
          icon={faTimes}
          size="lg"
          style={{ cursor: "pointer" }}
          onClick={overlayClick}
        />
      </div>
      <InsightFormContainer>
          <DetailsRow>
            Please subscribe to access.
          </DetailsRow>
          
      </InsightFormContainer>
      <BookButtonRow>
        <div style={{ marginRight: "1rem" }}>
          <button
            className="btn btn-outline-primary"
            onClick={closeModal}
          >Cancel</button>
        </div>
        <div >
          <button
            className="btn btn-primary"
            onClick={handleSubmit}>
              Subscribe
              </button>
        </div>
      </BookButtonRow>
    </Container>
  );
};

export default SubscribeWarning;

const InsightFormContainer = styled.div`
  max-height: 50vh;
  overflow: auto;
  @media screen and (max-width: ${breakpoints.s}) {
    max-height: 80vh;
  }
`;
const BookButtonRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding: 0.5rem;
`;

const Container = styled.div`
    position: relative;
    width: 50%;
    margin: auto;
    margin-top: 50vh;
    transform: translateY(-50%);
    background: var(--background-panel);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    @media screen and (max-width: ${breakpoints.s}) {
      width: 100%;
      height: 100vh;
    }
`;

const BookHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BookBody = styled.div`
  display: grid;
  grid-template-columns: 33% 66%;
  margin-top: 2rem;
`;

const BookCoverHolder = styled.div`
  padding: 0.5rem;
  width: 100%;
  > img {
    width: 72%;
  }
`;

const BookDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1rem;
`;

const DetailsRow = styled.div`
  width: 100%;
`;

const BookDetailForm = ({
  title,
  value,
  valueName,
  handleInputChange,
  type,
  theme,
}) => {
  return (
    <Detail>
      <DetailTitle>{title}</DetailTitle>
      {type === "textarea" && (
        <TextArea  className="form-control" onChange={handleInputChange} name={valueName} value={value} />
      )}
      {type === "category" && (
        <div style={{ maxWidth: "100%" }}>
          <Select
            onChange={handleInputChange}
            options={categoryOptions}
            value={value}
            theme={theme}
          />
        </div>
      )}
      {type === "number" && (
        <input className="form-control" onChange={handleInputChange} name={valueName} value={value} />
      )}
      {!type && (
        <input className="form-control" onChange={handleInputChange} name={valueName} value={value} />
      )}
    </Detail>
  );
};

const Detail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0.5rem;
  margin-bottom: 0.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailTitle = styled.span`
  font-size: var(--font-size-7);
  margin-bottom: 0.25rem;
  font-weight: 700;
`;

const TitleSuperscript = styled.span`
  font-size: var(--font-size-5);
`;
