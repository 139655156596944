import { createGlobalStyle } from "styled-components";

export const breakpoints = {
  xs: "420px",
  s: "820px",
  m: "1280px",
  l: "1440px",
};

const GlobalStyle = createGlobalStyle`
    :root {
        --color-black: #000000;
        --color-dark-gray: #4a4a4a;
        --color-white: #ffffff;
        --color-dark-opacity: ${({ theme }) => theme.darkOpacity };
        --color-growthnotes-blue: ${({ theme }) => theme.primary };
        --color-growthnotes-blue-light: #76AAE6;
        --color-growthnotes-green: #B1E367;
        --color-growthnotes-danger: #dc3545;
        --color-growthnotes-danger-light: #dc354569;

        --breakpoint-s: 420px;
        --breakpoint-m: 720px;
        --breakpoint-l: 1280px;
        --breakpoint-xl: 1440px;

    }
    html {
        --color-primary: ${({ theme }) => theme.primary };
        --color-primary-contrast: white;
        --color-primary-hover: var(--color-growthnotes-blue-light);
        --color-secondary: var(--color-growthnotes-green);
        --color-secondary-hovered: #b4d288;
        --color-danger: var(--color-growthnotes-danger);
        --color-danger-contrast: white;
        --color-danger-hover: var(--color-growthnotes-danger-light);
        --color-paper: var(--color-white);
        --color-hover-opacity: var(--color-dark-opacity);

        --color-text-default: ${({ theme }) => theme.text87 };
        --color-text-second: ${({ theme }) => theme.text60 };
        --color-success: ${({ theme }) => theme.success };
        --color-warning: ${({ theme }) => theme.warning };
        --color-warning-20: ${({ theme }) => theme.warning20 };

        --shadow-default: 0 2px 34px 0 rgba(0, 0, 0, 0.3);

        --highlight-opacity: var(--color-dark-opacity);

        --padding-button-default: 0.5rem 3rem;

        --z-index-notifications: 10000;
        --z-index-modal: 1000;
        --z-index-header: 50;
        --z-index-date-picker: 200;

        --box-shadow-1: 2px 2px 2px rgba(43, 66, 129, 0.25);
        --box-shadow-2: 5px 5px 5px rgba(43, 66, 129, 0.25);
        --box-shadow-3: 8px 8px 8px rgba(43, 66, 129, 0.25);

        --background: ${({ theme }) => theme.body};
        --background-panel: ${({ theme }) => theme.panel};
        --background-dropzone: ${({ theme }) => theme.panel};
        --header-border: ${({ theme }) => theme.border};
        @media screen and (max-width: ${breakpoints.s}) {
            --font-size-1: 30px;
            --font-size-2: 28px;
            --font-size-3: 22px;
            --font-size-4: 19px;
            --font-size-5: 17px;
            --font-size-6: 14px;
        }

        @media screen and (min-width: ${breakpoints.s}) {
            --font-size-1: 32px;
            --font-size-2: 30px;
            --font-size-3: 24px;
            --font-size-4: 21px;
            --font-size-5: 18px;
            --font-size-6: 16px;
        }
        body {
            background: ${({ theme }) => theme.background};
            color: ${({ theme }) => theme.text87};
            transition: all 0.50s linear;
            .table {
                color: var(--color-text-default);
            }
        }

        --special-bookshadow: ${({ theme }) => theme.bookShadow};
        input, select, textarea {
            background: #ffffff0d !important;
            color: var(--color-text-default) !important;
        }
        tbody, td, tfoot, th, thead, tr{
            border-color: ${({ theme }) => theme.border};
        }
        .custom_avatar {
            border-radius: 100%;
            object-fit: contina;
        }
    }
`;

export default GlobalStyle;
