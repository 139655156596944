import React, { useState } from "react";
import styled from "styled-components";

import { useNotifications } from "../context/Notifications";

import { breakpoints } from "../styles/globalStyles";

import { emailIsValid } from "../functions/misc";

import Button from "../components/Button";
import Input from "../components/Input";

import DefaultLayout from "../layouts/DefaultLayout";

import Loading from "../components/Loading";

import { signIn, sendPasswordResetEmail } from "../firebase/firebase.utils";
import { Helmet } from "react-helmet";
const LoginPage = ({ currentUser, themeToggler, theme }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { notify } = useNotifications();

  const [forgotPassword, setForgotPassword] = useState(false);

  const handleEmailChange = (e) => {
    setError("");
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setError("");
    setPassword(e.target.value);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email required");
      return;
    }
    if (!emailIsValid(email)) {
      setError("Email is invalid");
      return;
    }
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(email);
      setIsLoading(false);
      setPassword("");
      setEmail("");
      setForgotPassword(false);
      notify("Reset password link sent to the email address you entered");
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  const login = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email required");
      return;
    }
    if (!emailIsValid(email)) {
      setError("Email is invalid");
      return;
    }
    if (!password) {
      setError("Password required");
      return;
    }

    try {
      setIsLoading(true);
      await signIn(email, password);
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      <Helmet>
        <title>Login - TubeDiary</title>
        <meta name="description" content="Login to TubeDiary – a second brain and book summary platform - with your username and password. If you've forgotten your password, you know where to click." />
      </Helmet>
      <PageBody>
        <FormContainer>
          <FormTitle>Login</FormTitle>
          {!forgotPassword ? (
            <LoginForm>
              <Input value={email} onChange={handleEmailChange} label="Email" />
              <Input
                value={password}
                type="password"
                onChange={handlePasswordChange}
                label="Password"
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {isLoading ? (
                <Loading />
              ) : (
                <LoginButton label="Login" onClick={login} />
              )}
            </LoginForm>
          ) : (
            <LoginForm>
              <Input value={email} onChange={handleEmailChange} label="Email" />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {isLoading ? (
                <Loading />
              ) : (
                <LoginButton
                  label="Forgot Password"
                  onClick={handleForgotPassword}
                />
              )}
            </LoginForm>
          )}

          {!isLoading && (
            <ForgotPasswordLink
              onClick={() => {
                setError("");
                setEmail("");
                setPassword("");
                setForgotPassword((v) => !v);
              }}
            >
              {!forgotPassword ? "Forgot your password?" : "Back to Login"}
            </ForgotPasswordLink>
          )}
        </FormContainer>
      </PageBody>
    </DefaultLayout>
  );
};

export default LoginPage;

const ErrorMessage = styled.span`
  color: red;
`;

const ForgotPasswordLink = styled.span`
  margin: 0.25rem;
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
`;

const LoginButton = styled(Button)`
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 3rem;
  > span {
    font-size: 20px;
    font-weight: unset;
  }
`;

const FormTitle = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-text-default);
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginForm = styled.form`
  @media screen and (max-width: ${breakpoints.s}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: ${breakpoints.s}) {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 2rem;
`;
