import React, { useState, useEffect, Children } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import { breakpoints } from "../styles/globalStyles";

import Loading from "../components/Loading";

import { v4 as uuidv4 } from "uuid";

import { useNotifications } from "../context/Notifications";

import Button from "./Button";

import { useModal } from "../context/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { pricing } from "../config";

import { signUp, auth, signIn, updateInsightPublic } from "../firebase/firebase.utils";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import useStripeTools from "../stripe/useStripeTools";
import { prices } from "../stripe/config";

import backend from "../axiosBackend";

const createUser = async (uid, email, password, name) => {
  try {
    const response = await backend.post("/user", {
      uid,
      email,
      password,
      name
    });
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const signupUser = async (uid, email, password, name, isFree) => {
  try {
    const response = await backend.post("/user-signup", {
      uid,
      email,
      password,
      name,
      isFree
    });
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const SignupCard = ({ isModal, mode, theme }) => {
  const stripe = useStripe();
  const elements = useElements();
  const N = useNotifications();
  const { closeModal } = useModal();
  const [plan, setPlan] = useState('yearly');
  const [stage, setStage] = useState(mode ? "provide_details" : "configure");

  const [loading, setLoading] = useState(0);
  const [validating, setValidating] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [couponApplied, setCouponApplied] = useState(null);
  const [couponInvalid, setCouponInvalid] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [isFree, setIsFree] = useState(false);

  const {
    createSubscription,
    validPromoCode,
    applyPromoCode,
    validCard,
    retryInvoicePayment,
    cancelSubscription,
    updatePaymentDetails,
    clearPromoCode,
    setValidCard,
    signUpWithPayment,
  } = useStripeTools();

  useEffect(() => {
    if (validPromoCode) {
      if (validPromoCode.percent_off) {
        if (validPromoCode.percent_off == 100) { setIsFree(true) }
        setCouponApplied({
          annual: pricing.annual.pricePerMonth - pricing.annual.pricePerMonth *
            (validPromoCode.percent_off / 100),
          monthly: pricing.monthly.pricePerMonth - pricing.monthly.pricePerMonth *
            (validPromoCode.percent_off / 100)
        });
      } else {
        setCouponApplied({
          annual: pricing.annual.pricePerMonth - (validPromoCode.amount_off / 100),
          monthly: pricing.monthly.pricePerMonth - (validPromoCode.amount_off / 100)
        });
      }
    } else {
      setCouponApplied(null);
    }
  }, [validPromoCode]);

  const handleApplyPromoCode = async () => {
    if (!promoCode) return;
    try {
      setValidating(true);
      setCouponInvalid(false);
      const codeApplied = await applyPromoCode({ promoCode, userId: "hello" });
      if (codeApplied) {
        setCouponInvalid(false);
      } else {
        setCouponInvalid(true);
      }
    } finally {
      setValidating(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      setLoading(1);
      if (!name) {
        N.error(null, "Name is required");
      }
      if (!email) {
        N.error(null, "An email is required");
        return;
      }
      if (!password) {
        N.error(null, "Password is required");
        return;
      }
      if (password.length < 6) {
        N.error(null, "Password must be 6 characters or more");
        return;
      }
      if (!email.includes("@")) {
        N.error(null, "Please enter a valid email");
        return;
      }
      if (!validCard && !isFree) {
        N.error(null, "Please enter a valid Card Detail");
        return;
      }

      const uid = uuidv4();

      const billingDetails = {
        name: name,
        email: email,
      };

      let result;

      if (!isFree) {
        try {
          result = await signUpWithPayment({
            userId: uid,
            priceId: plan === "yearly" ? prices.annual : prices.monthly,
            email: email,
            password: password,
            name: name,
            billingDetails
          });
        } catch (err) {
          N.error(
            err,
            "There was an issue with your payment details. You will need to start your subscription"
          );
          return;
        }
      }

      try {
        result = await signupUser(uid, email, password, name, isFree);
        if (result.data.error) {
          N.error(
            null,
            "This email is already in use. Please sign in or use a different email address"
          );
          return;
        }
      } catch (err) {
        console.log("Create User failed");
        console.error(err);
        return;
      }

      await signIn(email, password);
      
      try {
        await updateInsightPublic(uid, name);
      } catch (err) {
        console.log("Create Hash failed");
        console.error(err);
      }

      window.location.replace("/insights");
    } catch (err) {
      N.error(err, "Something went wrong");
    } finally {
      setLoading(0);
    }
  };

  var cardOption = {
    style: {
      base: {
        backgroundColor: "#ffffff00",
        fontSize: "20px",
        color:
          theme === "light" ? "rgba(0, 0, 0,0.87)" : "rgba(255, 255, 255,0.87)",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  return (
    <CardContainer isModal={isModal}>
      {loading === 1 && (
        <LoadingCover>
          <Loading />
        </LoadingCover>
      )}
      {isModal && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem",
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      <SecondContainer isModal={isModal} loading={loading}>
        <h3 className="text-center m-3">Sign Up</h3>
        <PaymentFormContainer>
          <MonthlyYearly>
            <PlanSelector>
              <PlanButton
                active={plan === "monthly"}
                onClick={() => setPlan("monthly")}
              >
                <div>
                  <span>${pricing.monthly.pricePerMonth} per month</span>
                </div>
              </PlanButton>
              <PlanButton
                active={plan === "yearly"}
                onClick={() => setPlan("yearly")}
              >
                <div>
                  <span>${pricing.annual.pricePerMonth} per year</span>
                </div>
              </PlanButton>
            </PlanSelector>
          </MonthlyYearly>
          <Summary>
            <div>
              <span>
                {plan === "yearly" ? "Annual Plan" : "Monthly Plan"}
              </span>
              <span>
                {plan === "yearly" ? "Billed per Year" : "Billed per Month"}
              </span>
            </div>
            <div>
              {/* <span
                  style={{
                    textDecoration: "line-through",
                    color: "#ccc",
                    fontSize: "12px",
                  }}
                >
                  {plan === "yearly"
                    ? "$" + ( pricing.annual.origin).toFixed(2)
                    : "$" + pricing.monthly.origin.toFixed(2)}
                </span> */}
              <span
                style={{
                  textDecoration: validPromoCode ? "line-through" : "unset",
                  color: validPromoCode ? "#ccc" : "var(--color-primary)",
                  fontSize: validPromoCode ? "12px" : "16px",
                }}
              >
                {plan === "yearly"
                  ? "$" + (pricing.annual.pricePerMonth).toFixed(2)
                  : "$" + pricing.monthly.pricePerMonth.toFixed(2)}
              </span>

              {validPromoCode && couponApplied && (
                <span>
                  {plan === "yearly"
                    ? "$" + couponApplied?.annual.toFixed(2)
                    : "$" + couponApplied?.monthly.toFixed(2)}
                </span>
              )}
            </div>
          </Summary>
          <Plinth>
            <Label>
              Promotion Code{" "}
              {validPromoCode && (
                <span
                  style={{
                    fontWeight: 700,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={clearPromoCode}
                >
                  Remove Code
                </span>
              )}
            </Label>
            <CouponSection>
              {validPromoCode ? (
                <span>{validPromoCode.name}</span>
              ) : (
                <Input
                  placeholder={""}
                  onChange={(e) => setPromoCode(e.target.value)}
                  value={promoCode}
                />
              )}

              {validating ? (
                <Loading />
              ) : (
                <>
                  {validPromoCode ? (
                    <div></div>
                  ) : (
                    <Button
                      secondary
                      label={"Apply"}
                      onClick={handleApplyPromoCode}
                    />
                  )}
                </>
              )}
            </CouponSection>
            {couponInvalid === true && (
              <span style={{ fontSize: "12px", color: "red" }}>
                Coupon Invalid
              </span>
            )}
          </Plinth>
          <Plinth>
            <Label>First Name</Label>
            <Input
              placeholder={""}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Plinth>
          <Plinth>
            <Label>Email</Label>
            <Input
              placeholder={""}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Plinth>
          <Plinth>
            <Label>Password</Label>
            <Input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Plinth>
          {!isFree &&
            <Plinth>
              <Label>Card Details</Label>
              <div>
                <CardElement
                  options={cardOption}
                  onChange={(e) => {
                    if (e.complete) {
                      setValidCard((vc) => true);
                    } else {
                      setValidCard((vc) => false);
                    }
                  }}
                />
              </div>
            </Plinth>
          }
          <button
            className="mb-3 btn btn-primary p-2"
            style={{ background: "var(--color-primary)", borderColor: "var(--color-primary)" }}
            label={""}
            disabled={!name || !password || !email || (!validCard && !isFree)}
            onClick={handleSubmit}
          >
            Sign up
          </button>
          <span style={{
            color: "gray",
            textAlign: "center",
            fontSize: "12px",
            marginTop: "4px",
          }}>
            All payments are processed with Stripe
          </span>
        </PaymentFormContainer>

      </SecondContainer>

      {auth.currentUser && !loading && <AlreadySignedInCard />}
    </CardContainer>
  );
};

export default SignupCard;

const AlreadySignedInCard = () => {
  return (
    <SignedInCardContainer>
      <span>You are already signed up!</span>
    </SignedInCardContainer>
  );
};

const SignedInCardContainer = styled.div`
  width: 100%;
  padding: 2rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  > span {
    font-size: 26px;
    color: var(--color-text-default);
    font-weight: 700;
  }
`;

const CouponSection = styled.div`
  > input {
    width: 70%;
  }
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
`;

const LoadingCover = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--background-panel);
  z-index: 100;
`;

const PlanSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: 0.5rem;
  border: 1px solid #dedede;
  border-radius: 12px;
  overflow: hidden;
`;

const PlanButton = styled.button`
  width: 50%;
  height: 40px;

  border: none;
  display: grid;
  place-items: center;
  cursor: pointer;

  background: ${({ active }) => (active ? "var(--color-primary)" : "none")};
  outline: none;

  > div {
    display: flex;
    flex-direction: column;
    > span {
      font-size: 16px;
      font-weight: 700;
      color: ${({ active }) => (active ? "white" : "var(--color-primary)")};
    }
  }

  &:hover {
    background: ${({ active }) => (active ? "var(--color-primary)" : "#f3f3f3")};
  }
`;

const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding:1rem;
  background:none;

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    > span {
      margin-right: 0.5rem;
    }
    > span:first-child {
      font-size: 16px;
    }
    > span:nth-child(2) {
      font-size: 12px;
    }
  }
  > div:nth-child(2) {
    border-radius: 6px;
    border: 2px solid var(--color-primary);
    display: grid;
    place-items: center;
    padding: 1rem;
    > span {
      font-size: 16px;
      font-weight: 700;
      color: var(--color-primary);
    }
  }
`;

const SummaryBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const Label = styled.label`
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  padding: 1px 2px;
  font-weight: 700;
  color: var(--color-text-default);
`;

const PaymentFormContainer = styled.div`
  padding: 0rem 2rem 1rem 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Plinth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff09;
  place-items: center;
  width: 100%;
  padding: 0.5rem;
  border-radius: 12px;

  margin-bottom: 1rem;

  > div {
    display: block;
    width: 100%;
    height: fit-content;
  }
`;

const Input = styled.input`
  padding: 1px 2px;
  width: 100%;
  border: none;
  background-color: unset !important;
  color: #424770;
  font-size: 20px;

  &:focus {
    outline: none;
  }
`;

const MonthlyYearly = styled.div`
  width: 100%;
  > span:first-child {
    font-size: 18px;
    color: var(--color-text-default);
  }
`;

const PaymentTypeHolder = styled.div``;

const SmallPrint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    margin-bottom: 1rem;
    font-size: 14px;
  }
  > span:first-child {
    font-weight: 700;
    color: #2d7ad9;
  }
  > span:nth-child(2) {
    color: var(--color-text-default);
  }
  > span:nth-child(3) {
    color: #979797;
  }
`;

const BigContainer = styled.div`
  height: ${({ isModal }) => (isModal ? "90%" : "100%")};
`;

const FirstContainer = styled(BigContainer)`
  width: 100%;
  display: ${({ loading }) => (loading ? "none" : "flex")};
  padding: 2rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  > span {
    font-size: 26px;
    color: var(--color-text-default);
    font-weight: 700;
  }
`;

const SecondContainer = styled(BigContainer)`
  width: 100%;
  display: ${({ loading }) => (loading ? "none" : "flex")};
  flex-direction: column;
  justify-content: space-between;
`;

/*
const CardContainer = styled.div`
  @media screen and (max-width: ${breakpoints.s}) {
    width: ${({ isModal }) => (isModal ? "100%" : "100%")};
    ${({ isModal }) => isModal && "height: 100vh;"}

    background-color: var(--color-paper);
  }

  @media screen and (min-width: ${breakpoints.s}) {
    border-radius: 12px;
    width: 470px;
    margin: 8rem 0;
    height: 600px;
    background-color: var(--color-paper);
    box-shadow: var(--shadow-default);
  }
`;
*/

const CardContainer = styled.div`
  @media screen and (max-width: ${breakpoints.s}) {
    width: ${({ isModal }) => (isModal ? "100%" : "100%")};
    ${({ isModal }) => isModal && "height: 100vh;"}
    overflow-y: scroll;
    background-color: var(--background-panel);
  }

  @media screen and (min-width: ${breakpoints.s}) {
    border-radius: 12px;
    width: 100%;
    margin-right: 4.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: ${({ theme }) => theme.panel};
    box-shadow: var(--shadow-default);
    min-height: 764px;
    align-items: center;
    display: flex;
  }
`;
