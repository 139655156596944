/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import styled from "styled-components";
import { getInsights } from "../firebase/firebase.utils";
import { breakpoints } from "../styles/globalStyles";
import { Helmet } from "react-helmet";
import { TagCloud } from 'react-tagcloud'
import randomColor from "randomcolor";

const customRenderer = (tag, size, color) => {
  return (
    <span key={tag.value} style={{ color, fontSize: size, verticalAlign: 'middle', textTransform: 'capitalize'}} className={`m-2 d-inline-block `}>
      {tag.value}
    </span>
  )
}

const TagCloudPublic = ({ data }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (data != undefined) {
        let tmp = [];
        console.log(data);
        data.forEach((element) => {
          let tag = (element.author).trim();
          if (tag != "" && tag != undefined) {
            let tagIndex = tmp.findIndex((item) => (item.value).trim().toLowerCase() == tag.trim().toLowerCase());
            
            if (tagIndex >= 0) {
              let tagItem = tmp[tagIndex];
              tagItem["count"] = tagItem["count"] + 1;
              tmp[tagIndex] = tagItem;
            } else {
              let tagItem = {
                value: tag,
                count: 1,
              };
              tmp.push(tagItem);
            }
          }
        });
        tmp = tmp.sort(function(a, b) {
          var keyA = a.count,
            keyB = b.count;
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        let filteredTmp = tmp.filter(item => item.count >= 3);
        setTags(filteredTmp);
    }
  }, [data]);

  return (
        <ExploreContainer>
          <h1>Tag Cloud</h1>
          <div className="mt-3">
            { tags.map(item => (
              <span key={item.value} style={{ lineHeight: '2rem', display: 'inline-block', marginRight: '10px'}}>
                <span style={{ textTransform: 'capitalize' }} className="mx-2">{item.value}</span>
                <span style={{ padding: '3px 15px 3px 15px', borderRadius: '15px', background: '#6db166' }}>{item.count}</span>
              </span>
            ))}
          </div>
          <div className="mt-5">
            {tags.length > 0 ? (
              <TagCloud
                minSize={30}
                maxSize={100}
                tags={tags}
                renderer={customRenderer} 
              />
            ): (
              <div>
              <p className="text-center mb-0">This page will display all the tags you've gathered from your TubeDiary, offering a visual representation of the primary topics you explore. </p>
              <p className="text-center mb-0">By organizing your tags in one place, you can easily discern the themes that dominate your consumption patterns, 
                aiding in reflection, analysis,</p>
                <p className="text-center mb-0">and potential adjustments to your content preferences and interests.</p>
              </div>
            )}
          </div>
        </ExploreContainer>
  );
};

export default TagCloudPublic;

const FilterRow = styled.div`
  display: flex;
  padding: 2rem;
  @media screen and (max-width: ${breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 70px;

  > h1 {
    font-weight: 700;
    font-size: var(--font-size-1);
    color: var(--color-text-default);
  }
  > p {
    font-size: var(--font-size-4);
    max-width: 1030px;
    text-align: center;
    margin-top: 0;
    color: var(--color-text-default);
  }

  @media screen and (max-width: ${breakpoints.s}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${breakpoints.s}) {
  }
`;
