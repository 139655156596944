import React, { useState, useEffect, createContext, useContext } from "react";

import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/globalStyles";
import { lightTheme, darkTheme } from "./styles/Themes";
import { useDarkMode } from "./components/useDarkMode";
import Toggle from "./components/Toggler";

import Routes from "./routes/Routes";

import { auth, firestore } from "./firebase/firebase.utils";
import { PageInfoProvider } from "./context/PageInfoContext";

const isSubscribed = (subData) => {
  console.log(subData);
  if (!subData) {
    return false;
  }
  let curSubscription = subData.currentSubscription;
  let stripeCustomerId = subData.stripeCustomerId;
  if (stripeCustomerId == 'isFree') return true;
  if (
    curSubscription &&
    (curSubscription.status === "active" ||
      curSubscription.status === "trialing")
  ) {
    return true;
  } else {
    return false;
  }
};

const AuthContext = createContext();
const AuthProvider = AuthContext.Provider;

const App = () => {
  const [currentUser, setCurrentUser] = useState({
    empty: true,
    loggedIn: false,
    subscribed: false,
  });
  const [loaded, setLoaded] = useState(false);
  const [theme, themeToggler, mountedComponent] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  const [isAlertShow,setIsAlertShow] = useState(false)

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      try {
        console.log("on authstatechanged successfully called");
        if (user) {
          // todo: check if the user is currently paid up. set subscribed property to true if so. check subscribed status on db as well to ensure signup.
          const userDoc = await firestore.doc(`users/${user.uid}`).get();
          if (userDoc.exists) {
            console.log(userDoc.data());
            const avatar = userDoc.data().avatar ? userDoc.data().avatar : null;
            const username = userDoc.data().name ? userDoc.data().name : null;
            const useremail = userDoc.data().email ? userDoc.data().email : null;
            const subscriptionDataDoc = await firestore
              .doc(`users/${user.uid}/subscription/data`)
              .get();

            const subscriptionData = subscriptionDataDoc.exists
              ? subscriptionDataDoc.data().subscriptionData
              : null;
            setCurrentUser({
              ...auth.currentUser,
              loggedIn: true,
              subscribed: isSubscribed(subscriptionData),
              subscriptionData: subscriptionData ? subscriptionData : null,
              avatar: avatar,
              name: username,
              email:useremail,
            });
          } else {
            setCurrentUser({
              ...auth.currentUser,
              loggedIn: true,
              subscribed: false,
              avatar: null,
              name: null
            });
          }
        } else {
          // subscribed will not be set here, only for testing
          setCurrentUser({ loggedIn: false, subscribed: false });
        }
      } finally {
        setLoaded(() => true);
      }
    });
  }, []);


  useEffect(() => {
    const initialValue = document.body.style.zoom;
    // Change zoom level on mount
    document.body.style.zoom = "75%";
    document.body.style.height = "130vh";
    return () => {
      // Restore default value
      document.body.style.zoom = initialValue;
    };
  }, []);

  if (!mountedComponent) return <div />;

  return (
    <PageInfoProvider value={{isAlertShow:isAlertShow, setIsAlertShow:setIsAlertShow}}>
      <ThemeProvider theme={themeMode}>
        <AuthProvider value={{ currentUser, setCurrentUser }}>
          <GlobalStyle />
          {loaded && (
            <Routes
              currentUser={currentUser}
              themeToggler={themeToggler}
              theme={theme}
            />
          )}
        </AuthProvider>
      </ThemeProvider>
    </PageInfoProvider>
  );
};

export default App;

export const useAuth = () => {
  const store = useContext(AuthContext);

  return store;
};
