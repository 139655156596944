import React, { useEffect, useState , Fragment } from "react";
import { useParams } from 'react-router-dom';
import DefaultLayout from "../layouts/DefaultLayout";
import styled from "styled-components";
import Select from "../components/Select";
import { useMediaQuery } from "react-responsive";
import { getPublicInsights, getInsights, getUserIdWithHash } from "../firebase/firebase.utils";
import { breakpoints } from "../styles/globalStyles";
import Button from "../components/Button";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload , faAngleUp, faAngleDown, faFile, faStar, faHeart, faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons'
import SearchBox from "../components/SearchBox";
import {  formatStripeDate, formatDateShort } from "../functions/misc";
import { Rating } from 'react-simple-star-rating'
import Loading from "../components/Loading";
import Switch from "react-switch";
import external_link from "../assets/img/icons/external-link.png";
import external_link_white from "../assets/img/icons/external-link-white.png";
import * as moment from "moment";
import dayjs from "dayjs";
import TagCloudPublic from "../components/TagCloudPublic";
const filterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Video",
    value: "Video",
  },
  {
    label: "Podcast",
    value: "Podcast",
  },
  {
    label: "Article",
    value: "Article",
  },
  {
    label: "Misc",
    value: "Misc",
  },
];

const PublicInsight = ({ themeToggler, theme }) => {
  const { hash } = useParams();
  const userName = hash.charAt(0).toUpperCase() + hash.slice(1);
  // const {userName, setUserName} = useState();
  const [books, setBooks] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  
  const [categoryFilter, setCategoryFilter] = useState("All");

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [favFilter, setFavFilter] = useState(true);

  const onChangeFav = () => {
    let _favFilter = !favFilter;
    setFavFilter(_favFilter);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      getUserIdWithHash(hash).then(userId => {
        setUserId(userId);
        if(userId) {
          getPublicInsights(userId).then((data) => {
            data = data.sort(function(a, b) {
              var keyA = a.createdAt.seconds,
                keyB = b.createdAt.seconds;
              // Compare the 2 dates
              if (keyA > keyB) return -1;
              if (keyA < keyB) return 1;
              return 0;
            });
            setBooks(data);
            setLoading(false);
          });
        }else{
          setLoading(false);
        }
      })
    }, 1);
  }, [hash]);

  const calFilterBook = (() => {
    let temp = [];
    if(books.length > 0){
      temp = books.filter((b) =>{
        const title = b.title.toLowerCase();
        const query = searchQuery.toLowerCase();
        return title.includes(query);
        return categoryFilter === "All" ? (title.includes(query)) : ( b.category === categoryFilter && title.includes(query) )
        }
      );
      temp = temp.filter((b) => {
        return favFilter == false ? true : b.fav == true;
      })
    }
    var temp2 = [];
    if(temp.length > 0) {
      var month = moment(formatStripeDate(temp[0].createdAt.seconds)).format('MMMM YYYY');
      const cMonth = moment().format('MMMM YYYY');

      var createdAt = moment(formatStripeDate(temp[0].createdAt.seconds)).format('MM-DD-YYYY');
      var weeknumber = moment(createdAt, "MM-DD-YYYY").isoWeek();

      // if(cMonth !== month) {
        temp2.push({
          month: month
        })
      // }

      // Open to show week 2024-02-20
      // temp2.push({
      //   weeknum: weeknumber
      // })
      // Open to show week 2024-02-20

      var _hour = 0; 
      var _min = 0;
      var _tmp_month = '';

      var szSnipMonth = 0;
      var szSnipApplied = 0;

      for(var i=0; i < temp.length; i++) {
        var temp_month = moment(formatStripeDate(temp[i].createdAt.seconds)).format('MMMM YYYY');

        var tmp_createdAt = moment(formatStripeDate(temp[i].createdAt.seconds)).format('MM-DD-YYYY');

        var tmp_weeknumber = moment(tmp_createdAt, "MM-DD-YYYY").isoWeek();

        if(temp[i].time != undefined && temp[i].time != '' && temp_month == month) {
          var __hour = moment(temp[i].time).format('H');
          var __min = moment(temp[i].time).format('m');
          _hour += parseInt(__hour);
          _min += parseInt(__min);
        }
        _tmp_month = temp_month;

        // calculate szSnipApplied
        szSnipMonth++;
        let express = temp[i].express;
        if(express == true && temp_month == month) {
          szSnipApplied++;
        }
        if(temp_month !== month) {
          let _h = _hour;
          let _m = _min;
          _h += parseInt(_m / 60);
          _m = _m % 60;
          var _time = `${_h}h ${_m}m`;
          if(_h == 0 && _m == 0) {
            _time = '';
          }
          _hour = 0;
          _min = 0;

          if(temp[i].time != undefined && temp[i].time != '') {
            var __hour = moment(temp[i].time).format('H');
            var __min = moment(temp[i].time).format('m');
            _hour += parseInt(__hour);
            _min += parseInt(__min);
          }
          var idx = temp2.findIndex(item => item.month == month);
          var item = temp2[idx];

          var newItem = {
            ...item,
            time: _time,
            szSnipMonth: szSnipMonth,
            szSnipApplied: szSnipApplied
          };

          szSnipMonth = 0;
          szSnipApplied = 0;
          if(express == true) {
            szSnipApplied ++;
          }
          temp2[idx] = newItem;

          month = temp_month;
          temp2.push({month: month});

          // Open to show week 2024-02-20
          // weeknumber = tmp_weeknumber;
          // temp2.push({weeknum:weeknumber});
          // Open to show week 2024-02-20

        }
        // Open to show week 2024-02-20
        // if(tmp_weeknumber !== weeknumber) {
        //   weeknumber = tmp_weeknumber;
        //   temp2.push({weeknum:weeknumber});
        // }
        // Open to show week 2024-02-20
        temp2.push(temp[i]);
      }
      if(_tmp_month != '') {
        var idx = temp2.findIndex(item => item.month == _tmp_month);
        var item = temp2[idx];

        let _h = _hour;
        let _m = _min;
        _h += parseInt(_m / 60);
        _m = _m % 60;
        let _time = `${_h}h ${_m}m`;
        if(_h == 0 && _m == 0) {
          _time = '';
        }
        var newItem = {
          ...item,
          time: _time,
          szSnipMonth: szSnipMonth,
          szSnipApplied: szSnipApplied
        };
        temp2[idx] = newItem;
      }
    }
    calculateWeek(temp2);
    return temp2;
  });

  const calculateWeek = ((temp) => {
    var temp2 = temp;
    if(temp.length > 0) {      
      var _hourw = 0; 
      var _minw = 0;
      var szSnipMonthw = 0;
      var szSnipAppliedw = 0;
      var weekIndex = -1;
      for(var i=0; i < temp.length; i++) {
        let item = temp[i];
        let month = item.month;
        let week = item.weeknum;
        if(month != undefined ) {
          console.log('h43');
          if(weekIndex > 0) {
            let _hw = _hourw;
            let _mw = _minw;
            _hw += parseInt(_mw / 60);
            _mw = _mw % 60;
            var _timew = `${_hw}h ${_mw}m`;
            if(_hw == 0 && _mw == 0) {
              _timew = '';
            }
            _hourw = 0;
            _minw = 0;
            var itemw = temp2[weekIndex];
        
            var newItemw = {
              ...itemw,
              time: _timew,
              szSnipMonth: szSnipMonthw,
              szSnipApplied: szSnipAppliedw
            };
            temp2[weekIndex] = newItemw;
            weekIndex = -1;
          }
          _hourw = 0; 
          _minw = 0;
          szSnipMonthw = 0;
          szSnipAppliedw = 0;
          continue;
        }
        if( week != undefined) {
          console.log(week);
          if(weekIndex > 0) {
            let _hw = _hourw;
            let _mw = _minw;
            _hw += parseInt(_mw / 60);
            _mw = _mw % 60;
            var _timew = `${_hw}h ${_mw}m`;
            if(_hw == 0 && _mw == 0) {
              _timew = '';
            }
            _hourw = 0;
            _minw = 0;
            var itemw = temp2[weekIndex];
        
            var newItemw = {
              ...itemw,
              time: _timew,
              szSnipMonth: szSnipMonthw,
              szSnipApplied: szSnipAppliedw
            };
            temp2[weekIndex] = newItemw;
          }
          weekIndex = i;

          _hourw = 0; 
          _minw = 0;
          szSnipMonthw = 0;
          szSnipAppliedw = 0;
        }else{
          if(item.time != undefined && item.time != '') {
            var __hourw = moment(item.time).format('H');
            var __minw = moment(item.time).format('m');
            _hourw += parseInt(__hourw);
            _minw += parseInt(__minw);
          }
          szSnipMonthw++;
          let expressw = temp[i].express;
          if(expressw == true) {
            szSnipAppliedw++;
          }
        }
      }

      let _hw = _hourw;
      let _mw = _minw;
      _hw += parseInt(_mw / 60);
      _mw = _mw % 60;
      var _timew = `${_hw}h ${_mw}m`;
      if(_hw == 0 && _mw == 0) {
        _timew = '';
      }
      _hourw = 0;
      _minw = 0;
      var itemw = temp2[weekIndex];
  
      var newItemw = {
        ...itemw,
        time: _timew,
        szSnipMonth: szSnipMonthw,
        szSnipApplied: szSnipAppliedw
      };
      temp2[weekIndex] = newItemw;
    }
    return temp2;

  });

  const refreshList = async () => {
    getPublicInsights(userId).then((data) => {
      data = data.sort(function(a, b) {
        var keyA = a.createdAt.seconds,
          keyB = b.createdAt.seconds;
          console.log(keyA, keyB);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      setBooks(data);
    });
  };

  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      { (!loading && userId) ? (
        <PageBody>
          <ExploreContainer>
            <h1>{userName}'s TubeDiary</h1>
            <p style={{ textAlign: "center" }}>
              Explore your friend's favourite YouTube videos
            </p>
            {isMobile ? (
              <div style={{ width: "100%" }}>
                  <SearchBox 
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                  {/* <Select
                  className="mt-3"
                  options={filterOptions}
                  onChange={(val) => setCategoryFilter(val.value)}
                  value={filterOptions.find((fo) => fo.value === categoryFilter)}
                  isClearable={false}
                  theme={theme}
                /> */}
              </div>
            ) : (
              <div>
                <div style={{  margin: 'auto', display: 'flex' }}>
                  <SearchBox 
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    placeHolder={'Search'}
                  />
                  
                  {/* {favFilter ? (
                    <PButtonActive
                      className="btn"
                      onClick={() => onChangeFav()}
                    >
                      <FontAwesomeIcon icon={faHeart} />
                    </PButtonActive>
                  ) : (
                    <PButton
                      className="btn"
                      onClick={() => onChangeFav()}
                    >
                      <FontAwesomeIcon icon={faHeart} />
                    </PButton>
                  )} */}
                </div>
              </div>
            )}
          </ExploreContainer>
          <div className="w-100" style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
            <TableBooks className="table">
              { !isMobile && (<thead>
                <tr>
                  <th>Title</th>
                  <th>Author</th>
                  <th>Date Added</th>
                  <th>Time Spent</th>
                  {/* <th>Tag</th> */}
                  <th>Favorite</th>
                  {/* <th>Link</th> */}
                  {/* <th>Attachment</th> */}
                  {/* <th>Applied</th> */}
                  {/* <th>Applied</th> */}
                  <th></th>
                </tr>
              </thead>)}
              <tbody>
                {calFilterBook().length > 0 ? (
                  <>
                    {calFilterBook()
                      .map((book) => (
                        <BookRow key={Math.random()} book={book} refreshList={refreshList} insightList={books} theme={theme}/>
                      ))}
                  </>
                ): (
                  <tr>
                    <td colSpan={'11'}>
                      <div className="text-center p-5">Click the + button to add your insights</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </TableBooks>
          </div>

          {/* <TagCloudPublic data={books} /> */}
        </PageBody>
      ):
      (
        <PageBody>
          {loading ? 
          (
            <CenterDiv>
              <Loading/>
            </CenterDiv>
          ): (
            <CenterDiv>
              <span>No Data to show</span>
            </CenterDiv>
              )}
            {/* <TagCloudPublic data={books} /> */}
        </PageBody>
      )
      }
    </DefaultLayout>
  );
};

export default PublicInsight;
const CenterDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 30px;
`;
const FilterRow = styled.div`
  display: flex;
  padding: 2rem;
  @media screen and (max-width: ${breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 70px;

  > h1 {
    font-weight: 700;
    font-size: var(--font-size-1);
    color: var(--color-text-default);
  }
  > p {
    font-size: var(--font-size-4);
    max-width: 1030px;
    text-align: center;
    margin-top: 0;
    color: var(--color-text-default);
  }

  @media screen and (max-width: ${breakpoints.s}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${breakpoints.s}) {
  }
`;

const TableBooks = styled.table`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const BookRow = ({ book, theme }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });
  var createdAt = '';
  createdAt = book.createdAt ? formatStripeDate(book.createdAt.seconds, true) : '';
  var month = book.month;
  var time = book.time;

  let row = '';
  if(month !== undefined) {
    let percent = Math.floor((book.szSnipApplied/book.szSnipMonth) * 10000) / 100;

    row = <tr>
      <td colSpan={'4'}>
      <h3>{ month }</h3>
      </td>
      <td colSpan={'6'}>
        {/* <p style={{ display: 'flex', justifyContent: 'end', marginBottom: '0px'}}> <b>Applied: &nbsp; </b> { book.szSnipApplied }/{book.szSnipMonth} - {percent} %</p>
        {
          time != '' && (
            <p style={{ display: 'flex', justifyContent: 'end'}}> <b>Total Time: &nbsp; </b> { time }</p>
          )
        } */}
      </td>
    </tr>
  } 
  // Open to show week 2024-02-20
  // else if (book.weeknum !== undefined){
  //   let percentw = Math.floor((book.szSnipApplied/book.szSnipMonth) * 10000) / 100;

  //   row = <tr>
  //     <td colSpan={'4'}>
  //       <h4>Week { book.weeknum }</h4>
  //     </td>
  //     <td colSpan={'7'}>
  //       <p style={{ display: 'flex', justifyContent: 'end', marginBottom: '0px'}}><b>Applied: &nbsp; </b> {book.szSnipApplied}/{book.szSnipMonth} - {percentw} %</p>
  //       {
  //         time != '' && (
  //           <p style={{ display: 'flex', justifyContent: 'end'}}> <b>Total Time: &nbsp; </b> { time }</p>
  //         )
  //       }
  //     </td>
  //   </tr>
  // }
  // Open to show week 2024-02-20
  else{
    row = <Fragment>
    <tr style={{verticalAlign: 'middle'}}>
      <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer", maxWidth: '300px',minWidth: '300px',overflowWrap: 'anywhere' }}>
        {book.title}
      </td>
      <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}>
        {book.author}
      </td>

      <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer", width: '200px' }}>
        {createdAt}
      </td>

      <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer", width: '200px'}}>
        { book.time ? dayjs(book.time).format('H') + "h " + dayjs(book.time).format('mm') + 'm': ''}
      </td>
      {/* <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}>
        {book.tag}
      </td> */}
      <td >
        <div className="d-flex">
        { book.fav === true ? 
          (
            <FontAwesomeIcon style={{ cursor: 'pointer', color: '#f10000' }}  icon={faHeart} size={"lg"} />
          ): 
          (
            <FontAwesomeIcon style={{ cursor: 'pointer', color: '#cccccc' }}  icon={faHeart} size={"lg"} />
          )}
          <div onClick={() => setOpen((o) => !o)} style={{ width: '100%', cursor: "pointer"}}></div>
        </div>
      </td>
      {/* <td onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}>
        <a href={book.link} target="_blank" rel="noreferrer">{ book.link ? (<img style={{ width: '31px' }} src={ theme === 'light' ? external_link: external_link_white}  alt="View" />): ''}</a>
      </td> */}
      {/* <td onClick={() => setOpen((o) => !o)}  style={{ cursor: "pointer"}}>
          {
            book.fileUrl && (
              <a href={book.fileUrl} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFile}/></a>
            )
          }
      </td> */}
      {/* <td onClick={() => setOpen((o) => !o)}>
        <div className="d-flex">
          <Switch height={24} width={48} handleDiameter={22}  onColor={'#2B7BD9'} uncheckedIcon={false} checkedIcon = {false} disabled={true} checked={book.apply ? book.apply : false} />
          <div style={{ width: '100%', cursor: "pointer"}}></div>
        </div>
      </td> */}
      {/* <td onClick={() => setOpen((o) => !o)}>
        <div className="d-flex">
          { book.express ? 
          (
            <FontAwesomeIcon style={{ cursor: 'pointer', color: '#cccccc' }}  icon={faCheckSquare} size={"lg"} />
          ): 
          (
            <FontAwesomeIcon style={{ cursor: 'pointer', color: '#cccccc' }}  icon={faSquare} size={"lg"} />
          )}
          <div onClick={() => setOpen((o) => !o)} style={{ width: '100%', cursor: "pointer"}}></div>
        </div>
      </td> */}
      
      {/* <td onClick={() => setOpen((o) => !o)}>
        <ToggleOpen  style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} size={"lg"} />
        </ToggleOpen>
      </td> */}
    </tr>
    { open && (
      <tr className="test-td">
        <td colSpan={'9'} style={{ overflowWrap: 'anywhere' }}>
          <ExpandRow className="p-3">
              { (!book.note1 && !book.note2 && !book.note3 && !book.note) && (
                <div className="mb-1"><strong >N/A</strong></div>
              )}
              { (book.note1 || book.note2 || book.note3 ) && (
                <div className="mb-1"><strong >Key Practical Takeaways</strong></div>
              )}
              { book.note1 && (
                <div><span style={{ marginRight: '0.5rem'}}>1.</span>{book.note1}</div>
              )}
              { book.note2 && (
                <div><span style={{ marginRight: '0.5rem'}}>2.</span>{book.note2}</div>
              )}
              { book.note3 && (
                <div><span style={{ marginRight: '0.5rem'}}>3.</span>{book.note3}</div>
              )}
              {book.note && (
                <div style={{ whiteSpace : 'pre-line'}}>
                  <div className="mt-2 mb-1"><strong>Reflection</strong></div>
                  {book.note}
                </div>
              )}
          </ExpandRow>
        </td>
      </tr>
    )}
    </Fragment>;

    if(isMobile) {
      row = <Fragment>
      <tr>
        <td style={{ overflowWrap: 'anywhere'}}>
          <div className="p-1" style={{ border: "1px solid #000", borderRadius: '4px' }}>
            <div onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}><strong>Title: </strong>{book.title}</div>
            <div onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}><strong>Author: </strong>{book.author}</div>

            <div onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}><strong>Date Added: </strong>{createdAt}</div>
            <div onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}><strong>Time Spent: </strong>{ book.time ? dayjs(book.time).format('H') + "h " + dayjs(book.time).format('mm') + 'm': ''}</div>
            {/* <div onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}><strong>Tag: </strong>{book.tag}</div> */}
            <div onClick={() => setOpen((o) => !o)}><strong>Favorite: </strong>
              { book.fav === true ? 
              (
                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#f10000' }}  icon={faHeart} size={"lg"} />
              ): 
              (
                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#cccccc' }}  icon={faHeart} size={"lg"} />
              )}
            </div>
            <div><strong>Link: </strong>
              <a href={book.link} target="_blank" rel="noreferrer">{ book.link ? (<img style={{ width: '23px' }} src={theme === 'light' ? external_link : external_link_white}  alt="View" />): ''}</a>
            </div>
            {/* <div onClick={() => setOpen((o) => !o)} style={{ cursor: "pointer" }}><strong>Attachment:</strong> 
            {
              book.fileUrl && (
                <a className="m-3" href={book.fileUrl} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFile}/></a>
              )
            }
            </div> */}
            {/* <div onClick={() => setOpen((o) => !o)} className=""><strong style={{ marginRight: '10px' }}>Applied: </strong>
              <div style={{ float : 'right'}}>
                <Switch height={24} width={48} handleDiameter={22}  onColor={'#2B7BD9'} disabled={true} uncheckedIcon={false} checkedIcon = {false} checked={book.apply ? book.apply : false} />
              </div>
            </div> */}
            <div onClick={() => setOpen((o) => !o)} style={{ marginTop: '10px' }} className=""><strong style={{ marginRight: '10px' }}>Applied: </strong>
              <input
                  type="checkbox"
                  checked={ book.express ? book.express : false }
                  readOnly
              />
            </div>
            <div onClick={() => setOpen((o) => !o)} className="text-center">
              <ToggleOpen className="btn w-100"  style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} size={"lg"} />
              </ToggleOpen>
            </div>
          </div>
        </td>
      </tr>
      { open && (
        <tr className="test-td">
          <td style={{ overflowWrap: 'anywhere' }}>
            <ExpandRow className="p-3">
                { (!book.note1 && !book.note2 && !book.note3 && !book.note) && (
                  <div className="mb-1"><strong >N/A</strong></div>
                )}
                { (book.note1 || book.note2 || book.note3 ) && (
                  <div className="mb-1"><strong >Key Practical Takeaways</strong></div>
                )}
                { book.note1 && (
                  <div><span style={{ marginRight: '0.5rem'}}>1.</span>{book.note1}</div>
                )}
                { book.note2 && (
                  <div><span style={{ marginRight: '0.5rem'}}>2.</span>{book.note2}</div>
                )}
                { book.note3 && (
                  <div><span style={{ marginRight: '0.5rem'}}>3.</span>{book.note3}</div>
                )}
                { book.note && (
                  <div style={{ whiteSpace : 'pre-line'}}>
                    <div className="mt-2 mb-1"><strong>Reflection</strong></div>
                    {book.note}
                  </div>
                )}
            </ExpandRow>
          </td>
        </tr>
      )}
    </Fragment>;
    }
}
  return row;
};
const ExpandRow = styled.div`
  background: #85858530;
`;
const ToggleOpen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 100%;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    background-color: #eee;
  }
`;

const PButton = styled.button`
  margin-left: 1rem;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  &:hover {
    color: #ffffff;
    border-color: var(--color-primary);
  }
`;
const PButtonActive = styled.button`
  margin-left: 1rem;
  border: 2px solid var(--color-primary);
  background: var(--color-primary);
  color: #ffffff;
  &:hover {
    color: #ffffff;
    border-color: var(--color-primary);
  }
`;