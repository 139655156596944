export const pricing = {
  monthly: {
    planId: "price_1POj1XCrEMXqBp5nynPTcOWO",
    pricePerMonth: 3.99,
    origin: 3.99,
  },
  annual: {
    planId: "price_1POj1XCrEMXqBp5nOYo8nNMV",
    pricePerMonth: 39.99,
    origin: 39.99,
  }
};
