import React, { useEffect, useState } from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import styled from "styled-components";
import { getPageContent } from "../firebase/firebase.utils";
import { breakpoints } from "../styles/globalStyles";
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";
import Loading from "../components/Loading";
const HomeContentPage = ({ themeToggler, theme }) => {
  const [loading, setLoading] = useState(0);

  const [helpContent, setSubText] = useState(null);

  useEffect(() => {
    async function getContent() {
      setLoading(1)
      const pageData = await getPageContent('homecontent');
      setLoading(0);
      if (pageData) {
        var content = JSON.parse(pageData.content);
        setSubText(content['subtext'] ? content['subtext'] : null);
      }
    }
    getContent();
  }, []);

  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      <Helmet>
        <title>Home - TubeDiary</title>
        <meta name="description" content="Access TubeDiary book summaries designed to help you improve your habits in a sustainable way. Track your notes and learning journey along the way." />
      </Helmet>
      <PageBody>
        <ExploreContainer>
          <h1>Unlock the Power of YouTube</h1>
        </ExploreContainer>

        {loading == 1 ? (
          <LoadingCover>
            <Loading />
          </LoadingCover>
        ) :
          (
            <ContentDiv>
              {ReactHtmlParser(helpContent)}
            </ContentDiv>
          )}

      </PageBody>
    </DefaultLayout>
  );
};

export default HomeContentPage;

const ContentDiv = styled.div`
  display: block;
  padding: 30px;
  p {
    margin-bottom: 0rem;
  }
  img {
    max-width: 100%;
  }
`;

const LoadingCover = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--background-panel);
  z-index: 100;
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
`;

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 70px;

  > h1 {
    font-weight: 700;
    font-size: var(--font-size-1);
    color: var(--color-text-default);
  }
  > p {
    font-size: var(--font-size-4);
    max-width: 1030px;
    text-align: center;
    margin-top: 0;
    color: var(--color-text-default);
  }

  @media screen and (max-width: ${breakpoints.s}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${breakpoints.s}) {
  }
`;