import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Button from "../components/Button";
import Input from "../components/Input";
import SignupCard from "../components/SignupCard";

import DefaultLayout from "../layouts/DefaultLayout";

import { useMediaQuery } from "react-responsive";

import { breakpoints } from "../styles/globalStyles";

import paula from "../assets/img/testimonials/paula.png";
import loubna from "../assets/img/testimonials/loubna.png";
import marsha from "../assets/img/testimonials/marsha.png";

import growth from "../assets/img/icons/icon-growth3x.png";
import highlight from "../assets/img/icons/icon-highlight3x.png";
import insight from "../assets/img/icons/icon-insights3x.png";
import memory from "../assets/img/icons/icon-memory3x.png";

import audios from "../assets/img/icons/audios.png";
import accountability from "../assets/img/icons/accountability.png";

import suiteImage from "../assets/img/promotional/graphic-growthnotes-3x.png";
import readingImage from "../assets/img/promotional/reading-character.png";
import samyImage from "../assets/img/promotional/Samy-Felice.jpg";
import growthCycleAnimation from "../assets/img/promotional/growth-cycle-animation.gif";

import backgroundBirds from "../assets/img/promotional/growthnote-bg-img3x.png";

import { useHistory } from "react-router-dom";
import { useModal } from "../context/Modal";

import backend from "../axiosBackend";
import { Helmet } from "react-helmet";
const features = [
  // {
  //   image: growth,
  //   text: "Growing Library",
  // },
  // {
  //   image: highlight,
  //   text: "Top Highlights",
  // },
  {
    image: insight,
    text: "TubeDiary",
  },
  {
    image: memory,
    text: "Spatial Repetition",
  },
  {
    image: audios,
    text: "Walkthroughs",
  },
  {
    image: accountability,
    text: "Accountability",
  },
];


const HomePage = ({ currentUser, themeToggler, theme }) => {
  const { openModal, closeModal } = useModal();
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const mode = params.get("mode");
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  // scale height = 0.5625
  const [screenDimensionsForVimeoEmbed, setScreenDimensionsForVimeoEmbed] =
    useState(null);

  const handleSignUpClickDesktop = () => {
    history.push("/sign-up");
  };

  const handleSignUpClickMobile = () => {
    openModal(<SignupCard isModal="true" theme={theme} />);
  };

  useEffect(() => {
    if (isMobile && !!mode) {
      openModal(<SignupCard isModal="true" mode={mode} theme={theme} />);
    }
  }, [isMobile, mode]);

  useEffect(() => {
    setScreenDimensionsForVimeoEmbed((v) => ({
      width: 0.7 * window.innerWidth,
      height: 0.7 * (window.innerWidth * 0.5625),
    }));

    const timer = setInterval(() => {
      setCurrentTestimonial((t) => (t < 2 ? t + 1 : 0));
    }, 6000);
    return () => clearInterval(timer);
  }, []);

  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      <Helmet>
        <title>Sign up - TubeDiary</title>
        <meta name="description" content="Build a second brain that leverages your consumption of online information so that you take more action. Also, access key, practical book summaries." />
      </Helmet>
      <SignupSection isMobile={isMobile}>
        {isMobile ? (
          <SignupBody>
            <div>
              <h3>What You'll get</h3>
              <FeatureGrid>
                {features.map((f, index) => (
                  <Feature key={index}>
                    <img src={f.image} alt="todo" />
                    <span>{f.text}</span>
                  </Feature>
                ))}
              </FeatureGrid>
              <span>Sign up now for all the goodness</span>
            </div>
            <div
              style={{
                width: "100%",
                padding: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                primary
                label={"Sign Up"}
                style={{ width: "90%", height: "90%" }}
                onClick={handleSignUpClickMobile}
              />
            </div>
          </SignupBody>
        ) : (
          <>
            <SignupSectionBackground>
              <div>
                <div></div>
                <div></div>
              </div>
            </SignupSectionBackground>
            <SignupBody>
              <div className="col-sm-6">
                <h3>What You'll get</h3>
                <FeatureGrid>
                  {features.map((f, index) => (
                    <Feature key={index}>
                      <img src={f.image} alt="todo" />
                      <span>{f.text}</span>
                    </Feature>
                  ))}
                </FeatureGrid>
                <span>Sign up for all the goodness and more...</span>
              </div>
              <div className="col-sm-6">
                <SignupCard mode={mode} theme={theme} />
              </div>
            </SignupBody>
          </>
        )}
      </SignupSection>
    </DefaultLayout>
  );
};

export default HomePage;

const SignupBody = styled.div`
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${breakpoints.s}) {
    padding: 2rem;
    background-color: var(--background);
    flex-direction: column;
    > div:first-child {
      display: flex;
      flex-direction: column;
      > h3 {
        font-size: var(--font-size-1);
        font-weight: 700;
        text-align: center;
        color: var(--color-text-default);
      }
      > span {
        margin: 1rem 0;
        font-size: var(--font-size-4);
        line-height: 1;
        text-align: center;
        color: var(--color-text-default);
      }
    }
    > div:nth-child(2) {
      width: 100%;
    }
  }

  @media screen and (min-width: ${breakpoints.s}) {
    > div:first-child {
      padding: 2rem 0rem;
      display: flex;
      flex-direction: column;

      > h3 {
        font-size: var(--font-size-1);
        font-weight: 700;
        color: var(--color-text-default);
      }
      > span {
        margin: 1rem 0;
        font-size: var(--font-size-4);
        line-height: 1;
        color: var(--color-text-default);
      }
    }
    > div:nth-child(2) {
      width: 50%;
      display: contents;
    }
  }
`;

const SignupSection = styled.div`
  width: 100%;
  ${({ isMobile }) => !isMobile && "position: relative;"}
`;

const SignupSectionBackground = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
position: absolute;
left: 0;
z-index: -100;
> div:first-child {
    height: 100%;
    width: 100%;
    display: flex;
    > div:first-child {
        background-color:${({ theme }) => {
          return theme.name === "light" ? theme.background : theme.background;
        }};
        width: 60%;
        height: 100%;
        position: relative;
        z-index: -10;
      }
      > div:nth-child(2) {
        background-color: ${({ theme }) => {
          return theme.name === "light" ? theme.primary : theme.background;
        }};
        width: 40%;
        height: 100%;
        position: relative;
      }
}

> div:nth-child(2) {
    height: 90px;
    width: 100%;
    display: flex;
    > div:first-child {
        background-color: #eef6ff;
        width: 60%;
        height: 100%;
        position: relative;
        clip-path: polygon(0 0, 100% 0, 100% 40%, 0 100%);
        z-index: -10;
    }

    > div:nth-child(2) {
        background-color: var(--color-primary);
        width: 40%;
        height: 100%;
        clip-path: polygon(0 0, 0 40%, 100% 0);
        position: relative;

    }
}
}
`;

const FeatureGrid = styled.div`
  @media screen and (max-width: ${breakpoints.s}) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: ${breakpoints.s}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
`;

const Feature = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  margin: 1rem;
  > img {
    width: 64px;
  }
  > span {
    font-size: var(--font-size-4);
    font-weight: 700;
    margin-top: 8px;
    line-height: 1.19;
    color: var(--color-primary);
  }
`;
