import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useMachine } from "@xstate/react";
import HighlightsModal from "./HighlightsModal";
import growthnoteMachine from "../machines/growthnoteMachine";

import icon1_u from "../assets/img/progress/spatial-repetition-stage-1-unread.png";
import icon1_c from "../assets/img/progress/spatial-repetition-stage-1.png";
import icon2_u from "../assets/img/progress/spatial-repetition-stage-2-unread.png";
import icon2_p from "../assets/img/progress/spatial-repetition-stage-2-paused.png";
import icon2_c from "../assets/img/progress/spatial-repetition-stage-2.png";
import icon3_u from "../assets/img/progress/spatial-repetition-stage-3-unread.png";
import icon3_p from "../assets/img/progress/spatial-repetition-stage-3-paused.png";
import icon3_c from "../assets/img/progress/spatial-repetition-stage-3.png";
import tick_icon from "../assets/img/icons/spatial-repetition-check.png";
import { updateUserBook, updateUserBookList, getPageContent } from "../firebase/firebase.utils";
import { formatDate } from "../functions/misc";
import Button from "./Button";

import { RotateClockwise2 } from "tabler-icons-react";

const GrowthCycle = ({ bookId, currentUser }) => {
  const [isHelpModal, setIsHelpModal] = useState(false);
  const [viewCongratulations, setViewCongratulations] = useState(true);
  const [state, send] = useMachine(
    growthnoteMachine.withContext({
      bookId,
      currentUser,
    })
  );
  const [cycleContent, setSubText] = useState(null);

  useEffect(() => {
    (async () => {
      const pageData = await getPageContent('cycle');
      if(pageData) {
        var content = JSON.parse(pageData.content);
        setSubText(content['subtext'] ? content['subtext'] : null);
      }
    })();
    
  }, [state.context]);

  const resetGrowthCycle = async () => {
    try {
      await updateUserBook(currentUser.uid, bookId, {
        readDate: null,
        reviewSchedule: null,
        reviewDate: null,
        finalReviewDate: null,
        finalReviewSchedule: null,
      });

      send("RESET");
    } catch (err) {}
  };

  const firstTick = async () => {
    try {
      const readDate = new Date();
      const reviewSchedule = new Date(
        new Date().setDate(new Date().getDate() + 5)
      );

      await updateUserBook(currentUser.uid, bookId, {
        readDate,
        reviewSchedule,
      });

      send("TICK", { readDate, reviewSchedule });
    } catch (err) {}
  };

  const secondTick = async () => {
    try {
      const reviewDate = new Date();
      const finalReviewSchedule = new Date(
        new Date().setDate(new Date().getDate() + 10)
      );

      await updateUserBook(currentUser.uid, bookId, {
        reviewDate,
        finalReviewSchedule,
      });

      send("TICK", { reviewDate, finalReviewSchedule });
    } catch (err) {}
  };

  const finalTick = async () => {
    try {
      const finalReviewDate = new Date();

      await updateUserBook(currentUser.uid, bookId, {
        finalReviewDate,
      });

      send("TICK", { finalReviewDate });
    } catch (err) {}
  };
  return (
    <>
      <CycleContainer>
        <Header>
          <span>Growth Cycle</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <RotateClockwise2
              title={"Reset"}
              style={{ cursor: "pointer", marginRight: "8px" }}
              onClick={async () => await resetGrowthCycle()}
            />
            <HelpButton onClick={() => setIsHelpModal(true)}>?</HelpButton>
          </div>
        </Header>
        {/* Cycle not started yet */}
        {state.matches("readyForFirstTick") && (
          <GrowthContainer>
            <span class="text-muted">1x</span>
            <GrowthText stage={1} message={<span>Ready to read</span>} />
            <input type="checkbox" onClick={async () => await firstTick()} />
            <span class="text-muted">2x</span>
            <GrowthText
              stage={2}
              message={<span>Complete Stage 1 first</span>}
            />
            <span></span>
            <span class="text-muted">3x</span>
            <GrowthText
              stage={3}
              message={<span>Complete Stage 2 first</span>}
            />
            <span></span>
          </GrowthContainer>
        )}
        {/* Cycle started */}
        {(state.matches("notReadyForSecondTick") ||
          state.matches("readyForSecondTick")) && (
          <GrowthContainer>            
            <span>1x</span>
            <GrowthText
              stage={1}
              message={
                <span>
                  Read on <strong>{formatDate(state.context.readDate)}</strong>
                </span>
              }
            />
            <Tick />            
            <span class="text-muted">2x</span>
            <GrowthText
              stage={2}
              message={
                state.matches("readyForSecondTick") ? (
                  <span>Ready to read again!</span>
                ) : (
                  <span>
                    Review on{" "}
                    <strong>{formatDate(state.context.reviewSchedule)}</strong>
                  </span>
                )
              }
            />
            {state.matches("readyForSecondTick") ? (
              <input type="checkbox" onClick={async () => await secondTick()} />
            ) : (
              <span></span>
            )}
            <span class="text-muted">3x</span>
            <GrowthText
              stage={3}
              message={<span>Complete Stage 2 First</span>}
            />
            <span></span>
          </GrowthContainer>
        )}
        {(state.matches("notReadyForThirdTick") ||
          state.matches("readyForThirdTick")) && (
          <GrowthContainer>
          <span>1x</span>
            <GrowthText
              stage={1}
              message={
                <span>
                  Read on <strong>{formatDate(state.context.readDate)}</strong>
                </span>
              }
            />
            <Tick />
            <span>2x</span>
            <GrowthText
              stage={2}
              message={
                <span>
                  Reviewed on{" "}
                  <strong>{formatDate(state.context.reviewDate)}</strong>
                </span>
              }
            />
            <Tick />
            <span class="text-muted">3x</span>
            <GrowthText
              stage={3}
              message={
                state.matches("readyForThirdTick") ? (
                  <span>Ready to read again!</span>
                ) : (
                  <span>
                    Review on{" "}
                    <strong>
                      {formatDate(state.context.finalReviewSchedule)}
                    </strong>
                  </span>
                )
              }
            />
            {state.matches("readyForThirdTick") ? (
              <input type="checkbox" onClick={async () => await finalTick()} />
            ) : (
              <span></span>
            )}
          </GrowthContainer>
        )}
        {state.matches("completed") && (
          <>
            {viewCongratulations ? (
              <div
                style={{
                  padding: "4rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {<GrowthIndicator stage={"3C"} />}
                <span
                  style={{
                    color: "var(--color-primary)",
                    fontWeight: "700",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                    textAlign: "center",
                  }}
                >
                  Congratulations!
                </span>
                <span
                  style={{
                    marginBottom: "0.5rem",

                    textAlign: "center",
                  }}
                >
                  You have completed all the stages
                </span>
                <Button
                  secondary
                  label={"View history"}
                  onClick={() => setViewCongratulations((v) => !v)}
                />
              </div>
            ) : (
              <GrowthContainer>
              <span>1x</span>
                <GrowthText
                  stage={1}
                  message={
                    <span>
                      Read on{" "}
                      <strong>{formatDate(state.context.readDate)}</strong>
                    </span>
                  }
                />
                <Tick />
              <span>2x</span>
                <GrowthText
                  stage={2}
                  message={
                    <span>
                      Read on{" "}
                      <strong>{formatDate(state.context.reviewDate)}</strong>
                    </span>
                  }
                />
                <Tick />
                <span>3x</span>
                <GrowthText
                  stage={1}
                  message={
                    <span>
                      Read on{" "}
                      <strong>
                        {formatDate(state.context.finalReviewDate)}
                      </strong>
                    </span>
                  }
                />
                <Tick />
              </GrowthContainer>
            )}
          </>
        )}
      </CycleContainer>
      {isHelpModal && (
        <HighlightsModal
          highlights={ cycleContent }
          title={"Growth Cycle"}
          isOpen={isHelpModal}
          setIsOpen={setIsHelpModal}
        />
      )}
    </>
  );
};

export default GrowthCycle;

const GrowthImage = styled.img`
  width: 100%;
  padding: 12px;
  ${(props) => props.bw && "filter: grayscale(100%);"}
`;

const GrowthText = ({ stage, message }) => {
  return (
    <GrowthTextContainer>
      <span>Stage {stage}</span>
      {message}
    </GrowthTextContainer>
  );
};

const GrowthTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  > span:first-child {
    font-size: 11px;
    color: gray;
  }

  > span:nth-child(2) {
    font-size: 13px;
  }
`;

const GrowthContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  place-items: center;
`;

const Tick = styled.div`
  width: 16px;
  height: 16px;
  background: url(${tick_icon}) no-repeat center;
  background-size: contain;
`;

const HelpButton = styled.div`
  width: 20px;
  height: 20px;
  color: white;
  font-weight: 700;
  border-radius: 100%;
  cursor: pointer;
  background-color: var(--color-primary);
  display: grid;
  place-items: center;
`;

const CycleContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #dedede;
`;

const Header = styled.div`
  border-bottom: 2px solid #dedede;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  > span:first-child {
    font-size: 18px;
    font-weight: 700;
    color: var(--color-text-default);
  }
`;

const Stages = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem 1rem;
  > div {
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > span:first-child {
        font-size: 10px;
        color: #979797;
      }
      > span:nth-child(2) {
        font-size: 14px;
        color: #979797;
      }
    }
  }
`;

export const GrowthIndicator = ({ stage, gray, cycle }) => {
  return (
    <IndicatorImage
      src={
        {
          "1U": icon1_u,
          "1C": icon1_c,
          "2U": icon2_u,
          "2P": icon2_p,
          "2C": icon2_c,
          "3U": icon3_u,
          "3P": icon3_p,
          "3C": icon3_c,
        }[stage]
      }
      alt="progress icon"
      gray={gray}
    />
  );
};

const IndicatorImage = styled.img`
  height: ${(p) => (p.cycle ? "50px" : "46px")};
  ${(p) => (p.gray ? "filter: grayscale(100%);" : null)}
`;
