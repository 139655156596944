import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Zoom, Fab } from "@mui/material";
import { ArrowUp } from "tabler-icons-react";

const ScrollToTop = ({trigger}) => {
  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" style={{ position: 'fixed', bottom: 30, right: 30}}>
        <Fab color="primary" size="large" aria-label="scroll back to top">
          <ArrowUp />
        </Fab>
      </div>
    </Zoom>
  );
};

// ScrollToTop.propTypes = {
//   children: PropTypes.element.isRequired
// };

export default ScrollToTop;
