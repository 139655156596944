import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Button from "../components/Button";
import Input from "../components/Input";

import { useHistory } from "react-router-dom";

import { breakpoints } from "../styles/globalStyles";

import DefaultLayout from "../layouts/DefaultLayout";

import { useNotifications } from "../context/Notifications";

import { formatStripeDate } from "../functions/misc";

import Loading from "../components/Loading";

import { pricing } from "../config";
import Avatar from 'react-avatar-edit'

import {
  signUp,
  auth,
  emailAuthProvider,
  firestore,
  getPageContent,
  updateUserAvatar
} from "../firebase/firebase.utils";
import { useAuth } from "../App";

import { CardElement, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import useStripeTools from "../stripe/useStripeTools";
import { prices } from "../stripe/config";
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";
import useUpload from "../tools/useUpload";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProfilePage = ({ currentUser, themeToggler, theme }) => {
  const { uploadBase64Image } = useUpload();
  const history = useHistory();

  const N = useNotifications();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [subscriptionActive, setSubscriptionActive] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [loadingSubData, setLoadingSubData] = useState(true);
  const [plan, setPlan] = useState("yearly");

  const [currentSub, setCurrentSub] = useState(null);
  const {
    cancelSubscription,
    validCard,
    setValidCard,
    updatePaymentDetails,
    createSubscription,
    retryInvoicePayment,
  } = useStripeTools();

  // get subscription data from the backend.
  const [loading, setLoading] = useState([]);

  const [guideTitle, setTitle] = useState(null);
  const [guideSubtext, setSubText] = useState(null);
  const [changeAvatar, setChangeAvatar] = useState(true);

  const [avatarSrc, setAvatarSrc] = useState(currentUser.avatar);
  const [preview, setPreview] = useState(null);
  
  useEffect(() => {
    (async () => {
      if (avatarSrc != null) {
        setChangeAvatar(false);
      }
      const pageData = await getPageContent('member');
      if (pageData) {
        var content = JSON.parse(pageData.content);

        setTitle(content['title'] ? content['title'] : null);
        setSubText(content['subtext'] ? content['subtext'] : null);
      }

      console.log(auth.currentUser.uid);
      try {
        const currentSubSnapshot = await firestore
          .doc(`users/${auth.currentUser.uid}/subscription/data`)
          .get();
        const currentSub =
          currentSubSnapshot.data().subscriptionData.currentSubscription;
        console.log(currentSub);
        setCurrentSub(currentSub);
        if (currentSub && currentSub.status === "active") {
          setSubscriptionActive(true);
          let nextDateLabel, nextDate;
          if (currentSub.cancel_at_period_end) {
            nextDateLabel = "Subscription Ends";
            nextDate = formatStripeDate(currentSub.current_period_end);
          } else {
            nextDateLabel = "Next Payment Date";
            nextDate = formatStripeDate(currentSub.current_period_end);
          }
          setSubscriptionStatus([
            {
              label: "Status",
              value: currentSub.status,
            },
            {
              label: "Sign Up Date",
              value: formatStripeDate(currentSub.start_date),
            },
            {
              label: nextDateLabel,
              value: nextDate,
            },
          ]);
          setSubscriptionId(currentSub.id);
        } else if (currentSub && currentSub.status === "trialing") {
          setSubscriptionActive(true);
          setSubscriptionStatus([
            {
              label: "Status",
              value: "Trialling",
            },
            {
              label: "Trial Ends",
              value: formatStripeDate(currentSub.trial_end),
            },
            {
              label: "First Payment Date",
              value: formatStripeDate(currentSub.current_period_end),
            },
          ]);
          setSubscriptionId(currentSub.id);
        } else if (currentSub) {
          setSubscriptionActive(true);
          setSubscriptionStatus([
            {
              label: "Status",
              value: currentSub.status,
            },
            {
              label: "Payment Due Date",
              value: formatStripeDate(currentSub.current_period_end),
            },
          ]);
        } else {
          setSubscriptionActive(false);
        }
      } catch (err) {
        console.error(err);
        console.log("AAAAAAAAAAAAAAAA");
      } finally {
        setLoadingSubData(false);
      }
    })();
  }, []);

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "newEmail":
        setNewEmail(e.target.value);
        break;
      case "currentPassword":
        setCurrentPassword(e.target.value);
        break;
      case "newPassword":
        setNewPassword(e.target.value);
        break;
      case "confirmNewPassword":
        setConfirmNewPassword(e.target.value);
        break;
      default:
        console.error("Field name not found");
    }
  };

  const handleEmailUpdate = async () => {
    if (newEmail) {
      try {
        setLoading((arr) => [...arr, "email"]);
        await auth.currentUser.updateEmail(newEmail);
        N.notify("Email Changed Successfully");
        setNewEmail("");
      } catch (err) {
        N.error(err, err.message);
      } finally {
        setLoading((arr) => arr.filter((el) => el !== "email"));
      }
    }
  };

  const handlePasswordChange = async () => {
    if (currentPassword && newPassword === confirmNewPassword) {
      try {
        setLoading((arr) => [...arr, "password"]);
        await auth.currentUser.reauthenticateWithCredential(
          emailAuthProvider.credential(currentUser.email, currentPassword)
        );
        await auth.currentUser.updatePassword(newPassword);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        N.notify("Password Changed Successfully");
      } catch (err) {
        N.error(err, err.message);
      } finally {
        setLoading((arr) => arr.filter((el) => el !== "password"));
      }
    }
  };

  const handleSubmitCard = async () => {
    try {
      setLoading((arr) => [...arr, "updateCard"]);
      // if active subscription, update card details.
      if (
        (currentSub && currentSub.status === "trialing") ||
        currentSub.status === "active"
      ) {
        const result = await updatePaymentDetails({
          userId: currentUser.uid,
          subscriptionId: currentSub.id,
          billingDetails: {},
        });
        N.notify("Card updated successfully");
      } else {
        // there has been a card issue.
        const result = await retryInvoicePayment({
          invoiceId: currentSub.paymentIssue.invoice_id,
          userId: currentUser.uid,
          billingDetails: {},
        });
        N.notify("Card updated successfully");
      }
      window.location.reload();
    } finally {
      setLoading((arr) => arr.filter((el) => el !== "updateCard"));
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setLoading((arr) => [...arr, "cancelling"]);

      if (currentSub && currentSub.status === "active") {
        const sure = window.confirm(
          "Are you sure you want to cancel your subscription?"
        );
        if (sure) {
          const result = await cancelSubscription({
            userId: currentUser.uid,
            subscriptionId: currentSub.id,
          });
          console.log(result);
          N.notify("Subscription cancelled");
        }
      } else if (currentSub && currentSub.status === "trialing") {
        const sure = window.confirm(
          "Are you sure you want to cancel your subscription?"
        );
        if (sure) {
          const result = await cancelSubscription({
            userId: currentUser.uid,
            subscriptionId: currentSub.id,
            immediately: true,
          });
          console.log(result);
          N.notify("Subscription cancelled");
        }
      }
      window.location.reload();
    } finally {
      setLoading((arr) => arr.filter((el) => el !== "cancelling"));
    }
  };

  const handleCreateSubscription = async () => {
    try {
      setLoading((arr) => [...arr, "creatingSub"]);
      const billingDetails = {
        email: currentUser.email,
        name: currentUser.name
      };

      const result = await createSubscription({
        userId: currentUser.uid,
        priceId: prices.default,
        isTrial: true,
        billingDetails,
      });
      window.location.reload();
    } finally {
      setLoading((arr) => arr.filter((el) => el !== "creatingSub"));
    }
  };

  const onCrop = (preview) => {
    setPreview(preview);
  }

  const onClose = () => {
    setPreview(null);
  }

  const onBeforeFileLoad = (elem) => {
    // if(elem.target.files[0].size > 71680){
    //   elem.target.value = "";
    // };
  }

  const uploadAvatar = async () => {
    const data = preview.replace("data:image/jpeg;base64,", '');
    let fileUrl = await uploadBase64Image("avatar/" + currentUser.uid + ".jpg", data);
    await updateUserAvatar(currentUser.uid, fileUrl);
    setAvatarSrc(fileUrl);
    setChangeAvatar(false)
  }

  const allowChangeAvatar = () => {
    setChangeAvatar(true)
  }

  const disallowChangeAvatar = () => {
    setChangeAvatar(false)
    setPreview(null);
  }

  const snipPage = () => {
    history.push("/content");
  }

  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      <Helmet>
        <title>Profile - TubeDiary</title>
        <meta name="description" content="Update your payment details and manage your membership at TubeDiary – a second brain and book summary platform designed to help you improve your habits." />
      </Helmet>
      <PageBody>
        <ExploreContainer>
          <h1>
            Profile
          </h1>
        </ExploreContainer>
        {/* { changeAvatar ? (
            <div style={{ display: 'block', width: 'auto', margin: 'auto', marginBottom: '1rem' }}>
              <div style={{ display: 'block', width: 'auto', margin: 'auto', marginBottom: '1rem' }}>
                <Avatar
                  width={300}
                  height={300}
                  onCrop={onCrop}
                  onClose={onClose}
                  onBeforeFileLoad={onBeforeFileLoad}
                  exportMimeType={'image/jpeg'}
                  label ={ "Upload an avatar" }
                />
              </div>
                <div className="text-center" style={{ display: 'flex', width: 'auto', margin: 'auto', marginBottom: '1rem' }}>
                  { preview && (
                    <Button
                      primary
                      className="m-2"
                      label="Upload Avatar"
                      onClick={uploadAvatar}
                    />
                  )}
                    <Button
                      primary
                      className="m-2"
                      label="Cancel"
                      onClick={disallowChangeAvatar}
                    />
                </div>
            </div>
          ):
          (
          <div style={{ display: 'block', width: 'auto', margin: 'auto', marginBottom: '1rem' }}>
            <div style={{ display: 'block', width: 'auto', margin: 'auto', marginBottom: '1rem' }}>
              <LazyLoadImage 
                src={ avatarSrc }
                effect="blur"
                width={'300px'}
                height={'300px'}
                className="custom_avatar"
              />
            </div>
            <div className="text-center" style={{ display: 'flex', width: 'auto', margin: 'auto', marginBottom: '1rem' }}>
                <Button
                  primary
                  className="m-2"
                  label="Change Avatar"
                  onClick={allowChangeAvatar}
                />
            </div>
          </div>
          )} */}

        <div>

          {/*  */}
          <div>
            <SubscriptionArea className="mb-3">
              {/* <SectionHeader style={{ marginTop: 0 }}>
                Premium Library Subscription
              </SectionHeader> */}
              {subscriptionStatus ? (
                <>
                  {subscriptionStatus === "Error" ? (
                    <span>An Error Occurred</span>
                  ) : (
                    <>
                      {/* {subscriptionStatus.map((el, index) => (
                        <SubRow key={index}>
                          <Bold>{el.label}:</Bold>
                          <SubRowValue>{el.value}</SubRowValue>
                        </SubRow>
                      ))} */}
                      {subscriptionStatus &&
                        currentSub &&
                        !currentSub.cancel_at_period_end ? (
                        <>
                          <h4 style={{ color: "var(--color-primary)" }}>
                            Update Payment Method
                          </h4>
                          <PaymentFormContainer>
                            <Plinth>
                              <Label>Card Details</Label>
                              <div>
                                <CardElement
                                  options={{
                                    style: {
                                      base: {
                                        backgroundColor: "#edf6ff",
                                        // fontSize: "20px",
                                        color: "#424770",
                                        "::placeholder": {
                                          color: "#aab7c4",
                                        },
                                      },
                                      invalid: {
                                        color: "#9e2146",
                                      },
                                    },
                                  }}
                                  onChange={(e) => {
                                    if (e.complete) {
                                      setValidCard((vc) => true);
                                    } else {
                                      setValidCard((vc) => false);
                                    }
                                  }}
                                />
                              </div>
                            </Plinth>
                            {loading.includes("updateCard") ? (
                              <Loading />
                            ) : (
                              <Button
                                primary
                                label={"Update Payment Details"}
                                disabled={!validCard}
                                onClick={handleSubmitCard}
                              />
                            )}

                            <span
                              style={{
                                color: "gray",
                                textAlign: "center",
                                fontSize: "12px",
                                marginTop: "4px",
                              }}
                            >
                              All payments are processed with Stripe
                            </span>
                          </PaymentFormContainer>
                          <>
                            <div>
                              {/* <h4 style={{ color: "var(--color-primary)" }}>
                                Cancel Subscription
                              </h4> */}
                              {loading.includes("cancel") ? (
                                <Loading />
                              ) : (
                                <Button
                                  primary
                                  label={"Cancel Subscription"}
                                  onClick={handleCancelSubscription}
                                  style={{ backgroundColor: "red" }}
                                />
                              )}
                            </div>
                          </>
                        </>
                      ) : (
                        <div></div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>{loadingSubData ? <Loading /> : <div></div>}</>
              )}

              {!subscriptionActive && !currentSub ? (
                <>
                  {/* <PlanSelector>
                    <PlanButton
                      active={plan === "monthly"}
                      onClick={() => setPlan("monthly")}
                    >
                      <div>
                        <span>${pricing.monthly.pricePerMonth} per month</span>
                      </div>
                    </PlanButton>
                    <PlanButton
                      active={plan === "yearly"}
                      onClick={() => setPlan("yearly")}
                    >
                      <div>
                        <span>${pricing.annual.pricePerMonth} per month</span>
                        <span style={{ fontSize: "12px" }}>(billed yearly)</span>
                      </div>
                    </PlanButton>
                  </PlanSelector> */}
                  <PaymentFormContainer>
                    <Plinth>
                      <Label>Card Details</Label>
                      <div>
                        <CardElement
                          options={{
                            style: {
                              base: {
                                backgroundColor: "#edf6ff",
                                // fontSize: "20px",
                                color: "#424770",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                          onChange={(e) => {
                            if (e.complete) {
                              setValidCard((vc) => true);
                            } else {
                              setValidCard((vc) => false);
                            }
                          }}
                        />
                      </div>
                    </Plinth>
                    {loading.includes("creatingSub") ? (
                      <Loading />
                    ) : (
                      <Button
                        primary
                        label={"Activate Subscription"}
                        disabled={!validCard}
                        onClick={handleCreateSubscription}
                      />
                    )}

                    <span
                      style={{
                        color: "gray",
                        textAlign: "center",
                        fontSize: "12px",
                        marginTop: "4px",
                      }}
                    >
                      All payments are processed with Stripe
                    </span>
                  </PaymentFormContainer>
                </>
              ) : (
                <div></div>
              )}
            </SubscriptionArea>
            <SectionHeader>Update Password</SectionHeader>
            <Input
              label="Current Password"
              type="password"
              value={currentPassword}
              onChange={handleInputChange}
              name="currentPassword"
            />
            <Input
              label="New Password"
              type="password"
              value={newPassword}
              onChange={handleInputChange}
              name="newPassword"
            />
            <Input
              label="Confirm New Password"
              type="password"
              value={confirmNewPassword}
              onChange={handleInputChange}
              name="confirmNewPassword"
            />
            {loading.includes("password") ? (
              <Loading />
            ) : (
              <Button
                primary
                label="Update Password"
                onClick={handlePasswordChange}
              />
            )}
          </div>
          <div>

            <div>
              <SectionHeader>Member Guidelines</SectionHeader>
              <div>
                <h3>{guideTitle}</h3>
                <div>
                  {ReactHtmlParser(guideSubtext)}
                </div>


                {/* <div>
                  <a className="btn btn-info m-4" href="javascript:function isYouTubeUrl(url) {const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;return youtubeRegex.test(url);};function iptxt() {var d = document;try {if (!d.body) throw 0;if(isYouTubeUrl(d.location.href)) {const title = document.querySelector('#title h1').innerText;let channelName = document.querySelector('.ytd-video-owner-renderer#channel-name').innerText; const arr = channelName.trim().split(/\s{2,}/g); channelName = arr[0]; const duration = document.querySelector('.ytp-time-duration').innerText;let params = '&type=youtube&title=' + encodeURIComponent(title) + '&channel=' + encodeURIComponent(channelName) + '&duration=' + duration;window.open('https://www.xhabits.co/insights?u=' +encodeURIComponent(d.location.href) + params,'_blank').focus();}else{alert('This functionality can only be used on YouTube!');}} catch (e) {console.log('Please wait until the page has loaded.');}}iptxt();void(0)">
                  YouTube Save</a>
                  <i className="fa fa-arrow"></i>
                  <label>Drag this button to your Bookmark bar.</label>
                </div> */}

                <div>
                  <a className="btn btn-info m-4" href="javascript:function iptxt(){var d=document;try{if(!d.body)throw(0); let title = d.title; window.open('https://www.xhabits.co/insights?u=%27+encodeURIComponent(d.location.href) + '&category=Article&title=' + title , %27_blank%27).focus();}catch(e){alert(%27Please wait until the page has loaded.%27);}}iptxt();void(0)">
                    Content Save </a>
                  <i className="fa fa-arrow"></i>
                  <label>Drag this button to your Bookmark bar.</label>
                </div>
                <SectionHeader>Bonuses</SectionHeader>
                <SnipText className="p-4" onClick={(e) => snipPage()}>Access the Snips library here</SnipText>
              </div>
            </div>

            <SectionHeader>My Info</SectionHeader>
            <InfoBox>
              <span>
                Current Email: <Bold>{currentUser.email}</Bold>
              </span>
            </InfoBox>
            <Input
              label="Email Address"
              value={newEmail}
              onChange={handleInputChange}
              name="newEmail"
            />
            {loading.includes("email") ? (
              <Loading />
            ) : (
              <Button
                primary
                label="Update Email Address"
                onClick={handleEmailUpdate}
              />
            )}
          </div>
        </div>

        <div className="mt-5">
          <div>
            <SectionHeader>Contact</SectionHeader>
            <div>
              <p>
                If you have feedback, require support, or have a general enquiry
                please reach out to:
              </p>
              <RevealEmailComponent />
            </div>
          </div>
        </div>
      </PageBody>
    </DefaultLayout>
  );
};

export default ProfilePage;
const PreviewAvatar = styled.img`
  width: 300px;
  height: 300px;
  object-fit: contain;
  border-radius: 100%;
`;

const SnipText = styled.div`
  cursor: pointer;
  :hover{
    background: #e3e2e3;
    color: #000;
  }
`;

const ExploreContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 70px !important;
  margin-bottom: 70px !important;

  > h1 {
    font-weight: 700;
    font-size: var(--font-size-1);
    color: var(--color-text-default);
  }
`;
const SubRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
`;

const SubRowValue = styled.span`
  text-align: right;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const InfoBox = styled.div`
  background-color: #e1efff;
  padding: 2rem;
  width: 100%;
  > p {
  }
`;

const SectionHeader = styled.h3`
  margin-top: 0rem;
  color: var(--color-primary);
  font-size: 24px;
`;

const SubscriptionArea = styled.div`
  background-color: var(--background-panel);
  box-shadow: var(--shadow-default);
  padding: 2rem;
  border-radius: 10px;
  display: block !important;
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  > h1 {
    margin: auto;
  }
  > div {
    display: flex;
    width: 100%;
    align-items: flex-start;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  @media screen and (max-width: ${breakpoints.s}) {
    > div {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.s}) {
    > div {
      flex-direction: row;
      justify-content: space-between;
      > div {
        width: 40%;
      }
    }
  }
`;

const PaymentFormContainer = styled.div`
  width: 100% !important;
  display: block !important;
  // height: 85%;
  // display: none;
  // flex-direction: column;
  // justify-content: space-between;
`;

const Plinth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #edf6ff;
  place-items: center;
  width: 100%;
  padding: 0.5rem;
  border-radius: 12px;

  margin-bottom: 0.5rem;

  > div {
    display: block;
    width: 100%;
    height: fit-content;
  }
`;

const Label = styled.label`
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  padding: 1px 2px;
  font-weight: 700;
`;

const PlanSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: 0.5rem;
  border: 1px solid #dedede;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const PlanButton = styled.button`
  width: 50%;
  height: 45px;

  border: none;
  display: grid;
  place-items: center;
  cursor: pointer;

  background-color: ${({ active }) => (active ? "#2d7ad9" : "#fff")};
  outline: none;

  > div {
    display: flex;
    flex-direction: column;
    > span {
      font-size: 14px;
      font-weight: 700;
      color: ${({ active }) => (active ? "white" : "#4a4a4a")};
    }
  }

  &:hover {
    background-color: ${({ active }) => (active ? "#2d7ad9" : "#f3f3f3")};
  }
`;

const RevealEmailComponent = () => {
  const [finalEmail, setFinalEmail] = useState();
  const gen = () => {
    let ff = 'samy@samyfelice.com';
    setFinalEmail(ff);
  };

  if (finalEmail) {
    return <a href={`mailto:${finalEmail}`}>{finalEmail}</a>;
  } else {
    return <Button primary label={"Click to show email"} onClick={gen} />;
  }
};
