import React, { useEffect, useState, useRef } from "react";

import icon1_u from "../assets/img/progress/spatial-repetition-stage-1-unread.png";
import icon1_c from "../assets/img/progress/spatial-repetition-stage-1.png";
import icon2_u from "../assets/img/progress/spatial-repetition-stage-2-unread.png";
import icon2_p from "../assets/img/progress/spatial-repetition-stage-2-paused.png";
import icon2_c from "../assets/img/progress/spatial-repetition-stage-2.png";
import icon3_u from "../assets/img/progress/spatial-repetition-stage-3-unread.png";
import icon3_p from "../assets/img/progress/spatial-repetition-stage-3-paused.png";
import icon3_c from "../assets/img/progress/spatial-repetition-stage-3.png";

import DefaultLayout from "../layouts/DefaultLayout";
import styled from "styled-components";

import Select from "../components/Select";

import { FreeTag, PremiumTag, NewTag, ComingSoonTag } from "../components/Tag";

import { useMediaQuery } from "react-responsive";

import { firestore, auth, getBooks, getUserBooks,getPermission,updatePermission } from "../firebase/firebase.utils";

import { breakpoints } from "../styles/globalStyles";

import { Link } from "react-router-dom";

import Button from "../components/Button";

import GrowthNoteIconContainer from "../components/GrowthNoteIconContainer";
import { Helmet } from "react-helmet";
import SubscribeWarning from "../components/SubscribeWarning";
import { useModal } from "../context/Modal";
import { useHistory } from "react-router-dom";
import { pricing } from "../config";
import SubscribePermissionWarnning from "../components/SubscribePermissionWarnning";
import { usePageInfoProvider } from "../context/PageInfoContext";
import ScrollToTop from "../components/ScrollToTop";

const mapUserBookToDisplay = (userBook) => {
  if (!userBook)
    return { icon: icon1_u, message: "Not started", color: "gray" };
  if (userBook.finalReviewDate) return { icon: icon3_c, message: "Completed" };
  if (userBook.finalReviewSchedule) {
    // console.log(userBook?.finalReviewSchedule?.toDate());
    // console.log(new Date());
    // console.log(userBook.finalReviewSchedule.toDate() < new Date());
    if (userBook.finalReviewSchedule.toDate() < new Date()) {
      return {
        icon: icon3_u,
        message: "Ready for final review",
        color: "green",
      };
    } else {
      return { icon: icon3_p, message: "Reviewed" };
    }
  }
  if (userBook.reviewSchedule) {
    if (userBook.reviewSchedule.toDate() < new Date()) {
      return { icon: icon2_u, message: "Ready for review", color: "green" };
    } else {
      return { icon: icon2_p, message: "Read" };
    }
  }
  return { icon: icon1_u, message: "Not started", color: "gray" };
};

const filterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Air",
    value: "Air",
  },
  {
    label: "Earth",
    value: "Earth",
  },
  {
    label: "Water",
    value: "Water",
  },
  {
    label: "Fire",
    value: "Fire",
  },
];

const Books = ({ currentUser, themeToggler, theme }) => {
  const { openModal } = useModal();
  const history = useHistory();

  const [books, setBooks] = useState(null);
  const [userBooks, setUserBooks] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("All");

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.s} )` });
  const { subscribed } = currentUser;
  // console.log(subscribed, currentUser)
  const [currentSub, setCurrentSub] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [canAccess, setCanAccess] = useState(false);

  const [showPremium, setShowPremium] = useState(false);
  const [permission,setPermission] = useState(null)
  const {isAlertShow,setIsAlertShow} = usePageInfoProvider()

  const checkAccess = (bookID) =>{

    return true;

    if (currentUser.loggedIn){

      var oncycle = userBooks.find((ubook) => ubook.bookId === bookID)?.readDate? false: true

      if(oncycle) { return true }

      if (permission == null)
        return false
      if (permission.accesscount == undefined)
        return true
      if (permission.accesscount < 3)
        return true
      
      let currentTime = new Date(Date.now())

      let currentYear = currentTime.getFullYear()
      let currentMonth = currentTime.getMonth()          
      
      let prevTime = new Date(permission.accesstime)
      let year = prevTime.getFullYear()
      let month = prevTime.getMonth()         

      if (currentYear > year || (currentYear == year && currentMonth > month)){
        permission.accesscount = 0
        permission.accesstime = currentTime
        // permission.allowedbooks = []
        updatePermission(currentUser.email,{
          accesscount:permission.accesscount,
          accesstime:permission.accesstime,
          // allowedbooks:permission.allowedbooks,
        }).then(() =>{
          return true
        })
      }

      if (permission.allowedbooks != undefined){
        const books = permission.allowedbooks
        for (var i=0; i < books.length; i++) {
            if (bookID == books[i])
              return true
        }
      }
      setIsAlertShow(true)
      return false
    }
  }
  const updatePermissionFirebase = (bookID) =>{

    var oncycle = userBooks.find((ubook) => ubook.bookId === bookID)?.readDate? false: true

    if(oncycle) { return; }

    if (permission.accesscount == 3)
      return
    
    if (permission.allowedbooks == undefined)
      permission.allowedbooks = []
    else{
      const books = permission.allowedbooks
      for (var i=0; i < books.length; i++) {
          if (bookID == books[i])
            return
      }
    }
    permission.accesscount = permission.accesscount + 1
    permission.accesstime = Date.now()
    
    permission.allowedbooks.push(bookID)
    updatePermission(currentUser.email,{
                      accesscount:permission.accesscount,
                      accesstime:permission.accesstime,
                      allowedbooks:permission.allowedbooks,
                    })
  }
  const alertPermission = () =>{
    openModal(
        <SubscribePermissionWarnning currentUser={currentUser} theme={theme}/>
    )
  }
  const openWarning = () => {
    //checkPermission()
    openModal(
      <SubscribeWarning currentUser={currentUser}  theme={theme} subscribe={subscribe}/>,
      "New Insight",
      "books"
    )
  }

  const subscribe = () => {
    history.push("/profile");
  }
  useEffect(() => {
    if (!currentUser.empty) {
      if (currentUser.loggedIn) {
        getPermission(currentUser.email).then((data) =>{
          setPermission(data)
        })   
      }
    }    
    
    if (!currentUser.empty) {
      getBooks().then((data) => {
        let _data = data.sort((a, b) => b.free - a.free);
        _data = _data.sort((a, b) => a.hide - b.hide)
        setBooks(_data);
        if (currentUser.loggedIn) {
          getUserBooks(currentUser.uid).then((data) => {
            console.log('userbook', data)
            setUserBooks(data);
          });
        }
      });
    }
    (async () => {
      try {
        const currentSubSnapshot = await firestore
          .doc(`users/${auth.currentUser.uid}/subscription/data`)
          .get();
        const currentSub = currentSubSnapshot.data().subscriptionData.currentSubscription;
        const stripeCustomerId = currentSubSnapshot.data().subscriptionData.stripeCustomerId;

        console.log('currentsub', currentSub);
        console.log('stripeCustomerId', stripeCustomerId);
        setCurrentSub(currentSub);

        if (currentSub && currentSub.status === "active") {
          setCanAccess(true);
          setSubscriptionStatus([
            {
              label: "Status",
              value: currentSub.status,
            },
          ]);
        } else if (currentSub && currentSub.status === "trialing") {
          const trial_end = currentSub.trial_end;
          const timestamp = Date.now();
          
          if (trial_end * 1000 <= timestamp) {
            setCanAccess(true);
          }
          
          setSubscriptionStatus([
            {
              label: "Status",
              value: "Trialling",
            },
          ]);
        } else if (currentSub) {
          setSubscriptionStatus([
            {
              label: "Status",
              value: currentSub.status,
            },
          ]);
        }

        if (stripeCustomerId != 'isFree') {
          if( (currentSub && !currentSub.cancel_at_period_end && currentSub.status === "active") || (currentSub && currentSub.status == 'trialing')) {
            // show premium book
            setShowPremium(true);
          }else{
            setShowPremium(false);
          }
        } else {
          setShowPremium(true);
        }
        
      } catch (err) {
        console.error(err);
      } finally {
      }
    })();    
  }, [currentUser, isAlertShow]);
  
  const [trigger, setTrigger] = useState(false);
  const elementRef = useRef();

  let sTimer = null;
  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current.getBoundingClientRect().top < -300) {
        // User has scrolled down
        setTrigger(true);
      }else{
        setTrigger(false);
      }
    };
    sTimer = setInterval(() => {
      handleScroll();
    }, 500);
    return () => {
      if(sTimer) {
        clearInterval(sTimer);
      }
    };
  }, []);

  return (
    <DefaultLayout themeToggler={themeToggler} theme={theme}>
      <Helmet>
        <title>Snips - TubeDiary</title>
        <meta name="description" content="Access TubeDiary book summaries designed to help you improve your habits in a sustainable way. Track your notes and learning journey along the way." />
      </Helmet>
      <PageBody>
        <ExploreContainer>
          <h1 ref={elementRef}>Explore Snips</h1>
          <p>
          Identify habits worth eliminating based on the wisdom of key books.
          <br/>
          And access a toolkit designed to help you apply what you've learned.{" "}
          </p>
          {isMobile ? (
            <div style={{ width: "100%" }}>
              <Select
                options={filterOptions}
                onChange={(val) => setCategoryFilter(val.value)}
                value={filterOptions.find((fo) => fo.value === categoryFilter)}
                theme={theme}
              />
            </div>
          ) : (
            <FilterRow>
              {filterOptions.map((f, index) => (
                <div key={index} style={{ padding: "1rem" }}>
                  <Button
                    primary={f.value === categoryFilter}
                    secondary={f.value !== categoryFilter}
                    onClick={() => {
                      setCategoryFilter(f.value)
                    }}
                    label={f.label}
                  />
                </div>
              ))}
            </FilterRow>
          )}
        </ExploreContainer>
        <BooksContainer>
          {books && (
            <>
              {books
                .filter((b) =>
                  categoryFilter === "All" ? b : b.category === categoryFilter
                )
                .map((book, index) => (
                  <div key={index}>
                    {showPremium || book.free ? (
                      book.free ? (<>
                        <Link
                          to={ book.hide != true ? `/book-snips/${book.slug}` : '#'}
                          style={{ textDecoration: "none" }}
                          key={book.id}
                        >
                          <Book hide={book.hide}>

                            <div>
                              <img src={book.image} alt={`${book.title} cover`} />
                            </div>
                            <div>
                              { book.hide == true ? (
                                <ComingSoonTag/>
                              ): (
                                <>
                                  {!subscribed && (
                                    <>{book.free ? <FreeTag /> : <PremiumTag />}</>
                                  )}
                                </>
                              )}
                              <div
                                style={{
                                  display:"flex",
                                  flexDirection:"row",
                                  alignItems:"center"
                                }}
                              >
                              <GrowthNoteIconContainer
                                message={
                                  mapUserBookToDisplay(
                                    userBooks.find(
                                      (ubook) => ubook.bookId === book.id
                                    )
                                  ).message
                                }
                                color={
                                  mapUserBookToDisplay(
                                    userBooks.find(
                                      (ubook) => ubook.bookId === book.id
                                    )
                                  ).color
                                }
                              >
                                <img
                                  src={
                                    mapUserBookToDisplay(
                                      userBooks.find(
                                        (ubook) => ubook.bookId === book.id
                                      )
                                    ).icon
                                  }
                                  alt="indicator"
                                  style={{
                                    height: "46px",
                                    filter: userBooks.find(
                                      (ubook) => ubook.bookId === book.id
                                    )?.readDate
                                      ? undefined
                                      : "grayscale(100%)",
                                  }}
                                />
                              </GrowthNoteIconContainer>
                              {<>{book.new && <NewTag />}</>}
                              </div>
                            </div>
                            <h3>{book.title}</h3>
                            <h4>{book.author}</h4>
                            <p>{book.summary}</p>
                            <div></div>
                          </Book>
                        </Link>
                      </>):(
                          <Link
                          to={ book.hide != true ? `/book-snips/${book.slug}` : '#'}
                          style={{ textDecoration: "none" }}
                          key={book.id
                          }
                          onClick={()=>{updatePermissionFirebase(book.id)}}
                        >
                          <Book hide={book.hide}>
                            <div>
                              <img src={book.image} alt={`${book.title} cover`} />
                            </div>
                            <div>
                              
                              { book.hide == true ? (
                                <ComingSoonTag/>
                              ): (
                                <>
                                  {!subscribed && (
                                    <>{book.free ? <FreeTag /> : <PremiumTag />}</>
                                  )}
                                </>
                              )}
                              <div
                                style={{
                                  display:"flex",
                                  flexDirection:"row",
                                  alignItems:"center"
                                }}
                              >
                              <GrowthNoteIconContainer
                                message={
                                  mapUserBookToDisplay(
                                    userBooks.find(
                                      (ubook) => ubook.bookId === book.id
                                    )
                                  ).message
                                }
                                color={
                                  mapUserBookToDisplay(
                                    userBooks.find(
                                      (ubook) => ubook.bookId === book.id
                                    )
                                  ).color
                                }
                              >
                                <img
                                  src={
                                    mapUserBookToDisplay(
                                      userBooks.find(
                                        (ubook) => ubook.bookId === book.id
                                      )
                                    ).icon
                                  }
                                  alt="indicator"
                                  style={{
                                    height: "46px",
                                    filter: userBooks.find(
                                      (ubook) => ubook.bookId === book.id
                                    )?.readDate
                                      ? undefined
                                      : "grayscale(100%)",
                                  }}
                                />
                              </GrowthNoteIconContainer>
                              {<>{book.new && <NewTag />}</>}
                              </div>
                            </div>
                            <h3>{book.title}</h3>
                            <h4>{book.author}</h4>
                            <p>{book.summary}</p>
                            <div></div>
                          </Book>
                          </Link>
                      )
                    
                    ): (
                      <>
                        <div
                          onClick={ book.hide != true ? openWarning : console.log('333') }
                          key={book.id}
                        >
                          <Book hide={book.hide}>
                            <div>
                              <img src={book.image} alt={`${book.title} cover`} />
                            </div>
                            <div>
                              { book.hide == true ? (
                                <ComingSoonTag/>
                              ): (
                                <>
                                  {!subscribed && (
                                    <>{book.free ? <FreeTag /> : <PremiumTag />}</>
                                  )}
                                </>
                              )}
                              <div
                                style={{
                                  display:"flex",
                                  flexDirection:"row",
                                  alignItems:"center"
                                }}
                              >
                                <GrowthNoteIconContainer
                                  message={
                                    mapUserBookToDisplay(
                                      userBooks.find(
                                        (ubook) => ubook.bookId === book.id
                                      )
                                    ).message
                                  }
                                  color={
                                    mapUserBookToDisplay(
                                      userBooks.find(
                                        (ubook) => ubook.bookId === book.id
                                      )
                                    ).color
                                  }
                                >
                                  <img
                                    src={
                                      mapUserBookToDisplay(
                                        userBooks.find(
                                          (ubook) => ubook.bookId === book.id
                                        )
                                      ).icon
                                    }
                                    alt="indicator"
                                    style={{
                                      height: "46px",
                                      filter: userBooks.find(
                                        (ubook) => ubook.bookId === book.id
                                      )?.readDate
                                        ? undefined
                                        : "grayscale(100%)",
                                    }}
                                  />
                                </GrowthNoteIconContainer>
                                {<>{book.new && <NewTag />}</>}
                              </div>
                            </div>
                            <h3>{book.title}</h3>
                            <h4>{book.author}</h4>
                            <p>{book.summary}</p>
                            <div></div>
                          </Book>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </>
          )}
        </BooksContainer>
        {
          !subscribed && (
            <PreminumCardContainer>
              <h1>Access all the premium content</h1>
              <h1>and much more</h1>
              <SubscriptionArea className="mb-3 mt-5">
                  <>
                    <div style={{ fontSize: "1.4rem", fontWeight: 'bold' }}>
                      <p 
                        style={{
                          color: "gray",
                          textAlign: "center",
                          fontSize: "12px",
                          marginTop: "4px",
                        }}
                      >
                        
                      </p>
                    </div>
                    <div>
                      <p style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0' }} >
                        <span style={{ fontSize: "1.2rem", fontWeight: 'normal', marginRight: '1rem', textDecoration: 'line-through' }}>${pricing.annual.origin}</span>
                        $
                        <span style={{ fontSize: '3rem', verticalAlign: '-webkit-baseline-middle' }}>
                          {pricing.annual.pricePerMonth}
                        </span>
                      </p>
                      <div></div>
                    </div>
                    <div style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                      <Button primary label="Get Started" style={{ margin: 'auto' }}
                      onClick={() => history.push("/profile")}
                      />
                    </div>
                    <div
                      style={{
                        color: "gray",
                        textAlign: "center",
                        fontSize: "12px",
                        marginTop: "4px",
                      }}
                    >
                      <span>
                        
                      </span>
                      <br />
                        
                    </div>
                  </>
              </SubscriptionArea>
              <div>
                Payment handled by Stripe. We don't store your card details.
              </div>
            </PreminumCardContainer>
          )
        }
      </PageBody>
      {/* <ScrollToTop trigger ={trigger} /> */}
    </DefaultLayout>
  );
};

export default Books;
const SignupBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakpoints.s}) {
    padding: 2rem;
    background-color: var(--background);
    flex-direction: column;
    > div:first-child {
      display: flex;
      flex-direction: column;
      > h3 {
        font-size: var(--font-size-1);
        font-weight: 700;
        text-align: center;
        color: var(--color-text-default);
      }
      > span {
        margin: 1rem 0;
        font-size: var(--font-size-4);
        line-height: 1;
        text-align: center;
        color: var(--color-text-default);
      }
    }
    > div:nth-child(2) {
      width: 100%;
    }
  }

  @media screen and (min-width: ${breakpoints.s}) {
    > div:first-child {
      padding: 2rem 9rem;
      display: flex;
      flex-direction: column;

      > h3 {
        font-size: var(--font-size-1);
        font-weight: 700;
        color: var(--color-text-default);
      }
      > span {
        margin: 1rem 0;
        font-size: var(--font-size-4);
        line-height: 1;
        color: var(--color-text-default);
      }
    }
    > div:nth-child(2) {
      width: 45%;
    }
  }
`;
const FilterRow = styled.div`
  display: flex;
  padding: 0.1rem;
  @media screen and (max-width: ${breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 70px;

  > h1 {
    font-weight: 700;
    font-size: var(--font-size-1);
    color: var(--color-text-default);
  }
  > p {
    font-size: var(--font-size-4);
    max-width: 1030px;
    text-align: center;
    margin-top: 0;
    color: var(--color-text-default);
  }

  @media screen and (max-width: ${breakpoints.s}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${breakpoints.s}) {
  }
`;

const BooksContainer = styled.div`
  width: 100%;
  display: grid;
  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  justify-content: flex-start;
`;

const Book = styled.div`
  opacity: ${(p) => (p.hide ? 0.5 : 1)};
  cursor: ${(p) => (p.hide == true ? 'not-allowed' : 'pointer')};
  width: 100%;
  height: 634px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  > div:nth-child(1) {
    width: 194px;
    height: 300px;
    margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      height: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }
  > div:nth-child(2) {
    width: 100%;
    height: 40px;
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  > h3 {
    font-size: var(--font-size-3);
    font-weight: 700;
    color: var(--color-text-default);
    margin: 0px;
  }
  > h4 {
    margin-top: 0;
    font-size: var(--font-size-5);
    color: #979797;
  }
  > p {
    font-size: var(--font-size-4);
    color: var(--color-text-default);
    letter-spacing: 0.3px;
    line-height: 1.52;
  }
  > div:last-child {
    width: 100%;
    height: 180px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--special-bookshadow);
  }
    &:hover {
      background-color: var(--highlight-opacity);
    }
  }
`;

const PreminumCardContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${breakpoints.s}) {
    width: 50%;
  }

  @media screen and (max-width: ${breakpoints.s}) {
    width: 90%;
  }
  > h1 {
    font-weight: 700;
    font-size: var(--font-size-1);
    color: var(--color-text-default);
    text-align: center;
  }
`;

const SubscriptionArea = styled.div`
  background-color: var(--background-panel);
  box-shadow: var(--shadow-default);
  padding: 2rem;
  border-radius: 10px;
`;

const SectionHeader = styled.h3`
  color: var(--color-primary);
  font-size: 24px;
`;

const AlertPermissionDiv = styled.div`
  opacity: 0.3;
`