import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Input = ({ label, ...rest }) => {
  return (
    <InputContainer>
      <label htmlFor={label}>{label}</label>
      <input id={label} {...rest} />
    </InputContainer>
  );
};

export default Input;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;
  > label {
    padding-bottom: 10px;
  }
  > input {
    border-radius: 5px;
    border: 1px solid #979797;
    padding: 5px 15px;
    font-size: 1.3rem;
    color: var(--color-text-default);
    background: #ffffff0d;
  }
`;
