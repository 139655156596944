import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";

import Input from "./Input";
import TextArea from "./TextArea";
import Button from "./Button";
import Select from "./Select";

import { useNotifications } from "../context/Notifications";
import { useModal } from "../context/Modal";

import {
  addInsight,
  updateInsight,
  deleteInsight,
} from "../firebase/firebase.utils";
import { breakpoints } from "../styles/globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes, faStar, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Rating } from "react-simple-star-rating";
import useUpload from "../tools/useUpload";
import Dropzone from "./Dropzone";
import Switch from "react-switch";
import InsightCycle from "./InsightCycle";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";

import moment from "moment";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { auth } from "../firebase/firebase.utils";

// const categoryOptions = [
//   {
//     label: "Video",
//     value: "Video",
//   },
//   {
//     label: "Audio",
//     value: "Podcast",
//   },
//   {
//     label: "Article",
//     value: "Article",
//   },
//   {
//     label: "Misc",
//     value: "Misc",
//   },
// ];

const parseDurationToSeconds = (duration) => {
  const timeParts = duration.split(":");
  let totalSeconds = 0;
  if (timeParts.length === 1) {
    // Se não houver horas na duração
    totalSeconds = parseInt(timeParts[0], 10);
  } else if (timeParts.length === 2) {
    // Se houver horas na duração
    totalSeconds = parseInt(timeParts[0], 10) * 60 + parseInt(timeParts[1], 10);
  } else if (timeParts.length == 3) {
    totalSeconds =
      parseInt(timeParts[0], 10) * 3600 +
      parseInt(timeParts[1], 10) * 60 +
      parseInt(timeParts[2], 10);
  }
  console.log(totalSeconds);
  return totalSeconds;
};

const MyInsightModal = ({
  book,
  refreshList,
  currentUser,
  theme,
  hash,
  _link,
  setIsCycleModal,
  paramType,
  paramTitle,
  paramChannel,
  paramDuration,
  paramCategory,
}) => {
  const [title, setTitle] = useState(book ? book.title : paramTitle);
  const [author, setAuthor] = useState(book ? book.author : paramChannel);

  // const [category, setCategory] = useState(
  //   book
  //     ? categoryOptions.find((op) => op.value === book.category)
  //     : paramCategory != undefined
  //     ? categoryOptions.find((op) => op.value === paramCategory)
  //     : categoryOptions[0]
  // );

  let _time = dayjs().startOf("day");
  if (paramDuration != undefined) {
    let seconds = parseDurationToSeconds(paramDuration);
    console.log(seconds);
    _time = dayjs(_time).add(seconds, "second");
  }
  const [tag, setTag] = useState(book ? book.tag : "");
  const [link, setLink] = useState(
    book && book.link ? book.link : (_link ? decodeURIComponent(_link) : "")
  );
  const [time, setTime] = useState(
    book && book.time != "" && book.time != undefined ? dayjs(book.time) : _time
  );
  const [note1, setNote1] = useState(book ? book.note1 : "");
  const [note2, setNote2] = useState(book ? book.note2 : "");
  const [note3, setNote3] = useState(book ? book.note3 : "");

  const [isGenerating, setisGenerating] = useState(false);
  useEffect(() => {
    console.log(time);
  }, [time]);

  const [note, setNote] = useState(book ? book.note : "");
  const [rate, setRate] = useState(book ? book.rate : 0);
  const [fav, setFav] = useState(
    book ? (book.fav !== undefined ? book.fav : false) : false
  );

  const [slug, setSlug] = useState(book ? book.slug : null);
  const [isPrivate, setPrivate] = useState(
    book ? (book.private !== undefined ? book.private : false) : false
  );
  const [fileUrl, setFileUrl] = useState(book ? book.fileUrl : null);

  const [waiting, setWaiting] = useState(null);
  const [showCycle, setShowCycle] = useState(
    book ? (book.showCycle !== undefined ? book.showCycle : false) : false
  );

  const [id] = useState(book ? book.id : null);

  const N = useNotifications();
  const fillColor = "#306aed";

  const { closeModal } = useModal();

  const pseudo = useMemo(() => Math.floor(Math.random() * 10000000), []);

  const [newFile, setNewFile] = useState(null);

  useEffect(() => {
    if (_link != undefined && slug != undefined) {
      if (auth.currentUser.uid) {
        console.log(auth.currentUser.uid);
        setTimeout(async () => {
          await handleSubmit();
        }, 1000);
      }
    }
  }, [_link, auth, slug]);

  const { uploadFile, deleteFileByUrl } = useUpload();
  const handleFileUpload = async (files) => {
    setNewFile(files[0]);
  };
  
  const handleDeleteFile = async (e) => {
    e.stopPropagation();
    if (book && book.fileUrl) {
      await handleSubmit(true);
      setFileUrl(null);
      book.fileUrl = null;
    }
    setNewFile(null);
  };
  const handleRating = (rate) => {
    setRate(rate);
    // other logic
  };
  const handleFav = () => {
    let _fav = !fav;
    setFav(_fav);
  };
  var insightList = localStorage.getItem("insightList");
  if (!insightList) {
    insightList = [];
  } else {
    insightList = JSON.parse(insightList);
  }
  useEffect(() => {
    if (title && !id) {
      let titleToSlug = title.split(" ").join("-") + "-" + pseudo;
      setSlug(titleToSlug);
    }
  }, [title]);

  const handleSubmit = async (deleteFile = false) => {
    // validation
    if (!title ) {
      N.warn("Missing required fields - Title");
      return;
    }
    if ( !slug) {
      N.warn("Missing required fields - Slug");
      return;
    }
    // if ( !category) {
    //   N.warn("Missing required fields - Category");
    //   return;
    // }
    if (newFile && newFile.size > 10485760) {
      N.warn("Attachment could not exceed 10MB");
      return;
    }
    setWaiting("submit");
    try {
      // firestore save (using url from upload)
      // file upload
      let fileUrl = "";

      // if (newFile) {
      //   if(book && book.fileUrl){
      //     await deleteFileByUrl(book.fileUrl);
      //   }
      //   fileUrl = await uploadFile("insight/" + newFile.name, newFile);
      // } else if (!id) {
      //   fileUrl = "";
      // } else {
      //   console.log('deleete')
      //   if(deleteFile === true) {
      //     await deleteFileByUrl(book.fileUrl);
      //     fileUrl = "";
      //   }else{
      //     fileUrl = book.fileUrl ? book.fileUrl : '';
      //   }

      // }

      if (id) {
        let insight = {
          ...book,
          id,
          userId: currentUser.uid,
          title: title ? title : "",
          author: author ? author : "_",
          // category: category.value,
          note: note ? note : null,
          note1: note1 ? note1 : null,
          note2: note2 ? note2 : null,
          note3: note3 ? note3 : null,
          slug: slug !== undefined ? slug : "",
          tag: tag !== undefined ? tag : "",
          link: link !== undefined ? link : "",
          createdAt: book.createdAt,
          fav,
          private: isPrivate,
          fileUrl: fileUrl ? fileUrl : "",
          hash: hash ? hash : "",
          showCycle: showCycle !== undefined ? showCycle : false,
          express: book.express !== undefined ? book.express : false,
          time:
            time == undefined || time == ""
              ? dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss")
              : dayjs(time).format("YYYY-MM-DD HH:mm:ss"),
        };
        if (!currentUser.empty && currentUser.loggedIn) {
          console.log(insight);
          await updateInsight(id, insight);
        } else {
          var selected = insightList.findIndex((item) => {
            return item.id === id;
          });
          insightList[selected] = insight;
          localStorage.setItem("insightList", JSON.stringify(insightList));
        }
        await refreshList();
        setWaiting(null);
        if (deleteFile !== true) {
          closeModal();
        }
      } else {
        let newBook = {
          id: pseudo,
          userId: currentUser.uid,
          title: title ? title : "",
          author: author ? author : "",
          // category: category.value,
          note: note ? note : null,
          note1: note1 ? note1 : null,
          note2: note2 ? note2 : null,
          note3: note3 ? note3 : null,
          slug: slug !== undefined ? slug : "",
          tag: tag !== undefined ? tag : "",
          link: link !== undefined ? link : "",
          createdAt: new Date(),
          fav,
          private: isPrivate,
          fileUrl: fileUrl ? fileUrl : "",
          hash: hash ? hash : "",
          showCycle: showCycle !== undefined ? showCycle : false,
          express: false,
          time: dayjs(time).format("YYYY-MM-DD HH:mm"),
        };
        if (!currentUser.empty && currentUser.loggedIn) {
          await addInsight(newBook);
        } else {
          insightList.push(newBook);
          localStorage.setItem("insightList", JSON.stringify(insightList));
        }

        await refreshList();
        setWaiting(null);
        closeModal();
      }
    } catch (err) {
      setWaiting(null);
      N.error(err);
    }
  };

  const checkIfYoutubeURL = (url) => {
    const youtubeRegex = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S*?[?&].*)?/;
    var _isYouTubeUrl = youtubeRegex.test(url);
    return _isYouTubeUrl;
  }

  const generateSummary = async () => {
    let url = link !== undefined ? link : "";
    const isYb = checkIfYoutubeURL(url);
    let summary = '';
    if (isYb) {
      try {
        setisGenerating(true);
        const apiUrl = 'https://yb-summary-api-3-387084173040.europe-west2.run.app/api/v1/transcript-youtube';
        const data = {
          "url": url,
        };
        var raw = JSON.stringify(data);
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add other headers as needed
          },
          mode: 'cors',
          body: raw,
        });
        setisGenerating(false);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log('Response data:', responseData);
        console.log('Response data:', responseData.summary);
        summary = responseData.summary;
        setNote1(summary);
        // Process the response data as needed
      } catch (error) {
        setisGenerating(false);
        console.error('Error posting data:', error);
        setNote1(summary);
      }
    }
    setNote1(summary);
  }

  const handleDelete = async () => {
    if (!currentUser.empty && currentUser.loggedIn) {
      await deleteInsight(id);
    } else {
      var selected = insightList.findIndex((item) => {
        return item.id == id;
      });
      insightList.splice(selected, 1);
      localStorage.setItem("insightList", JSON.stringify(insightList));
    }
    await refreshList();
    closeModal();
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "title":
        setTitle(e.target.value);
        break;
      case "author":
        setAuthor(e.target.value);
        break;
      case "note":
        setNote(e.target.value);
        break;
      case "note1":
        setNote1(e.target.value);
        break;
      case "note2":
        setNote2(e.target.value);
        break;
      case "note3":
        setNote3(e.target.value);
        break;
      case "tag":
        setTag(e.target.value);
        break;
      case "link":
        setLink(e.target.value);
        break;
      default:
        console.error("Field name not found");
    }
  };

  const handleTimeChange = (value, e) => {
    const tt = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    console.log(tt);
    setTime(tt);
  };

  // const handleCategoryChange = (e) => {
  //   setCategory(e);
  // };

  const handlePrivate = (checked) => {
    setPrivate(checked);
  };

  const overlayClick = (e) => {
    e.stopPropagation();
    closeModal();
  };

  const handleCycle = (e) => {
    setShowCycle(!showCycle);
  };
  return (
    <Container>
      <div className="mb-3 pb-3">
        <span></span>
        <FontAwesomeIcon
          className="float-end"
          icon={faTimes}
          size="lg"
          style={{ cursor: "pointer" }}
          onClick={overlayClick}
        />
      </div>
      <InsightFormContainer>
        <DetailsRow>
          <BookDetailForm
            title={"Title"}
            value={title}
            valueName={"title"}
            handleInputChange={handleInputChange}
            theme={theme}
          />
        </DetailsRow>
        <DetailsRow>
          <BookDetailForm
            title={"Channel"}
            value={author}
            valueName={"author"}
            handleInputChange={handleInputChange}
            theme={theme}
          />
        </DetailsRow>

        {/* <DetailsRow>
          <BookDetailForm
            title={"Category"}
            value={category}
            valueName={"category"}
            type="category"
            handleInputChange={handleCategoryChange}
            theme={theme}
          />
        </DetailsRow> */}

        <DetailsRow>
          <BookDetailForm
            title={"Link"}
            value={link}
            valueName={"link"}
            handleInputChange={handleInputChange}
            theme={theme}
          />
        </DetailsRow>

        <DetailsRow>
          <Detail>
            <DetailTitle>Time Spent</DetailTitle>
            <TimeContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  ampm={false}
                  openTo="hours"
                  views={["hours", "minutes", "seconds"]}
                  inputFormat="HH:mm:ss"
                  mask="__:__:__"
                  value={time}
                  onChange={handleTimeChange}
                  className="border w-100 rounded-1"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </TimeContainer>
          </Detail>
        </DetailsRow>

        {/* <DetailsRow>
          <BookDetailForm
            title={"Tag"}
            value={tag}
            valueName={"tag"}
            handleInputChange={handleInputChange}
            theme={theme}
          />
        </DetailsRow> */}
        {/* <DetailsRow>
            <Detail>
              <DetailTitle>Favorite</DetailTitle>
              <FontAwesomeIcon onClick={handleFav} style={{ cursor: 'pointer', color: fav > 0 ? '#f10000': '#cccccc', width: '1.5rem' }}  icon={faHeart} size={"lg"} />
            </Detail>
          </DetailsRow> */}
        {/* <DetailsRow>
            <Detail>
              <DetailTitle>Key Practical Takeaway</DetailTitle>
              <div className="d-flex mb-2">
                <input className="form-control" onChange={handleInputChange} name="note1" value={note1} />
              </div>
            </Detail>
          </DetailsRow> */}
        <DetailsRow>
          <BookDetailForm
            title={"Key Takeaways"}
            value={note1}
            valueName={"note1"}
            type="textarea"
            handleInputChange={handleInputChange}
            theme={theme}
          />
        </DetailsRow>
        {/* <BookCoverHolder>
            <Dropzone
              handleFileUpload={handleFileUpload}
              image={fileUrl}
              newImageName={newFile ? newFile.name : null}
              newImageSize={newFile ? newFile.size : null}
              handleDelete={handleDeleteFile}
            />
          </BookCoverHolder> */}
        {/* <DetailsRow>
          <Detail>
            <DetailTitle>Private</DetailTitle>
            <Switch
              height={24}
              width={48}
              handleDiameter={22}
              onColor={fillColor}
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={handlePrivate}
              checked={isPrivate}
            />
          </Detail>
        </DetailsRow> */}
        {!currentUser.empty && currentUser.loggedIn && (
          <DetailsRow>
            <Detail>
              <div className="d-flex" style={{ marginBottom: "0.5rem" }}>
                {/* <Switch  height={24} width={48} handleDiameter={22} onColor={fillColor} uncheckedIcon={false} checkedIcon = {false} onChange={handleCycle} checked={showCycle ? showCycle : false} /> */}
                <FontAwesomeIcon
                  onClick={handleFav}
                  style={{
                    cursor: "pointer",
                    color: fav > 0 ? "#f10000" : "#cccccc",
                    width: "1.5rem",
                  }}
                  icon={faHeart}
                  size={"lg"}
                />
                <label style={{ marginLeft: "1rem", fontWeight: "700" }}>
                  Activate Growth Cycle
                </label>
              </div>
              {id && fav && (
                <InsightCycle
                  insightId={book.id}
                  insight={book}
                  refreshList={refreshList}
                  currentUser={currentUser}
                  setIsCycleModal={setIsCycleModal}
                  autoFirstTick={fav}
                />
              )}
            </Detail>
          </DetailsRow>
        )}
      </InsightFormContainer>
      <BookButtonRow>
        {id && (
          <div style={{ marginRight: "1rem" }}>
            <button className="btn btn-outline-danger" onClick={handleDelete}>
              Delete
            </button>
          </div>
        )}
        <div style={{ marginRight: "1rem" }}>
          <button className="btn btn-outline-primary" onClick={closeModal}>
            Cancel
          </button>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Save
          </button>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <button className="btn btn-primary" onClick={generateSummary}>
            { isGenerating ? "Generating..." : "Generate"}
          </button>
        </div>
      </BookButtonRow>
    </Container>
  );
};

export default MyInsightModal;

const TimeContainer = styled.div`
  > .MuiFormControl-root > .MuiInputBase-root > input {
    padding: 7px 10px;
  }
`;
const InsightFormContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
  @media screen and (max-width: ${breakpoints.s}) {
    max-height: 80vh;
  }
`;
const BookButtonRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding: 0.5rem;
`;

const Container = styled.div`
  position: relative;
  width: 50%;
  margin: auto;
  margin-top: 50vh;
  transform: translateY(-35%);
  background: var(--background-panel);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  @media screen and (max-width: ${breakpoints.s}) {
    width: 100%;
    height: 100vh;
  }
`;

const BookHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BookBody = styled.div`
  display: grid;
  grid-template-columns: 33% 66%;
  margin-top: 2rem;
`;

const BookCoverHolder = styled.div`
  padding: 0.5rem;
  width: 100%;
  > img {
    width: 72%;
  }
`;

const BookDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1rem;
`;

const DetailsRow = styled.div`
  width: 100%;
  display: flex;
`;

const BookDetailForm = ({
  title,
  value,
  valueName,
  handleInputChange,
  type,
  theme,
}) => {
  return (
    <Detail>
      <DetailTitle>{title}</DetailTitle>
      {type === "textarea" && (
        <TextArea
          className="form-control"
          onChange={handleInputChange}
          name={valueName}
          value={value}
        />
      )}
      {/* {type === "category" && (
        <div style={{ maxWidth: "100%" }}>
          <Select
            onChange={handleInputChange}
            options={categoryOptions}
            value={value}
            theme={theme}
          />
        </div>
      )} */}
      {type === "number" && (
        <input
          className="form-control"
          onChange={handleInputChange}
          name={valueName}
          value={value}
        />
      )}
      {type === "time" && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            onChange={handleInputChange}
            name={valueName}
            value={value}
          />
        </LocalizationProvider>
      )}
      {!type && (
        <input
          className="form-control"
          onChange={handleInputChange}
          name={valueName}
          value={value}
        />
      )}
    </Detail>
  );
};

const Detail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0.5rem;
  margin-bottom: 0.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailTitle = styled.span`
  font-size: var(--font-size-7);
  margin-bottom: 0.25rem;
  font-weight: 700;
`;

const TitleSuperscript = styled.span`
  font-size: var(--font-size-5);
`;
