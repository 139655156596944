import { functions } from "../firebase/firebase.utils";
import backend from "../axiosBackend";
import { auth } from "../firebase/firebase.utils";
/*
  API Implementation
*/

const createSetupIntent = async (userId) => {
  const response = await backend.post("/stripe/setupIntent", {
    userId,
  });
  return response;
};
const createSubscriptionAndUser = async (userId, email, password, name, priceId, payment_method, promoCode) => {
  const response = await backend.post("/stripe/subscription-user", {
    userId,
    email,
    password,
    name,
    priceId,
    payment_method,
    promoCode
  });
  return response;
};

const cancelSignup = async (userID, subscriptionId) => {

  const response = await backend.post("/stripe/subscription-user-delete",{
    userID,
    subscriptionId
  });

  return response;
};

const createSubscriptionWithoutTrial = async (userId, priceId, promoCode) => {
  const response = await backend.post("/stripe/subscription", {
    userId,
    priceId,
    promoCode,
    withTrial: false,
  });
  return response;
};

const createSubscriptionWithTrial = async (
  userId,
  priceId,
  payment_method,
  promoCode
) => {
  const response = await backend.post("/stripe/subscription", {
    userId,
    priceId,
    payment_method,
    promoCode,
    withTrial: true,
  });
  return response;
};

const validatePromoCode = async (promoCode, userId) => {
  const response = await backend.get(
    `/stripe/promoCode?code=${promoCode}${userId ? "&userId=" + userId : ""}`,
    {
      userId,
    }
  );
  return response;
};

// userId comes from firebaseToken
const updatePaymentDetails = async (subscriptionId, paymentMethodId) => {
  const firebaseToken = await auth.currentUser.getIdToken(true);
  const response = await backend.put(
    `/stripe/paymentDetails`,
    {
      subscriptionId,
      paymentMethodId,
    },
    {
      headers: {
        Authorization: "Bearer " + firebaseToken,
      },
    }
  );
  return response;
};

// userId comes from firebaseToken
const cancelSubscription = async (subscriptionId, immediately) => {
  const firebaseToken = await auth.currentUser.getIdToken(true);
  const response = await backend.delete(
    `/stripe/subscription/${subscriptionId}${
      immediately ? "?immediately=true" : ""
    }`,
    {
      headers: {
        Authorization: "Bearer " + firebaseToken,
      },
    }
  );

  return response;
};

// userId comes from firebaseToken
const returnInvoiceToRetry = async (paymentMethodId, invoiceId) => {
  const firebaseToken = await auth.currentUser.getIdToken(true);
  const response = await backend.post(
    `/stripe/invoice`,
    {
      paymentMethodId,
      invoiceId,
    },
    {
      headers: {
        Authorization: "Bearer " + firebaseToken,
      },
    }
  );

  return response;
};

/*
    Decouples the stripeApi from Firebase Functions.
*/

export const stripeApi_CreateSetupIntent = async ({ userId }) => {
  try {
    const response = await createSetupIntent(userId);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const stripeApi_CreateSubscriptionWithTrial = async ({
  userId,
  priceId,
  payment_method,
  promoCode,
}) => {
  try {
    const response = await createSubscriptionWithTrial(
      userId,
      priceId,
      payment_method,
      promoCode
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const stripeApi_CancelSignup = async ({userId, subscriptionId}) => {
  try {
    const response = await cancelSignup(userId, subscriptionId);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const stripeApi_CreateSubscriptionAndUser = async ({
  userId,
  email,
  password,
  name,
  priceId,
  payment_method,
  promoCode,
}) => {
  try {
    const response = await createSubscriptionAndUser(
      userId,
      email,
      password,
      name,
      priceId,
      payment_method,
      promoCode
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const stripeApi_CreateSubscriptionWithoutTrial = async ({
  userId,
  priceId,
  payment_method,
  promoCode,
}) => {
  try {
    const response = await createSubscriptionWithoutTrial(
      userId,
      priceId,
      promoCode
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const stripeApi_ReturnInvoiceToRetry = async ({
  userId,
  paymentMethodId,
  invoiceId,
}) => {
  try {
    const response = await returnInvoiceToRetry(paymentMethodId, invoiceId);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const stripeApi_UpdatePaymentDetails = async ({
  userId,
  subscriptionId,
  paymentMethodId,
}) => {
  try {
    const response = await updatePaymentDetails(
      subscriptionId,
      paymentMethodId
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const stripeApi_ValidatePromoCode = async ({ userId, promoCode }) => {
  try {
    const response = await validatePromoCode(promoCode, userId);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const stripeApi_CancelSubscription = async ({
  userId,
  subscriptionId,
  immediately,
}) => {
  try {
    const response = await cancelSubscription(subscriptionId, immediately);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
