import { createMachine } from "xstate";
import { assign } from "xstate/lib/actions";
import { getUserBook } from "../firebase/firebase.utils";

const mapTimestampsToDates = (bookData) => {
  console.log(bookData.readDate);
  if (bookData.readDate) {
    bookData.readDate = bookData.readDate.toDate();
  }
  if (bookData.reviewSchedule) {
    bookData.reviewSchedule = bookData.reviewSchedule.toDate();
  }
  if (bookData.reviewDate) {
    bookData.reviewDate = bookData.reviewDate.toDate();
  }
  if (bookData.finalReviewSchedule) {
    bookData.finalReviewSchedule = bookData.finalReviewSchedule.toDate();
  }
  if (bookData.finalReviewDate) {
    bookData.finalReviewDate = bookData.finalReviewDate.toDate();
  }
  return bookData;
};

const getGrowthNoteStage = async (bookId, userId) => {
  const userBookRaw = await getUserBook(userId, bookId);
  if (userBookRaw === null) return null;
  const userBook = mapTimestampsToDates(userBookRaw);
  return userBook;
};

const growthnoteMachine = createMachine({
  id: "growthnoteMachine",
  initial: "initialLoad",
  context: {},
  states: {
    initialLoad: {
      invoke: {
        src: (context, event) => async () =>
          await getGrowthNoteStage(context.bookId, context.currentUser.uid),
        onDone: [
          {
            target: "checkingCycleProgress",
            cond: (context, event) => event.data !== null,
            actions: assign({
              readDate: (context, event) => event.data.readDate,
              reviewSchedule: (context, event) => event.data.reviewSchedule,
              reviewDate: (context, event) => event.data.reviewDate,
              finalReviewSchedule: (context, event) =>
                event.data.finalReviewSchedule,
              finalReviewDate: (context, event) => event.data.finalReviewDate,
            }),
          },
          { target: "readyForFirstTick" },
        ],
      },
    },
    checkingCycleProgress: {
      on: {
        "": [
          {
            target: "completed",
            cond: (context, event) => context.finalReviewDate,
          },
          {
            target: "readyForThirdTick",
            cond: (context, event) =>
              context.reviewDate && new Date() > context.finalReviewSchedule,
          },
          {
            target: "notReadyForThirdTick",
            cond: (context, event) => context.reviewDate,
          },
          {
            target: "readyForSecondTick",
            cond: (context, event) =>
              context.readDate && new Date() > context.reviewSchedule,
          },
          {
            target: "notReadyForSecondTick",
            cond: (context, event) => context.readDate,
          },
          {
            target: "readyForFirstTick",
          },
        ],
      },
    },
    readyForFirstTick: {
      on: {
        TICK: {
          target: "notReadyForSecondTick",
          actions: assign({
            readDate: (context, event) => event.readDate,
            reviewSchedule: (context, event) => event.reviewSchedule,
          }),
        },
        RESET: {
          target: "readyForFirstTick",
        },
      },
    },
    notReadyForSecondTick: {
      on: {
        RESET: {
          target: "readyForFirstTick",
        },
      },
    },
    readyForSecondTick: {
      on: {
        TICK: {
          target: "notReadyForThirdTick",
          actions: assign({
            reviewDate: (context, event) => event.reviewDate,
            finalReviewSchedule: (context, event) => event.finalReviewSchedule,
          }),
        },
        RESET: {
          target: "readyForFirstTick",
        },
      },
    },
    notReadyForThirdTick: {
      on: {
        RESET: {
          target: "readyForFirstTick",
        },
      },
    },
    readyForThirdTick: {
      on: {
        TICK: {
          target: "completed",
          actions: assign({
            finalReviewDate: (context, event) => event.finalReviewDate,
          }),
        },
        RESET: {
          target: "readyForFirstTick",
        },
      },
    },
    completed: {
      on: {
        RESET: {
          target: "readyForFirstTick",
        },
      },
    },
  },
});

export default growthnoteMachine;
